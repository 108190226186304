import React, { useState, useEffect, useContext } from 'react'
import { PERMISSIONS } from 'consts/permissions'
import Context from 'utils/Context'
import locale from 'antd/es/date-picker/locale/vi_VN'

//component antd
import {
  Table,
  Row,
  Col,
  Typography,
  Input,
  Select,
  Button,
  DatePicker,
  Upload,
  Tabs,
  notification,
  Popconfirm,
  Tag,
  Checkbox,
  Switch,
} from 'antd'

//icons antd
import {
  SearchOutlined,
  DownloadOutlined,
  UploadOutlined,
  FormOutlined,
} from '@ant-design/icons'

//apis

import importColumns from './importColumns'
import inventoryColumns from './inventoryColumns'
import exportColumns from './exportColumns'
import { ACTION, ROUTES } from 'consts'
import { translate } from 'utils/i18n'
import { getTimeRange, removeNull } from 'utils'

//components
import Permission from 'components/Permission'
import ImportModal from 'components/ExcelModal/Modal'
import exportToCSV from 'components/ExcelModal/exportData'
import {
  getCardByType,
  getOrderInInventory,
  importCardIn,
  importCardOut,
  deleteCard,
  cancelCard,
  exportCardAll,
  exportCardByFilter,
  exportCardCurrentPage,
  exportAllInInventory,
  exportFilterInInventory,
  exportPageInInventory,
} from 'apis/card'
import { sendNotifyImport } from 'apis/order'
import { sendNotifyDebit } from 'apis/order'
import { useHistory } from 'react-router-dom'
import {
  warehouseExport,
  exportWarehouse,
  warehouseImport,
  warehouseImport2,
  warehouseImportOrder2,
  warehouseExportCardOut,
} from 'components/ExcelModal/fieldConvert'
import XLSX from 'xlsx'
import moment from 'moment-timezone'
import { formatNumber } from 'utils'

import styles from './warehouse.scss'
import { getUsers } from 'apis/users'

const { Option } = Select
const { RangePicker } = DatePicker
export default () => {
  const history = useHistory()
  const [data, setData] = useState()
  const [viewPage, setViewPage] = useState(
    history.location?.state?.warehouseActiveTab
      ? history.location.state.warehouseActiveTab + ''
      : '2'
  )
  const context = useContext(Context)
  const [loading, setLoading] = useState(false)
  const [showImport, setShowImport] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [filter, setFilter] = useState({ page: 1, pageSize: 300 })
  const [maxRecord, setMaxRecord] = useState(0)
  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const [countVolume, setCountVolume] = useState({ kl: 0, tl: 0 })
  const [importData, setImportData] = useState([])
  const [displayImportData, setDisplayImportData] = useState([])
  const [importLoading, setImportLoading] = useState(false)
  const [selectedDate, setSeletedDate] = useState()
  const [customerList, setCustomerList] = useState([])
  const [onlyShowStockEmpty, setOnlyShowStockEmpty] = useState(0)

  const toggleOpenSelect = () => setIsOpenSelect(!isOpenSelect)
  const changeRange = (date, dateString) => {
    setFilter({
      ...filter,
      startDate: dateString[0],
      endDate: dateString[1],
    })
    setSeletedDate(`${dateString[0]} -> ${dateString[1]}`)
    toggleOpenSelect()
  }
  const changeTimeOption = (value) => {
    let tmp = getTimeRange(value)
    setFilter({
      ...filter,
      startDate: tmp.from_date,
      endDate: tmp.to_date,
    })
    setSeletedDate(value)
  }

  const getCard = async (params) => {
    try {
      setLoading(true)
      const res = await (viewPage == 2
        ? getOrderInInventory(params)
        : getCardByType({
            type: viewPage == 1 ? 'import' : 'export',
            ...params,
          }))

      if (res.data && res.data.success && res.status === 200) {
        setData(res.data.data)
        setMaxRecord(res.data.count)
      } else {
        notification.error({
          message:
            res.data?.messs || 'Hệ thống đang khởi động, vui lòng thử lại',
        })
      }
      setLoading(false)
    } catch (err) {
      notification.error({
        message: 'Hệ thống đang khởi động, vui lòng thử lại',
      })
      setLoading(false)
      console.log(err)
    }
  }

  const handleSearchCustomer = async (val) => {
    try {
      var _query = { role_id: 7, page: 1, pageSize: 10000 }
      if (viewPage == 1) _query.have_import_order = 1
      if (viewPage == 2) _query.have_inventory = 1
      if (viewPage == 3) _query.have_export_order = 1

      const res = await getUsers(_query)
      if (res.data.success) {
        setCustomerList(res.data.data)
      } else {
        setCustomerList([])
      }
    } catch (err) {
      setCustomerList([])
      console.log(err)
    }
  }

  const onSendNotifyImport = async () => {
    try {
      console.log(selectedRowKeys)
      var response = await sendNotifyImport({
        card_ids: selectedRowKeys,
      })
      if (response && response.status == 200) {
        notification.success({
          message: 'Gửi thông báo thành công',
        })
        getCard(removeNull(filter))
        handleSearchCustomer()
      } else {
        notification.error({
          message: response?.data?.message ?? 'Gửi thông báo thất bại',
        })
      }
    } catch (e) {}
  }

  const onCancelCard = async (id) => {
    try {
      setLoading(true)
      const res = await cancelCard(id)
      console.log(res)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Huỷ phiếu thành công' })
          getCard(removeNull(filter))
        } else
          notification.error({
            message: res.data.mess || res.data.message || 'Huỷ phiếu thất bại',
          })
      } else
        notification.error({
          message: res.data.mess || res.data.message || 'Huỷ phiếu thất bại',
        })
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const onSelectChange = (selected) => {
    setSelectedRowKeys(selected)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  const settings = {
    maxCount: 1,
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      authorization: 'authorization-text',
    },
    onChange: (info) => {
      if (info.file.status === 'uploading') {
        setImportLoading(true)

        const reader = new FileReader()
        reader.onload = async (e) => {
          const bstr = e.target.result
          const workBook = XLSX.read(bstr, {
            type: 'binary',
            cellDates: true,
          })
          const workSheetname = workBook.SheetNames[0]

          const workSheet = workBook.Sheets[workSheetname]

          const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 0 })
          let success = true
          const listCard = await (viewPage == 1
            ? warehouseImport2(fileData)
            : warehouseExportCardOut(fileData))

          // Import
          if (viewPage == 1) {
            const workSheetname2 = workBook.SheetNames[1]
            const workSheet2 = workBook.Sheets[workSheetname2]
            const fileData2 = XLSX.utils.sheet_to_json(workSheet2, {
              header: 0,
            })
            let listDataOrder = await warehouseImportOrder2(fileData2)
            setImportData({ ...listCard, data: listDataOrder })
            setDisplayImportData([listCard])
          } else {
            setImportData(listCard[0])
            setDisplayImportData(listCard[1])
          }

          setImportLoading(false)
        }

        reader.readAsBinaryString(info.file.originFileObj)
      }
      if (info.file.status === 'error') {
        info.file.status = 'done'
      }
    },
  }
  const resetFilter = () => {
    let tmp = {
      page: 1,
      pageSize: filter.pageSize,
    }
    setFilter(tmp)
    setSeletedDate(undefined)
  }

  const handleImport = async () => {
    try {
      const res = await (viewPage == 1
        ? importCardIn({ data: [importData] })
        : importCardOut({ data: importData }))

      if (res.data.success) {
        notification.success({ message: 'Import phiếu thành công' })
        setDisplayImportData([])
        setImportData([])
        setShowImport(false)
        getCard(removeNull(filter))
      } else {
        notification.error({
          message: res.data?.mess || 'Import  phiếu không thành công',
        })
      }
    } catch (err) {
      console.log(err)
      notification.error({ message: 'Có lỗi xảy ra trong quá trình xử lý' })
    }
  }
  const ImportComponent = () => (
    <Upload {...settings}>
      <Button type="primary">Chọn file nhập</Button>
    </Upload>
  )

  const onExportCurrentPage = async () => {
    try {
      if (viewPage == 2) {
        setLoading(true)
        const res = await exportPageInInventory({
          page: filter.page,
          pageSize: filter.pageSize,
        })
        setLoading(false)
        if (res.data && res.data.success) {
          window.open(res.data.data)
        } else notification.error({ message: 'Export không thành công' })
      } else {
        setLoading(true)
        const res = await exportCardCurrentPage({
          page: filter.page,
          pageSize: filter.pageSize,
          type: viewPage == 1 ? 'import' : 'export',
        })
        setLoading(false)
        if (res.data && res.data.success) {
          window.open(res.data.data)
        } else notification.error({ message: 'Export không thành công' })
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
      notification.error({
        message: 'Có lỗi xảy ra tong quá trình xử lý dữ liệu',
      })
    }
  }

  const onExportByFilter = async () => {
    try {
      if (viewPage == 2) {
        setLoading(true)
        const res = await exportFilterInInventory({
          ...filter,
        })
        setLoading(false)
        if (res.data && res.data.success) {
          window.open(res.data.data)
        } else notification.error({ message: 'Export không thành công' })
      } else {
        setLoading(true)
        const res = await exportCardByFilter({
          ...filter,
          type: viewPage == 1 ? 'import' : 'export',
        })
        setLoading(false)
        if (res.data && res.data.success) {
          window.open(res.data.data)
        } else notification.error({ message: 'Export không thành công' })
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
      notification.error({
        message: 'Có lỗi xảy ra tong quá trình xử lý dữ liệu',
      })
    }
  }

  const onExportAllData = async () => {
    try {
      if (viewPage == 2) {
        setLoading(true)
        const res = await exportAllInInventory({
          ...filter,
        })
        setLoading(false)
        if (res.data && res.data.success) {
          window.open(res.data.data)
        } else notification.error({ message: 'Export không thành công' })
      } else {
        setLoading(true)
        const res = await exportCardAll({
          ...filter,
          type: viewPage == 1 ? 'import' : 'export',
        })
        setLoading(false)
        if (res.data && res.data.success) {
          window.open(res.data.data)
        } else notification.error({ message: 'Export không thành công' })
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
      notification.error({
        message: 'Có lỗi xảy ra tong quá trình xử lý dữ liệu',
      })
    }
  }

  useEffect(() => {
    getCard(removeNull(filter))
    handleSearchCustomer()
  }, [viewPage, filter])

  useEffect(() => {
    let kl = 0,
      tl = 0
    if (viewPage == 2) {
      selectedRowKeys.forEach((e) => {
        let tmp = data.find((d) => d.id == e)
        kl += tmp.mass
        tl += tmp.volume
      })
      setCountVolume({ kl, tl })
    }
  }, [selectedRowKeys])

  useEffect(() => {
    handleSearchCustomer()
  }, [])

  return (
    <>
      <Row justify="space-between">
        <Typography.Title level={4}>{translate('Warehouses')}</Typography.Title>
      </Row>

      <Tabs
        activeKey={viewPage}
        onChange={(e) => {
          setViewPage(e)
          setSelectedRowKeys([])
          setFilter({ page: 1, pageSize: 100 })
        }}
        className="warehouse-tabs">
        <Tabs.TabPane tab="Tồn kho" disabled={loading} key={2}></Tabs.TabPane>
        {context?.permissions?.includes(PERMISSIONS.xem_phieu_nhap) && (
          <Tabs.TabPane
            disabled={loading}
            tab="Phiếu nhập kho"
            key={1}></Tabs.TabPane>
        )}
        {context?.permissions?.includes(PERMISSIONS.xem_phieu_xuat) && (
          <Tabs.TabPane
            disabled={loading}
            tab="Phiếu xuất kho"
            key={3}></Tabs.TabPane>
        )}
      </Tabs>
      <br />
      <Row gutter={20}>
        <Col span={6}>
          <Row>Lọc theo khách hàng</Row>
          <Row>
            <Select
              showSearch
              placeholder="Chọn mã khách hàng"
              style={{ width: '100%' }}
              value={filter.customer_code}
              onChange={(e) => setFilter({ ...filter, customer_code: e })}>
              {customerList.map((e) => (
                <Select.Option value={e.customer_code}>
                  {e.customer_code}
                </Select.Option>
              ))}
            </Select>
            {/* <Input
                prefix={<SearchOutlined style={{ color: '#bdc3c7' }} />}
                placeholder={translate('Search by customer code')}
                value={filter.customer_name}
                onChange={(e) =>
                  setFilter({ ...filter, customer_name: e.target.value })
                }
              /> */}
          </Row>
        </Col>
        <Col span={6}>
          <Row>Tìm theo mã bill</Row>
          <Row>
            <Input
              prefix={<SearchOutlined style={{ color: '#bdc3c7' }} />}
              placeholder="Tìm theo mã bill"
              value={filter.bill_code}
              onChange={(e) =>
                setFilter({ ...filter, bill_code: e.target.value })
              }
            />
          </Row>
        </Col>
        <Col span={6}>
          <Row>{translate('Filter by date')}</Row>
          <Row>
            <Select
              open={isOpenSelect}
              onBlur={() => {
                if (isOpenSelect) toggleOpenSelect()
              }}
              onClick={() => {
                if (!isOpenSelect) toggleOpenSelect()
              }}
              style={{ width: '100%' }}
              placeholder="Chọn thời gian"
              allowClear
              value={selectedDate}
              onChange={async (value) => {
                if (isOpenSelect) toggleOpenSelect()
                changeTimeOption(value)
              }}
              dropdownRender={(menu) => (
                <div>
                  <RangePicker
                    placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                    onFocus={() => {
                      if (!isOpenSelect) toggleOpenSelect()
                    }}
                    onBlur={() => {
                      if (isOpenSelect) toggleOpenSelect()
                    }}
                    locale={locale}
                    style={{ width: '100%' }}
                    showTime
                    format="YYYY-MM-DD"
                    onOpenChange={() => {
                      setTimeout(() => {
                        let timePicker = document.querySelector(
                          '.ant-picker-panel-container .ant-picker-time-panel'
                        )
                        if (timePicker) timePicker.style = 'display:none'
                      }, 500)
                    }}
                    onChange={changeRange}
                  />
                  {menu}
                </div>
              )}>
              <Option value="to_day">{translate('Today')} </Option>
              <Option value="yesterday">{translate('Yesterday')}</Option>
              <Option value="this_week">{translate('This week')} </Option>
              <Option value="last_week">{translate('Last week')} </Option>
              <Option value="last_month">{translate('Last month')} </Option>
              <Option value="this_month">{translate('This month')} </Option>
              <Option value="this_year">{translate('This year')}</Option>
              <Option value="last_year">{translate('Last year')}</Option>
            </Select>
          </Row>
        </Col>
        {(viewPage == 1 || viewPage == 3) && (
          <Col span={6}>
            <Row>Lọc theo trạng thái</Row>
            <Row>
              <Select
                placeholder="Chọn trạng thái"
                style={{ width: '100%' }}
                value={filter.is_activate}
                allowClear
                onChange={(e) => setFilter({ ...filter, is_activate: e })}>
                <Select.Option value="1">
                  Đã {viewPage == 1 ? 'nhập' : 'xuất'}
                </Select.Option>
                <Select.Option value="0">Lưu nháp</Select.Option>
              </Select>
            </Row>
          </Col>
        )}
        {viewPage == 2 && selectedRowKeys.length > 0 && (
          <>
            <Col span={3}>
              <Row>Tổng trọng lượng</Row>
              <Button style={{ width: 100 }}>
                {parseFloat(Number(countVolume.kl)).toFixed(3)} kg
              </Button>
            </Col>
            <Col span={3}>
              <Row>Tổng khối lượng</Row>
              <Button style={{ width: 100 }}>
                {parseFloat(Number(countVolume.tl)).toFixed(3)} cm<sup>3</sup>
              </Button>
            </Col>
          </>
        )}
      </Row>
      <br />
      <Row justify="space-between">
        <Col>
          <Row gutter={15}>
            {(viewPage == 1 || viewPage == 3) && (
              <Permission permissions={[PERMISSIONS.tao_phieu_nhap]}>
                <Col>
                  <Button
                    icon={<DownloadOutlined />}
                    type="primary"
                    style={{
                      marginBottom: 10,
                      backgroundColor: '#C12222D4',
                      border: 'none',
                      borderRadius: 5,
                    }}
                    onClick={() => setShowImport(true)}>
                    Chọn file nhập
                  </Button>
                </Col>
              </Permission>
            )}
            <Col>
              <Button
                type="primary"
                icon={<UploadOutlined />}
                style={{
                  backgroundColor: '#DB710FD4',
                  border: 'none',
                  borderRadius: 5,
                  marginRight: 10,
                  marginLeft: 10,
                }}
                onClick={onExportCurrentPage}>
                Xuất phiếu trang hiện tại
              </Button>
              <Button
                type="primary"
                icon={<UploadOutlined />}
                style={{
                  backgroundColor: '#DB710FD4',
                  border: 'none',
                  borderRadius: 5,
                  marginRight: 10,
                  marginLeft: 10,
                }}
                onClick={onExportByFilter}>
                Xuất phiếu theo bộ lọc
              </Button>
              <Button
                type="primary"
                icon={<UploadOutlined />}
                style={{
                  backgroundColor: '#DB710FD4',
                  border: 'none',
                  borderRadius: 5,
                  marginRight: 10,
                  marginLeft: 10,
                }}
                onClick={onExportAllData}>
                Xuất toàn bộ phiếu
              </Button>

              <Button type="primary" danger onClick={resetFilter}>
                Xóa bộ lọc
              </Button>
            </Col>
            {/* {(viewPage == 1 || viewPage == 3) && (
              <div>
                {selectedRowKeys.length ? (
                  <Col>
                    <Button
                      type="primary"
                      icon={<FormOutlined />}
                      style={{ borderRadius: 5 }}
                      onClick={() => {
                        for (let i = 0; i < selectedRowKeys.length; i++) {
                          const warehouse = data.find(
                            (e) => e.id === selectedRowKeys[i]
                          )
                          if (warehouse)
                            if (warehouse.is_activate === 1) {
                              notification.warning({
                                message: `${warehouse.code} đã nhập, không thể cập nhật`,
                                duration: 3,
                              })
                              return
                            }
                        }

                        history.push({
                          pathname:
                            viewPage == 1
                              ? ROUTES.UPDATE_WAREHOUSE_IMPORT
                              : ROUTES.UPDATE_WAREHOUSE_EXPORT +
                                `card_id=${1}&type=export`,
                          // state: {
                          //   billData: selectedRowKeys.map((e) => {
                          //     return data.find((d) => d.id == e)
                          //   }),
                          //   warehouseActiveTab: viewPage,
                          // },
                        })
                      }}>
                      Chỉnh sửa
                    </Button>
                  </Col>
                ) : (
                  ''
                )}
              </div>
            )} */}
          </Row>
        </Col>

        <Col>
          <Row justify="end" gutter={20}>
            {viewPage == 1 && (
              <Permission permissions={[PERMISSIONS.tao_phieu_nhap]}>
                <Col>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: '#5ECBA1',
                      border: 'none',
                      borderRadius: 5,
                    }}
                    onClick={() =>
                      window.open(ROUTES.CREATE_WAREHOUSE_IMPORT, '_blank')
                    }>
                    Tạo phiếu nhập
                  </Button>
                </Col>
              </Permission>
            )}

            {viewPage == 2 && selectedRowKeys.length > 0 && (
              <Permission permissions={[PERMISSIONS.tao_phieu_xuat]}>
                <Col>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: '#5ECBA1',
                      border: 'none',
                      borderRadius: 5,
                    }}
                    onClick={() => {
                      let oneCustomer = true
                      let billSelected = data.filter((e) =>
                        selectedRowKeys.includes(e.id)
                      )

                      billSelected.forEach((e) => {
                        console.log(
                          'data',
                          e.customer_code,
                          data[0].customer_code
                        )
                        if (e.customer_code != billSelected[0].customer_code)
                          oneCustomer = false
                      })
                      if (oneCustomer) {
                        history.push({
                          pathname: ROUTES.CREATE_WAREHOUSE_EXPORT,
                          state: {
                            type: 'export',
                            initData: data.filter((e) =>
                              selectedRowKeys.includes(e.id)
                            ),
                            warehouseActiveTab: viewPage,
                          },
                        })
                      } else {
                        notification.warning({
                          message:
                            'Vui lòng chọn các đơn hàng của cùng 1 khách hàng',
                        })
                      }
                    }}>
                    Tạo phiếu xuất
                  </Button>
                </Col>
              </Permission>
            )}

            {viewPage == 3 && (
              <Permission permissions={[PERMISSIONS.tao_phieu_xuat]}>
                <Col>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: '#5ECBA1',
                      border: 'none',
                      borderRadius: 5,
                    }}
                    onClick={() =>
                      window.open(ROUTES.CREATE_WAREHOUSE_EXPORT, '_blank')
                    }>
                    Tạo phiếu xuất
                  </Button>
                </Col>
              </Permission>
            )}
          </Row>
        </Col>
      </Row>

      <br />
      {viewPage == 2 && (
        <Row style={{ marginLeft: 15 }}>
          <Switch
            checked={onlyShowStockEmpty}
            onChange={(checked) => {
              if (checked) {
                setFilter({ ...filter, inventory_available: true })
              } else {
                setFilter({ ...filter, inventory_available: false })
              }
              setOnlyShowStockEmpty(checked)
            }}
          />
          <div style={{ paddingLeft: 10 }}>
            {onlyShowStockEmpty
              ? 'Chỉ hiện tồn kho lớn hơn 0'
              : 'Đang hiện tất cả tồn kho'}
          </div>
        </Row>
      )}
      <br />
      {viewPage == 1 && (
        <Row>
          {selectedRowKeys.length > 0 && (
            <Popconfirm
              title={'Xác nhận gửi thông báo nhập hàng đến khách hàng?'}
              onConfirm={(e) => {
                onSendNotifyImport()
              }}>
              <Button type={'primary'}>
                <div>{'Gửi thông báo Telegram'}</div>
              </Button>
            </Popconfirm>
          )}
        </Row>
      )}
      <Table
        rowKey="id"
        pagination={{
          pageSize: filter.pageSize,
          pageSizeOptions: [100, 200, 300, 400],
          current: filter.page,
          total: maxRecord,
          onChange: (page, pageSize) =>
            setFilter({ ...filter, page, pageSize }),
        }}
        columns={
          viewPage == 1
            ? importColumns
                .filter((column) => {
                  if (context) {
                    const keys = [
                      {
                        key: 'cost_import',
                        value: PERMISSIONS.xem_gia_von_phieu_nhap,
                      },
                    ]
                    const keyActive = keys.find(
                      (k) => k.key === column.dataIndex
                    )
                    if (keyActive) {
                      const columnActive = context.permissions.find(
                        (permission) => permission === keyActive.value
                      )
                      return columnActive ? true : false
                    }
                    return true
                  }

                  return true
                })
                .map((column) => {
                  if (column.key === 'code_io')
                    return {
                      ...column,
                      render: (text, record) => (
                        <a
                          onClick={
                            () =>
                              !record.is_active &&
                              window.open(
                                ROUTES.UPDATE_WAREHOUSE_IMPORT +
                                  '/' +
                                  record.id,
                                '_blank'
                              )
                            // history.push({
                            //   pathname:
                            //     ROUTES.UPDATE_WAREHOUSE_IMPORT + '/' + record.id,
                            // })
                          }>
                          {text}
                        </a>
                      ),
                    }
                  if (column.key === 'status')
                    return {
                      ...column,
                      render: (text, record) => {
                        if (record.status != 'cancel') {
                          return <Tag color={'green'}>{'Hoạt động'}</Tag>
                        } else {
                          return <Tag color={'red'}>{'Đã huỷ'}</Tag>
                        }
                      },
                    }
                  if (column.key === 'action')
                    return {
                      ...column,
                      render: (text, record) => (
                        <Permission permissions={[PERMISSIONS.huy_phieu_nhap]}>
                          <Popconfirm
                            onConfirm={() => onCancelCard(record.id)}
                            okText="Đồng ý"
                            cancelText="Từ chối"
                            title="Bạn có muốn huỷ phiếu này?">
                            <Button
                              disabled={record.status == 'cancel'}
                              type="primary"
                              danger>
                              Huỷ Phiếu
                            </Button>
                          </Popconfirm>
                        </Permission>
                      ),
                    }
                  return column
                })
            : viewPage == 2
            ? inventoryColumns.map((column) => {
                if (column.key == 'amount_package_imported')
                  return {
                    ...column,
                    render: (text, record) => (
                      <div>
                        {record.amount_package_imported +
                          '/' +
                          record.total_quantity_package}
                      </div>
                    ),
                  }

                if (column.key == 'amount_package_exported')
                  return {
                    ...column,
                    render: (text, record) => (
                      <div>
                        {record.amount_package_exported +
                          '/' +
                          record.total_quantity_package}
                      </div>
                    ),
                  }
                return column
              })
            : exportColumns
                .filter((column) => {
                  if (context) {
                    const keys = [
                      {
                        key: 'cost_export',
                        value: PERMISSIONS.xem_tong_cuoc_phieu_xuat,
                      },
                    ]
                    const keyActive = keys.find(
                      (k) => k.key === column.dataIndex
                    )
                    if (keyActive) {
                      const columnActive = context.permissions.find(
                        (permission) => permission === keyActive.value
                      )
                      return columnActive ? true : false
                    }
                    return true
                  }
                  return true
                })
                .map((column) => {
                  if (column.key === 'code_io')
                    return {
                      ...column,
                      render: (text, record) => (
                        <a
                          style={
                            !record.is_activate
                              ? {
                                  color: 'blue',
                                  cursor: 'pointer',
                                }
                              : {}
                          }
                          onClick={() =>
                            window.open(
                              ROUTES.UPDATE_WAREHOUSE_EXPORT + '/' + record.id,
                              '_blank'
                            )
                          }>
                          {text}
                        </a>
                      ),
                    }

                  if (column.key === 'is_activate')
                    return {
                      ...column,
                      render: (text, record) => {
                        if (record.is_activate) {
                          return (
                            <Col>
                              <Tag color={'green'}>{'Kích hoạt'}</Tag>
                            </Col>
                          )
                        } else {
                          return <Tag color={'grey'}>{'Lưu nháp'}</Tag>
                        }
                      },
                    }

                  if (column.key === 'status')
                    return {
                      ...column,
                      render: (text, record) => {
                        return (
                          <div>
                            {record.status == 'cancel' ? (
                              <Tag color={'red'}>{'Đã huỷ'}</Tag>
                            ) : (
                              <Tag color={'green'}>{'Hoạt động'}</Tag>
                            )}
                          </div>
                        )
                      },
                    }

                  if (column.key === 'action')
                    return {
                      ...column,
                      render: (text, record) => (
                        <Permission permissions={[PERMISSIONS.huy_phieu_xuat]}>
                          <Popconfirm
                            onConfirm={() => onCancelCard(record.id)}
                            okText="Đồng ý"
                            cancelText="Từ chối"
                            title="Bạn có muốn huỷ phiếu này?">
                            <Button
                              disabled={record.status == 'cancel'}
                              type="primary"
                              danger>
                              Huỷ phiếu
                            </Button>
                          </Popconfirm>
                        </Permission>
                      ),
                    }
                  return column
                })
        }
        scroll={{ x: 'max-content', y: '60vh' }}
        dataSource={data}
        loading={loading}
        rowSelection={viewPage == 3 ? undefined : rowSelection}
        size="small"
      />
      <ImportModal
        actionComponent={<ImportComponent />}
        visible={showImport}
        onCancel={() => {
          setShowImport(false)
          setDisplayImportData([])
        }}
        onOk={() => handleImport()}
        columns={
          viewPage == 1
            ? [
                {
                  title: 'Ngày nhập kho',
                  dataIndex: 'created_at',
                  render(data) {
                    return (
                      data &&
                      moment(String(data).replace(/\.000Z$/, '')).format(
                        'DD/MM/YYYY HH:mm:ss'
                      )
                    )
                  },
                },
                {
                  title: 'Tên đơn vị vận chuyển',
                  dataIndex: 'shipping_partner_name',
                },
                {
                  title: 'Ghi chú',
                  dataIndex: 'note',
                },
                {
                  title: 'Biên lai ghi nhận thực tế',
                  dataIndex: 'img',
                },
                {
                  title: 'Cước nhập kho',
                  dataIndex: 'cost_import',
                  render(data) {
                    return data && formatNumber(data)
                  },
                },
                // {
                //   title: 'Mã đơn hàng',
                //   dataIndex: 'code',
                // },
                // {
                //   title: 'Số lượng',
                //   dataIndex: 'num',
                // },
              ]
            : viewPage == 2
            ? inventoryColumns
            : [
                {
                  title: 'Ngày xuất kho',
                  render(data) {
                    return (
                      data &&
                      moment(String(data).replace(/\.000Z$/, '')).format(
                        'DD/MM/YYYY HH:mm:ss'
                      )
                    )
                  },
                },
                {
                  title: 'Mã khách hàng',
                  dataIndex: 'customer_code',
                },
                {
                  title: 'Tên đơn vị vận chuyển',
                  dataIndex: 'shipping_partner_name',
                },
                {
                  title: 'Ghi chú',
                  dataIndex: 'note',
                },
                {
                  title: 'Cước xuất kho',
                  dataIndex: 'cost_export',
                  render(data) {
                    return data && formatNumber(data)
                  },
                },
                {
                  title: 'Thanh toán hộ(COD)',
                  dataIndex: 'cod',
                  render(data) {
                    return data && formatNumber(data)
                  },
                },
                {
                  title: 'Phụ phí',
                  dataIndex: 'sub_cost',
                  render(data) {
                    return data && formatNumber(data)
                  },
                },
                {
                  title: 'Mã đơn hàng',
                  dataIndex: 'code',
                },
                {
                  title: 'Số kiện',
                  dataIndex: 'num',
                },
              ]
        }
        dataSource={displayImportData}
        downTemplate={
          viewPage == 1 ? '/template/nhap_kho.xlsx' : '/template/xuat_kho.xlsx'
        }
      />
    </>
  )
}
