import React, { useState, useEffect, useContext, useRef } from 'react'
import locale from 'antd/es/date-picker/locale/vi_VN'

//components antd
import { Form, Row, Col, Select, Input, DatePicker, InputNumber } from 'antd'

//icon antd

import { translate } from 'utils/i18n'
import { ACTION } from 'consts'
import moment from 'moment'
import { useLocation, useHistory } from 'react-router-dom'
import Context from 'utils/Context'

//components
import CustomSpace from 'components/CustomSpace'

//apis
import { getUsers } from 'apis/users'
import { getShipping } from 'apis/shipping'
import { getWarehouses } from 'apis/warehouses'

const ShipmentForm = ({
  form,
  required,
  isUpdate,
  setIsUpdate,
  onChange,
  initialValue,
  getForm,
  is_disable_code = false,
  is_print = false,
}) => {
  const context = useContext(Context)
  console.log(context)
  const typingTimeoutRef = useRef()

  const [warehouses, setWarehouses] = useState([])

  const [customersList, setCustomersList] = useState([])

  const [ShippingPartner, setShippingPartner] = useState([])

  const [componentForm] = Form.useForm()

  const _getRuleRequired = (field) => {
    return {
      required,
      message: translate('Please input') + ' ' + translate(field),
    }
  }

  const getAllCustomer = (params) => {
    getUsers(params)
      .then((res) => {
        if (initialValue && res.data.success) {
          var customer = res.data.data.find(
            (customer) => customer.id == initialValue.id_customer
          )

          if (componentForm)
            componentForm.setFieldsValue({
              customer_code: customer?.customer_code,
            })

          if (form)
            form.setFieldsValue({
              customer_code: customer?.customer_code,
            })
        }
        if (res.data.success) setCustomersList(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getAllWarehouse = () => {
    getWarehouses()
      .then((res) => {
        if (res.data.success) {
          if (context && context.warehouse_info) {
            if (form)
              form.setFieldsValue({
                warehouse_id: context?.warehouse_id,
              })
            if (componentForm)
              componentForm.setFieldsValue({
                warehouse_id: context?.warehouse_id,
              })
          }

          setWarehouses(res.data.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getAllShippingPartner = () => {
    getShipping({ area: 'International' })
      .then((res) => {
        if (res.data.success) {
          setShippingPartner(res.data.data)
          const shippingPartnerDefault = res.data.data.find(
            (data) =>
              data.is_default && context.warehouse_id === data.warehouse_id
          )
          if (shippingPartnerDefault)
            form.setFieldsValue({
              shipping_partner_name: shippingPartnerDefault.name,
            })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // const getInitialWarehouses = async () => {
  //   try {
  //     context.dispatch({ name: ACTION.LOADING, data: true });
  //     const res = await getWarehouses();
  //     if (res.status === 200) {
  //       setWarehouses(res.data.data);
  //     }
  //     context.dispatch({ name: ACTION.LOADING, data: false });
  //   } catch (error) {
  //     context.dispatch({ name: ACTION.LOADING, data: false });
  //     console.log(error);
  //   }
  // };

  //check user is update
  const _onFieldsChange = () => {
    if (!isUpdate && typeof setIsUpdate === 'function') setIsUpdate(true)
  }

  const onChangeFieldsValue = (changedFields, allFields) => {
    if (onChange) {
      let tmp = {}

      allFields.forEach((e) => {
        tmp = { ...tmp, [e.name[0]]: e.value }
      })

      onChange(tmp)
    }
  }

  const loadData = async () => {
    context.dispatch({ name: ACTION.LOADING, data: true })
    if (initialValue && componentForm) {
      componentForm.setFieldsValue({
        ...initialValue,
        date_sign: initialValue.date_sign
          ? moment(initialValue.date_sign).tz('Asia/Ho_Chi_Minh')
          : null,
      })
    }

    if (form)
      form.setFieldsValue({
        mass: 0.5,
        total_quantity_package: 1,
        cod: 0,
        volume: 0,
        fee_bag: 0,
      })
    await Promise.all([
      getAllCustomer({ page: 1, pageSize: 1000 }),
      getUsers({ page: 1, pageSize: 1000 }),
      getAllShippingPartner(),
      getAllWarehouse(),
    ])

    getForm && getForm(componentForm)
    context.dispatch({ name: ACTION.LOADING, data: false })
  }

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    if (!initialValue) return

    if (componentForm && context && !initialValue?.warehouse_id)
      componentForm.setFieldsValue({
        warehouse_id: context?.warehouse_id,
      })

    if (form && context && !initialValue?.warehouse_id)
      form.setFieldsValue({
        warehouse_id: context?.warehouse_id,
      })
  }, [context])
  return (
    <>
      <Form
        layout="vertical"
        form={form || componentForm}
        onFieldsChange={onChangeFieldsValue}>
        <Row gutter={100}>
          <Col xs={24} md={8}>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  label="Thời gian ký nhận"
                  name="date_sign"
                  rules={[_getRuleRequired('Thời gian ký nhận')]}>
                  <DatePicker
                    locale={locale}
                    showTime
                    showSecond={false}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="tracking_number"
                  label={translate('Waybill number')}
                  rules={[_getRuleRequired('Waybill number')]}>
                  <Input
                    onChange={(e) => {
                      const value = e.target.value
                      if (typingTimeoutRef.current) {
                        clearTimeout(typingTimeoutRef.current)
                      }
                      typingTimeoutRef.current = setTimeout(async () => {
                        componentForm.setFieldsValue({
                          tracking_number: value + ', ',
                        })
                        form.setFieldsValue({
                          tracking_number: value + ', ',
                        })
                      }, 400)
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="mass"
                  label={translate('Mass')}
                  rules={[_getRuleRequired('Mass')]}>
                  <InputNumber
                    min={0}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  required
                  name="total_quantity_package"
                  label={translate('Amount package')}
                  rules={[
                    { ..._getRuleRequired('Amount package'), required: true },
                  ]}
                  validateTrigger={false}>
                  <InputNumber
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    style={{ width: '100%' }}
                    min={0}
                  />
                </Form.Item>
              </Col>
              {!is_print && (
                <Col span={24}>
                  <Form.Item
                    initialValue={0}
                    required
                    name="cost_origin"
                    label="Giá vốn"
                    rules={[
                      { message: 'Vui lòng nhập giá vốn', required: true },
                    ]}
                    validateTrigger={false}>
                    <InputNumber
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      style={{ width: '100%' }}
                      min={0}
                    />
                  </Form.Item>
                </Col>
              )}
              {isUpdate && (
                <>
                  <Col span={24}>
                    <Form.Item
                      initialValue={0}
                      required
                      name="unit_price"
                      label="Đơn giá"
                      rules={[
                        { message: 'Vui lòng nhập đơn giá', required: true },
                      ]}
                      validateTrigger={false}>
                      <InputNumber
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        style={{ width: '100%' }}
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      initialValue={0}
                      required
                      name="sum_cost"
                      label="Tổng cước"
                      rules={[
                        { message: 'Vui lòng nhập tổng cước', required: true },
                      ]}
                      validateTrigger={false}>
                      <InputNumber
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        style={{ width: '100%' }}
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
          </Col>

          <Col xs={24} md={8}>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  required
                  name="customer_code"
                  label={translate('Mã khách hàng')}
                  rules={[
                    { ..._getRuleRequired('Mã khách hàng'), required: true },
                  ]}
                  validateTrigger={false}>
                  <Select
                    // onSelect={_onSelectInvoiceType}
                    filterOption={(input, option) =>
                      option.children &&
                      option.children
                        .toLowerCase()
                        .indexOf(input && input.toLowerCase()) >= 0
                    }
                    optionFilterProp="children"
                    showSearch>
                    {customersList
                      .filter((e) => e.role_id === 7)
                      .map((val, index) => (
                        <Select.Option
                          value={val.customer_code}
                          key={val.customer_code}>
                          {val.customer_code + ' (' + val.name + ')'}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label={translate('Product Description')}>
                  <Input.TextArea />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="cod"
                  label={translate('Payment help')}
                  rules={[_getRuleRequired('Payment help')]}>
                  <InputNumber
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    min={0}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="volume"
                  label="Khối lượng"
                  rules={[_getRuleRequired('Volume')]}>
                  <InputNumber
                    min={0}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              {!is_print && (
                <Col span={24}>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="sub_fee_kg"
                    label=" Phụ phí trọng lượng (kg)"
                    rules={[_getRuleRequired('Phụ phí trọng lượng (kg)')]}>
                    <InputNumber
                      min={0}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              )}
              {!is_print && (
                <Col span={24}>
                  <Form.Item
                    label="Phí nâng hàng"
                    name="fee_package"
                    //rules={[_getRuleRequired('Phí nâng hàng')]}
                  >
                    <InputNumber
                      min={0}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Col>

          <Col xs={24} md={8}>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  label="Phí Đóng Gỗ/Đóng Tải"
                  name="fee_bag"
                  rules={[_getRuleRequired('Phí Đóng Gỗ/Đóng Tải')]}>
                  <InputNumber
                    min={0}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  required
                  name="code_bill"
                  label={translate('Bill number')}
                  rules={[
                    { ..._getRuleRequired('Bill number'), required: true },
                  ]}
                  validateTrigger={false}>
                  <Input disabled={is_disable_code} />
                </Form.Item>
              </Col>

              {/* <Col span={24}>
                <Form.Item
                  label="Giá vốn (Công nợ v/c quốc tế)"
                  name="cost_origin"
                  rules={[_getRuleRequired('Giá vốn')]}>
                  <InputNumber
                    min={0}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col> */}

              {!is_print && (
                <Col span={24}>
                  <Form.Item name="sub_fee_m3" label="Phụ phí khối lượng(m3)">
                    <InputNumber
                      min={0}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Form.Item required name="warehouse_id" label={'Kho'}>
                  <Select optionFilterProp="children" showSearch>
                    {warehouses.map((val, index) => (
                      <Select.Option value={val.id} key={val.id}>
                        {val.name + ' (' + val.code + ')'}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="shipping_partner_name"
                  label={translate('Shipping partner')}
                  rules={[_getRuleRequired('Shipping partner')]}>
                  <Select
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    optionFilterProp="children"
                    showSearch>
                    {ShippingPartner.map((e) => (
                      <Select.Option value={e.name}>{e.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  )
}

ShipmentForm.defaultProps = {
  required: true,
}

export default ShipmentForm
