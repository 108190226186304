import React, { useEffect, useState } from 'react'
import {
  Table,
  Row,
  Typography,
  Button,
  notification,
  Popconfirm,
  Space,
  Tooltip,
} from 'antd'

import columnsDefault from './columns'
import WarehouseForm from './WarehouseForm'
import Permission from 'components/Permission'

//apis
import { getWarehouses, deleteWarehouse } from 'apis/warehouses'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { PERMISSIONS } from 'consts/permissions'

export default function WarehouseList() {
  const [filter, setFilter] = useState({ page: 1, pageSize: 300 })
  const [warehouses, setWarehouses] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0)

  const onDeleteWarehouse = async (id) => {
    try {
      setLoading(true)
      const res = await deleteWarehouse(id)
      setLoading(false)

      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Xóa kho thành công' })
          onGetWarehouses()
        } else
          notification.error({
            message: res.data.mess || res.data.message || 'Xóa kho thất bại',
          })
      } else
        notification.error({
          message: res.data.mess || res.data.message || 'Xóa kho thất bại',
        })
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const onGetWarehouses = async () => {
    try {
      setLoading(true)
      const res = await getWarehouses(filter)
      setLoading(false)
      if (res.data.success && res.status === 200) {
        setWarehouses(res.data.data)
        setTotalCount(res.data.count)
      }
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  useEffect(() => {
    onGetWarehouses()
  }, [filter])

  return (
    <div>
      <Row justify="space-between">
        <Typography.Title level={4}>Danh sách kho</Typography.Title>
        <Permission permissions={[PERMISSIONS.tao_kho]}>
          <WarehouseForm reload={onGetWarehouses}>
            <Button type="primary">Thêm kho</Button>
          </WarehouseForm>
        </Permission>
      </Row>
      <br />
      <Table
        loading={loading}
        pagination={{
          pageSize: filter.pageSize,
          current: filter.page,
          total: totalCount,
          onChange: (page, pageSize) =>
            setFilter({ ...filter, page, pageSize }),
        }}
        columns={columnsDefault.map((column) => {
          if (column.key === 'stt')
            return {
              ...column,
              render: (text, record, index) =>
                (filter.page - 1) * filter.pageSize + index + 1,
            }
          if (column.key === 'action')
            return {
              ...column,
              render: (text, record) => (
                <Space>
                  {/* <Popconfirm
                    onConfirm={() => onDeleteWarehouse(record.warehouse_id)}
                    title="Bạn có muốn xóa kho này không?">
                    <Button type="primary" danger icon={<DeleteOutlined />} />
                  </Popconfirm> */}
                  <Permission permissions={[PERMISSIONS.cap_nhat_kho]}>
                    <WarehouseForm record={record} reload={onGetWarehouses}>
                      <Tooltip title="Cập nhật kho">
                        <Button
                          style={{ marginLeft: 10 }}
                          type="primary"
                          icon={<EditOutlined />}
                        />
                      </Tooltip>
                    </WarehouseForm>
                  </Permission>
                </Space>
              ),
            }
          return column
        })}
        scroll={{ x: 'max-content', y: '60vh' }}
        dataSource={warehouses}
        loading={loading}
        size="small"
      />
    </div>
  )
}
