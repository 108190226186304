import React, { useState, useEffect } from 'react'

import styles from './info.module.scss'
import { LineChart } from 'react-chartkick'
import 'chartkick/chart.js'
import locale from 'antd/es/date-picker/locale/vi_VN'

//components antd
import { Select, DatePicker, Row, Col } from 'antd'

//icons antd
import { ShoppingCartOutlined, CreditCardOutlined } from '@ant-design/icons'
import BalanceItem from './balance-item'

//apis
import { getSummaries } from 'apis/summary'

import { getTimeRange, removeNull } from 'utils'
import { translate } from 'utils/i18n'
import { ROUTES } from 'consts'
const { Option } = Select
const { RangePicker } = DatePicker
const Dashboard = () => {
  const [dataChart, setDataChart] = useState([])
  const [summaries, setSummaries] = useState(null)
  const [filter, setFilter] = useState({})
  const [selectedDate, setSelectedDate] = useState()
  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const toggleOpenSelect = () => setIsOpenSelect(!isOpenSelect)
  const changeRange = (date, dateString) => {
    setFilter({ ...filter, startDate: dateString[0], endDate: dateString[1] })
    setSelectedDate(`${dateString[0]} -> ${dateString[1]}`)
  }
  const changeTimeOption = (value) => {
    let tmp = getTimeRange(value)
    setSelectedDate(value)
    setFilter({ ...filter, startDate: tmp.from_date, endDate: tmp.to_date })
  }

  const _getSummaries = async (params) => {
    try {
      const res = await getSummaries(params)
      console.log(res)
      if (res.status === 200) {
        setSummaries(res.data)
        setDataChart(res.data.dataChart)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    _getSummaries(removeNull(filter))
  }, [filter])

  return (
    <div className={styles['dashboard_manager']}>
      <div className={styles['dashboard_manager_balance']}>
        <Row style={{ width: '100%', padding: '20px' }} gutter={30}>
          <Col>
            <Row style={{ color: 'gray' }}>Lọc theo ngày</Row>
            <Row>
              <Select
                open={isOpenSelect}
                onBlur={() => {
                  if (isOpenSelect) toggleOpenSelect()
                }}
                onClick={() => {
                  if (!isOpenSelect) toggleOpenSelect()
                }}
                value={selectedDate}
                style={{ width: 380 }}
                placeholder="Choose time"
                allowClear
                onChange={async (value) => {
                  if (isOpenSelect) toggleOpenSelect()
                  changeTimeOption(value)
                }}
                dropdownRender={(menu) => (
                  <div>
                    <RangePicker
                      placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                      onFocus={() => {
                        if (!isOpenSelect) toggleOpenSelect()
                      }}
                      onBlur={() => {
                        if (isOpenSelect) toggleOpenSelect()
                      }}
                      locale={locale}
                      style={{ width: '100%' }}
                      onChange={changeRange}
                      showTime
                      format="YYYY-MM-DD"
                      onOpenChange={() => {
                        setTimeout(() => {
                          let timePicker = document.querySelector(
                            '.ant-picker-panel-container .ant-picker-time-panel'
                          )
                          if (timePicker) timePicker.style = 'display:none'
                        }, 500)
                      }}
                    />
                    {menu}
                  </div>
                )}>
                <Option value="to_day">{translate('Today')} </Option>
                <Option value="yesterday">{translate('Yesterday')}</Option>
                <Option value="this_week">{translate('This week')}</Option>
                <Option value="last_week">{translate('Last week')}</Option>
                <Option value="last_month">{translate('Last month')}</Option>
                <Option value="this_month">{translate('This month')}</Option>
                <Option value="this_year">{translate('This year')}</Option>
                <Option value="last_year">{translate('Last year')}</Option>
              </Select>
            </Row>
          </Col>
        </Row>
        <div className={styles['dashboard_manager_balance_items']}>
          <Row gutter={[20, 20]}>
            <Col span={8}>
              <BalanceItem
                route={ROUTES.ORDERS}
                colorFrom="rgba(66, 78, 190, 1)"
                colorTo="rgba(97, 107, 201, 1)"
                title="Tổng số đơn hàng hoàn thành"
                icon={<ShoppingCartOutlined />}
                price={
                  summaries
                    ? summaries.countOrderComplete + '/' + summaries.countOrder
                    : ''
                }
              />
            </Col>
            <Col span={8}>
              <BalanceItem
                route={ROUTES.DEBIT_NOTE}
                colorFrom="rgba(143, 66, 190, 1)"
                colorTo="rgba(130, 97, 201, 1)"
                title="Tổng tiền đã thu KH"
                icon={<CreditCardOutlined />}
                price={summaries ? summaries.totalDebitCustomerPaid : 0}
              />
            </Col>
            <Col span={8}>
              <BalanceItem
                route={ROUTES.DEBIT_NOTE}
                colorFrom="rgba(190, 66, 163, 1)"
                colorTo="rgba(193, 103, 157, 1)"
                title="Tổng tiền chưa thu KH"
                icon={<CreditCardOutlined />}
                price={summaries ? summaries.totalDebitCustomerNotPaid : 0}
              />
            </Col>

            <Col span={8}>
              <BalanceItem
                route={ROUTES.DEBIT_NOTE}
                colorFrom="rgba(143, 66, 190, 1)"
                colorTo="rgba(130, 97, 201, 1)"
                title="Tổng nợ đã chi với v/c quốc tế"
                icon={<CreditCardOutlined />}
                price={summaries ? summaries.totalDebitInternationalHadPaid : 0}
              />
            </Col>

            <Col span={8}>
              <BalanceItem
                route={ROUTES.DEBIT_NOTE}
                colorFrom="rgba(190, 66, 163, 1)"
                colorTo="rgba(193, 103, 157, 1)"
                title="Tổng nợ chưa chi với v/c quốc tế"
                icon={<CreditCardOutlined />}
                price={summaries ? summaries.totalDebitInternationalNotPaid : 0}
              />
            </Col>

            <Col span={8}>
              <BalanceItem
                route={ROUTES.DEBIT_NOTE}
                colorFrom="rgba(143, 66, 190, 1)"
                colorTo="rgba(130, 97, 201, 1)"
                title="Tổng nợ đã chi với v/c nội địa"
                icon={<CreditCardOutlined />}
                price={summaries ? summaries.totalDebitInlandHadPaid : 0}
              />
            </Col>

            <Col span={8}>
              <BalanceItem
                route={ROUTES.DEBIT_NOTE}
                colorFrom="rgba(190, 66, 163, 1)"
                colorTo="rgba(193, 103, 157, 1)"
                title="Tổng nợ chưa chi với v/c nội địa"
                icon={<CreditCardOutlined />}
                price={summaries ? summaries.totalDebitInlandNotPaid : 0}
              />
            </Col>
            {/* <Col span={8}>
              <BalanceItem
                route={ROUTES.DEBIT_NOTE}
                colorFrom="rgba(190, 66, 163, 1)"
                colorTo="rgba(193, 103, 157, 1)"
                title="Tổng đã chi với v/c quốc tế"
                icon={<CreditCardOutlined />}
                price={summaries ? summaries.totalDebitInternationalNotPaid : 0}
              />
            </Col> */}
          </Row>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          margin: '1em 0',
          background: '#fff',
          padding: '1rem',
        }}>
        <h2>
          Biểu đồ thể hiện đơn hàng hôm nay và hôm qua theo thời gian trong ngày
        </h2>
        <LineChart data={dataChart} />
      </div>
    </div>
  )
}
export default Dashboard
