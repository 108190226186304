import React, { useState, useEffect, useContext, useRef } from 'react'

import customersColumns from './customersColumns'
import Context from 'utils/Context'
import { ACTION } from 'consts'
import { translate } from 'utils/i18n'
import { formatNumber } from 'utils'
import { PERMISSIONS } from 'consts/permissions'
import XLSX from 'xlsx'
//icon antd
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
  LinkOutlined,
} from '@ant-design/icons'

//component antd
import {
  Table,
  Row,
  Typography,
  notification,
  Col,
  Input,
  Popconfirm,
  Select,
  Button,
  Modal,
  Upload,
  Space,
  Result,
} from 'antd'

//component
import ModalCreateCustomer from './ModalCreateCustomer'
import Permission from 'components/Permission'

//apis
import { getUsers, deleteUser, importCustomer } from 'apis/users'

import { getPrice } from 'apis/price'

export default () => {
  const typingTimeoutRef = useRef(null)

  const [customers, setCustomers] = useState([])
  const [paramsFilter, setParamsFilter] = useState({ page: 1, pageSize: 300 })
  const [count, setCount] = useState(0)
  const [filterKey, setFilterKey] = useState('customer_code')
  const [showImport, setShowImport] = useState(false)
  const [importData, setImportData] = useState([])
  const [importLoading, setImportLoading] = useState(false)

  const [effortPriceList, setEffortPriceList] = useState({})
  const context = useContext(Context)

  //Role client sẽ lấy customers receiver cùng companyId
  //Role admin, subadmin,... lấy all
  const getCustomers = async (params) => {
    try {
      context.dispatch({ name: ACTION.LOADING, data: true })

      const res = await getUsers({ role_id: 7, ...params })
      console.log(res)
      if (res.data && res.data.success) {
        setCustomers(res.data.data)
        setCount(res.data.count)
      } else {
        notification.error({
          message: res.data?.mess || 'Có lỗi xảy ra, vui lòng thử lại',
        })
      }
      //  else

      context.dispatch({ name: ACTION.LOADING, data: false })
    } catch (error) {
      notification.error({
        message: 'Hệ thống đang khởi động, vui lòng đợi tí',
      })
      context.dispatch({ name: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const onSearch = (e) => {
    const value = e.target.value
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      paramsFilter.page = 1
      delete paramsFilter.name
      delete paramsFilter.customer_code
      if (!value) delete paramsFilter.search
      else paramsFilter[filterKey] = value
      getCustomers(paramsFilter)
      setParamsFilter(paramsFilter)
    }, 750)
  }

  const onDeleteCustomer = async (userId) => {
    try {
      context.dispatch({ name: ACTION.LOADING, data: true })
      const res = await deleteUser(userId)
      console.log(res)
      if (res.status === 200) {
        getCustomers(paramsFilter)
        notification.success({ message: translate('Delete customer success') })
      } else
        notification.error({
          message: 'Error',
          description: translate('Delete customer failed'),
        })
      context.dispatch({ name: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      context.dispatch({ name: ACTION.LOADING, data: false })
    }
  }

  const getEffortPrice = async () => {
    try {
      const res = await getPrice()
      if (res.data.success && res.status == 200) {
        setEffortPriceList(
          res.data.data.reduce((a, b) => ((a[b.id] = b), a), {})
        )
      }
    } catch (err) {
      console.log(err)
    }
  }

  const customerConvertFields = (data, reverse = false) => {
    const template = {
      customer_code: 'Mã khách hàng(*)',
      name: 'Tên khách hàng(*)',
      tel: 'Điện thoại',
      address: 'Địa chỉ',
      email: 'Email',
      sum_debit: 'Tổng công nợ',
      password: 'Mật khẩu(*)',
    }
    if (reverse) {
      return Object.keys(template).reduce((a, b) => {
        if (b == 'customer_code') {
          a[template[b]] = data.customer_code
        } else a[template[b]] = data[b]
        return a
      }, {})
    }
    let success = true
    let tmp = Object.keys(template).reduce((a, b) => {
      if (b != 'sub_cost') {
        if (data[template[b]] === '') {
          success = false
          notification.error({ message: `${template[b]} không được để trống` })
        } else {
          a[b] = data[template[b]]
        }
      } else a[b] = data[template[b]]
      return a
    }, {})
    return success ? tmp : null
  }

  useEffect(() => {
    getEffortPrice()
    getCustomers(paramsFilter)
  }, [])

  const settings = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      authorization: 'authorization-text',
    },
    maxCount: 1,
    onChange(info) {
      if (info.file.status === 'uploading') {
        setImportLoading(true)
        const reader = new FileReader()
        reader.onload = async (e) => {
          const bstr = e.target.result
          const workBook = XLSX.read(bstr, {
            type: 'binary',
            cellDates: true,
          })
          const workSheetname = workBook.SheetNames[0]
          const workSheet = workBook.Sheets[workSheetname]

          const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 0 })

          const fileDataConverted = fileData
            .map((e) => {
              const newData = customerConvertFields(e)
              if (newData) {
                return {
                  ...newData,
                  customer_code: newData.customer_code || null,
                  name: newData.name || null,
                  tel: newData.tel || null,
                  address: newData.address || '',
                  email: newData.email || '',
                  sum_debit: newData.sum_debit || 0,
                  password: newData.password || '123456',
                  role_id: 7,
                }
              }
            })
            .filter((e) => e)
          setImportData(fileDataConverted)
        }

        reader.readAsBinaryString(info.file.originFileObj)
      }
      setImportLoading(false)
    },
  }

  const onImport = async () => {
    try {
      setImportLoading(true)
      const res = await importCustomer({
        data: importData,
      })
      if (res.data && res.data.success) {
        getCustomers()
        setShowImport(false)
        setImportLoading(false)
        setImportData([])
        notification.success({
          message: 'Nhập khẩu khách hàng thành công',
          description:
            'Cập nhật: ' +
            res.data?.data?.count_update +
            ', Thêm mới: ' +
            res.data?.data?.count_insert,
        })
      } else {
        notification.error({
          message: 'Nhập khẩu khách hàng thất bại',
          description: res.data.mess,
        })
      }
    } catch (err) {
      notification.error({
        message: 'Nhập khẩu khách hàng thất bại',
      })
    }
  }

  const ImportButton = () => (
    <Upload {...settings}>
      <Button>Chọn File Nhập</Button>
    </Upload>
  )

  return (
    <>
      <Row justify="space-between">
        <Col>
          <Typography.Title level={4}>
            {translate('Customers')}
          </Typography.Title>
        </Col>
        <Space align={'center'}>
          {/* <Button
            icon={<UploadOutlined />}
            type="primary"
            style={{
              backgroundColor: '#C12222D4',
              border: 'none',
              marginRight: 10,
            }}
            onClick={() => setShowImport(true)}>
            Nhập khẩu
          </Button> */}

          <Permission permissions={[PERMISSIONS.tao_khach_hang]}>
            <ModalCreateCustomer
              getInitialCustomersReceiver={() => getCustomers(paramsFilter)}
            />
          </Permission>
        </Space>
      </Row>
      <br />
      <Row gutter={20}>
        <Col xs={24} lg={9}>
          <Row>Tìm kiếm khách hàng</Row>
          <Input.Group compact>
            <Input
              prefix={<SearchOutlined style={{ color: '#bdc3c7' }} />}
              placeholder={translate('Find customer')}
              allowClear
              enterButton="Search"
              onChange={onSearch}
              style={{ width: '60%' }}
            />
            <Select
              style={{ width: '40%' }}
              value={filterKey}
              onChange={setFilterKey}>
              <Select.Option value="customer_code">Mã khách hàng</Select.Option>
              <Select.Option value="name">Tên khách hàng</Select.Option>
            </Select>
          </Input.Group>
        </Col>
      </Row>

      <br />
      <Table
        style={{ width: '100%' }}
        columns={customersColumns.map((e) => {
          if (e.key === 'action')
            return {
              ...e,
              width: 100,
              render: (text, record) => (
                <Row justify="center" align="middle" wrap={false}>
                  <Permission permissions={[PERMISSIONS.cap_nhat_khach_hang]}>
                    <ModalCreateCustomer
                      getInitialCustomersReceiver={() =>
                        getCustomers(paramsFilter)
                      }
                      customer={{
                        ...record,
                        effort_prices: record.customer_effort_prices.map(
                          (e) => e.id_effort_price
                        ),
                      }}>
                      <EditOutlined
                        style={{
                          fontSize: 16,
                          color: '#1890FF',
                          cursor: 'pointer',
                        }}
                      />
                    </ModalCreateCustomer>
                  </Permission>
                  <Permission permissions={[PERMISSIONS.xoa_khach_hang]}>
                    <Popconfirm
                      title={`${translate(
                        'Are you sure delete this customer'
                      )} ?`}
                      okText="Ok"
                      cancelText="No"
                      onConfirm={() => onDeleteCustomer(record.id)}>
                      <DeleteOutlined
                        style={{
                          marginLeft: 10,
                          fontSize: 16,
                          color: 'orange',
                          cursor: 'pointer',
                        }}
                      />
                    </Popconfirm>
                  </Permission>
                </Row>
              ),
            }

          if (e.key === 'group_telegram_id')
            return {
              ...e,
              width: 100,
              render: (text, record) => <div>{record.group_telegram_id}</div>,
            }

          if (e.dataIndex === 'customer_effort_prices')
            return {
              ...e,
              render(data) {
                return (
                  data &&
                  data.length > 0 &&
                  data.map((e) => {
                    return <div>{e?.effort_price?.title}</div>
                  })
                )
              },
            }

          return e
        })}
        dataSource={customers}
        size="small"
        pagination={{
          current: paramsFilter.page,
          defaultPageSize: paramsFilter.pageSize,
          pageSizeOptions: [100, 200, 300, 400],
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            paramsFilter.page = page
            paramsFilter.pageSize = pageSize

            setParamsFilter(paramsFilter)
            getCustomers(paramsFilter)
          },
          total: count,
        }}
        summary={(pageData) => (
          <Table.Summary.Row style={{ fontWeight: 500 }}>
            <Table.Summary.Cell>Tổng:</Table.Summary.Cell>
            <Table.Summary.Cell></Table.Summary.Cell>
            <Table.Summary.Cell></Table.Summary.Cell>
            <Table.Summary.Cell></Table.Summary.Cell>
            <Table.Summary.Cell>
              {formatNumber(
                pageData.reduce((a, b) => a + (b.revenueSum || 0), 0)
              )}
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              {formatNumber(
                pageData.reduce((a, b) => a + (b.sum_debit || 0), 0)
              )}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )}
      />

      <Modal
        title={
          <a
            target="_blank"
            href="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022_11_10_76f3edf1-ea0b-4009-aada-6806fbce993d_nhap_khau_khach_hang.xlsx">
            <Button type="link" icon={<LinkOutlined />}>
              Tải xuống mẫu file
            </Button>
          </a>
        }
        visible={showImport}
        onCancel={() => {
          setShowImport(false)
          setImportData([])
        }}
        width={1000}
        centered
        footer={null}>
        <Row style={{ marginBottom: 15 }} gutter={20}>
          <Col>
            <h4>Chọn file nhập</h4>
            <ImportButton />
          </Col>
        </Row>
        {importData.length > 0 && (
          <Result
            status="success"
            title="Tải file thành công"
            subTitle={'Số lượng khách hàng tải lên: ' + importData.length}
            extra={[
              <Button
                loading={importLoading}
                onClick={(e) => {
                  onImport()
                }}
                type="primary"
                key="console">
                Tiến hành nhập vào hệ thống
              </Button>,
            ]}
          />
        )}

        {/* <Table
            columns={[1, 2, 3, 4, 5, 6, 7, 8, 10, 21, 11, 20, 14].map((e) => {
              let col = shipmentsColumns.find((c) => c.key == e) || {}
              if (e == 1)
                return {
                  ...col,
                  ...tableColumnRender[e],
                  render(data) {
                    return data && moment(data).format('DD/MM/YYYY HH:mm:ss')
                  },
                }
              return { ...col, ...tableColumnRender[e] }
            })}
            pagination={{
              pageSize: 300,
            }}
            size="small"
            loading={importLoading}
            dataSource={importData}
            scroll={{ x: 'max-content' }}
          /> */}
      </Modal>
    </>
  )
}
