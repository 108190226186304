import React, { useEffect } from 'react'
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom'
import { ROUTES } from 'consts'

//components
import BaseLayout from 'components/BaseLayout'
import Authentication from 'components/Authentication'

import { translate } from 'utils/i18n'
import { PERMISSIONS } from 'consts/permissions'

//views
import Order from './Order'
import OrderTracking from './OrderTracking'
import CreateShipment from './CreateOrder'
import PageNotFound from './404'
import Login from './Login'
import Information from './Information'
import Users from './Users'
import DetailUser from './DetailUser'
import Warehouses from './Warehouses'
import WarehouseList from './Warehouses/WarehouseList'
import Customers from './Customers'
import DebitNote from './DebitNote'
import DetailDebitNote from './DetailDebitNote'
import AccountSetting from './AccountSetting'
import ShippingPartner from './ShippingPartner'
import CreateShippingPartner from './CreateShippingPartner'
import TablePrice from './TablePrice'
import TablePriceCreatePage from './TablePrice/tabelPriceCreatePage'
import SearchBill from './Search'
import CreateWarehouseExport from './createWarehouse/createExport'
import CreateWarehouseImport from './createWarehouse/createImport'
import UpdateImportWarehouse from './UpdateWarehouse/updateImportWarehouse'
import UpdateExportWarehouse from './UpdateWarehouse/updateExportWarehouse'
import OrderList from './UpdateWarehouse/orderList'
import Itinerary from './Itinerary'
import UpdateOrders from './UpdateOrders'
import CreateOrderAndPrint from './CreateOrderAndPrint'
import Inventory from './Inventory'
import Role from './Role'
import Profile from './Profile'

const DEFINE_ROUTER = [
  {
    path: ROUTES.LOGIN,
    Component: () => <Login />,
    exact: true,
    title: translate('Express Management'),
  },
  {
    path: ROUTES.OVERVIEW,
    Component: () => <Information />,
    permissions: [PERMISSIONS.tong_quan],
    exact: true,
    title: translate('Overview'),
  },
  {
    path: ROUTES.ORDERS,
    Component: () => <Order />,
    permissions: [PERMISSIONS.xem_don_hang],
    exact: true,
    title: translate('Shipments'),
  },
  {
    path: ROUTES.CREATE_SHIPMENT_AND_PRINT,
    Component: () => <CreateOrderAndPrint />,
    permissions: [PERMISSIONS.xem_don_hang],
    exact: true,
    title: translate('Shipments'),
  },
  {
    path: ROUTES.ORDERS_UPDATE,
    Component: () => <UpdateOrders />,
    permissions: [PERMISSIONS.cap_nhat_don_hang],
    exact: true,
    title: translate('Update orders'),
  },
  {
    path: ROUTES.CREATE_SHIPMENT,
    Component: () => <CreateShipment />,
    permissions: [PERMISSIONS.tao_don_hang],
    exact: true,
    title: translate('Create Shipments'),
  },
  {
    path: ROUTES.DETAIL_DEBIT_NOTE,
    Component: () => <DetailDebitNote />,
    permissions: [PERMISSIONS.cap_nhat_cong_no],
    exact: true,
    title: 'Chi tiết công nợ',
  },
  {
    path: ROUTES.DEBIT_NOTE,
    Component: () => <DebitNote />,
    permissions: [PERMISSIONS.xem_cong_no],
    exact: true,
    title: 'Quản lí công nợ',
  },
  // {
  //   path: ROUTES.ACCOUNT_SETTING,
  //   Component: () => <AccountSetting />,
  //   permissions: [],
  //   exact: true,
  //   title: translate('Account Setting'),
  // },
  {
    path: ROUTES.CUSTOMERS,
    Component: () => <Customers />,
    permissions: [PERMISSIONS.xem_khach_hang],
    exact: true,
    title: 'Quản lí khách hàng',
  },
  {
    path: ROUTES.HOME,
    Component: () => <SearchBill />,
    permissions: [PERMISSIONS.tra_cuu_don],
    exact: true,
    title: 'Tra cứu đơn',
  },
  {
    path: ROUTES.PROFILE,
    Component: () => <Profile />,
    permissions: [],
    exact: true,
    title: 'Hồ sơ',
  },
  {
    path: ROUTES.CREATE_WAREHOUSE_EXPORT,
    Component: () => <CreateWarehouseExport />,
    permissions: [PERMISSIONS.tao_phieu_xuat],
    exact: true,
    title: 'Tạo phiếu xuất kho',
  },
  {
    path: ROUTES.CREATE_WAREHOUSE_IMPORT,
    Component: () => <CreateWarehouseImport />,
    permissions: [PERMISSIONS.tao_phieu_nhap],
    exact: true,
    title: 'Tạo phiếu nhập kho',
  },
  {
    path: ROUTES.USERS,
    Component: () => <Users />,
    permissions: [PERMISSIONS.nhan_vien],
    exact: true,
    title: translate('Users'),
  },
  {
    path: ROUTES.DETAIL_USER,
    Component: () => <DetailUser />,
    permissions: [PERMISSIONS.tao_nhan_vien],
    exact: true,
    title: translate('Detail User'),
  },
  {
    path: ROUTES.WAREHOUSES,
    Component: () => <Warehouses />,
    permissions: [PERMISSIONS.tao_phieu_nhap, PERMISSIONS.tao_phieu_xuat],
    exact: true,
    title: translate('Warehouses'),
  },
  {
    path: ROUTES.WAREHOUSE_LIST,
    Component: () => <WarehouseList />,
    permissions: [PERMISSIONS.danh_sach_kho],
    exact: true,
    title: 'Danh sách kho',
  },
  {
    path: ROUTES.SHIPPING_PARTNER,
    Component: () => <ShippingPartner />,
    permissions: [PERMISSIONS.van_chuyen],
    exact: true,
    title: translate('Shipping partner'),
  },
  {
    path: ROUTES.CREATE_SHIPPING_PARTNER,
    Component: () => <CreateShippingPartner />,
    permissions: [PERMISSIONS.tao_van_chuyen],
    exact: true,
    title: translate('Create shipping partner'),
  },
  {
    path: ROUTES.CUSTOMERS,
    Component: () => <Customers />,
    permissions: [PERMISSIONS.xem_khach_hang],
    exact: true,
    title: translate('Customers'),
  },
  {
    path: ROUTES.TABLE_PRICE,
    Component: () => <TablePrice />,
    permissions: [PERMISSIONS.xem_cong_thuc_tinh_gia],
    exact: true,
    title: translate('Setting Price'),
  },
  {
    path: ROUTES.CREATE_TABLE_PRICE,
    Component: () => <TablePriceCreatePage />,
    permissions: [PERMISSIONS.xem_cong_thuc_tinh_gia],
    exact: true,
    title: translate('Setting Price'),
  },
  {
    path: ROUTES.UPDATE_TABLE_PRICE,
    Component: () => <TablePriceCreatePage type="update" />,
    permissions: [PERMISSIONS.cap_nhat_cong_thuc_tinh_gia],
    exact: true,
    title: translate('Setting Price'),
  },
  {
    path: ROUTES.UPDATE_WAREHOUSE_IMPORT + '/:id',
    Component: () => <UpdateImportWarehouse />,
    permissions: [PERMISSIONS.cap_nhat_phieu_nhap],
    exact: true,
    title: 'Cập nhật phiếu nhập kho',
  },
  {
    path: ROUTES.UPDATE_WAREHOUSE_EXPORT + '/:id',
    Component: () => <UpdateExportWarehouse />,
    permissions: [PERMISSIONS.cap_nhat_phieu_xuat],
    exact: true,
    title: 'Cập nhật phiếu xuất kho',
  },
  {
    path: ROUTES.ORDER_LIST,
    Component: () => <OrderList />,
    permissions: [PERMISSIONS.xem_don_hang],
    exact: true,
    title: 'Danh sách đơn hàng',
  },
  {
    path: ROUTES.ITINERARY,
    Component: () => <Itinerary />,
    permissions: [PERMISSIONS.hanh_trinh],
    exact: true,
    title: 'Hành trình',
  },
  {
    path: ROUTES.INVENTORY,
    Component: () => <Inventory />,
    permissions: [
      PERMISSIONS.xem_ton_kho,
      PERMISSIONS.xem_phieu_nhap,
      PERMISSIONS.xem_phieu_xuat,
    ],
    exact: true,
    title: 'Hàng tồn kho',
  },
  {
    path: ROUTES.ROLE,
    Component: () => <Role />,
    permissions: [PERMISSIONS.vai_tro],
    exact: true,
    title: 'Vai trò',
  },
]

const DEFINE_ROUTER_NOT_AUTH = [
  {
    path: ROUTES.ORDER_TRACKING,
    Component: () => <OrderTracking />,
    permissions: [],
    exact: true,
    title: 'Theo dõi đơn hàng',
  },
]

export default () => {
  useEffect(() => {
    setInterval(() => {
      const pickerContent = document.querySelectorAll(
        'body .ant-picker-content'
      )
      for (let i = 0; i < pickerContent.length; i++) {
        const dates = pickerContent[i].querySelectorAll('th')
        const month = pickerContent[i].querySelectorAll(
          '.ant-picker-cell.ant-picker-cell-in-view .ant-picker-cell-inner'
        )

        if (dates && dates.length > 6) {
          dates[0].innerHTML = 'T.2'
          dates[1].innerHTML = 'T.3'
          dates[2].innerHTML = 'T.4'
          dates[3].innerHTML = 'T.5'
          dates[4].innerHTML = 'T.6'
          dates[5].innerHTML = 'T.7'
          dates[6].innerHTML = 'CN'
        }

        if (month && month.length === 12) {
          month[0].innerHTML = 'Tháng 1'
          month[1].innerHTML = 'Tháng 2'
          month[2].innerHTML = 'Tháng 3'
          month[3].innerHTML = 'Tháng 4'
          month[4].innerHTML = 'Tháng 5'
          month[5].innerHTML = 'Tháng 6'
          month[6].innerHTML = 'Tháng 7'
          month[7].innerHTML = 'Tháng 8'
          month[8].innerHTML = 'Tháng 9'
          month[9].innerHTML = 'Tháng 10'
          month[10].innerHTML = 'Tháng 11'
          month[11].innerHTML = 'Tháng 12'
          for (let j = 0; j < month.length; j++)
            month[j].style.whiteSpace = 'nowrap'
        }
      }

      const monthActive = document.querySelectorAll(
        '.ant-picker-header-view button.ant-picker-month-btn'
      )
      if (monthActive && monthActive.length) {
        for (let i = 0; i < monthActive.length; i++)
          switch (monthActive[i].innerText) {
            case 'Jan': {
              monthActive[i].innerHTML = 'T.1'
              break
            }
            case 'Feb': {
              monthActive[i].innerHTML = 'T.2'
              break
            }
            case 'Mar': {
              monthActive[i].innerHTML = 'T.3'
              break
            }
            case 'Apr': {
              monthActive[i].innerHTML = 'T.4'
              break
            }
            case 'May': {
              monthActive[i].innerHTML = 'T.5'
              break
            }
            case 'Jun': {
              monthActive[i].innerHTML = 'T.6'
              break
            }
            case 'Jul': {
              monthActive[i].innerHTML = 'T.7'
              break
            }
            case 'Aug': {
              monthActive[i].innerHTML = 'T.8'
              break
            }
            case 'Sep': {
              monthActive[i].innerHTML = 'T.9'
              break
            }
            case 'Oct': {
              monthActive[i].innerHTML = 'T.10'
              break
            }
            case 'Nov': {
              monthActive[i].innerHTML = 'T.11'
              break
            }
            case 'Dec': {
              monthActive[i].innerHTML = 'T.12'
              break
            }
            default:
              break
          }
      }
    }, 2000)
  }, [])
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact={true}>
          <Redirect to={ROUTES.HOME} />
        </Route>
        {DEFINE_ROUTER_NOT_AUTH.map(({ Component, ...rest }, index) => (
          <Route {...rest} key={index}>
            <Component />
          </Route>
        ))}
        {DEFINE_ROUTER.map(({ Component, ...rest }, index) => (
          <Route {...rest} key={index}>
            <Authentication {...rest}>
              <BaseLayout>
                <Component />
              </BaseLayout>
            </Authentication>
          </Route>
        ))}
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
