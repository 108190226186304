import React, { useState, useEffect } from 'react'
import { Row, Col, Tabs, Button, notification, Modal, Spin } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Link, useHistory, useParams } from 'react-router-dom'
import { ROUTES } from 'consts'
import WarehouseUpdateExportForm from 'components/WarehouseForm/UpdateExport'
import { updateCard, getCardByType } from 'apis/card'
import { removeNull } from 'utils'
export default function UpdateExportWarehouse() {
  const history = useHistory()
  const [data, setData] = useState([])
  const [activeTabs, setACtiveTabs] = useState(0)
  const [showConfirm, setShowConfirm] = useState({
    visible: false,
    type: true,
  })
  const [loading, setLoading] = useState(false)
  const params = useParams()
  const [disableUpdate, setDisableUpdate] = useState(false)
  const onUpdate = async (is_activate) => {
    if (is_activate) {
      let pass = true
      const body = data.map((e, index) => {
        console.log(e)
        if (!e.customer_code && !e.user) {
          notification.warning({ message: 'Vui lòng thêm khách hàng' })
          pass = false
          return
        }
        if (!e.orders.length) {
          notification.warning({ message: 'Vui lòng thêm đơn hàng' })
          pass = false
          return
        }
        if (!e.shipping_partner_name && !e.shipping_partner) {
          notification.warning({ message: 'Vui lòng thêm đơn vị vận chuyển' })
          pass = false
          return
        }
        if (!e.cost_export && e.cost_export != 0) {
          notification.warning({ message: 'Vui lòng nhập cước nhập kho' })
          pass = false
          return
        }

        return {
          id: e.id,
          cost: e.cost,
          type: 'export',
          is_activate,
          customer_code: (e.user && e.user.customer_code) || '',
          shipping_partner_name:
            e.shipping_partner_name ||
            (e.shipping_partner && e.shipping_partner.name) ||
            '',
          cod: e.cod || 0,
          note: e.note,
          cost_export: e.cost_export,
          sub_cost: e.sub_cost,
          data: e.orders.map((o) => {
            return {
              code_bill: o.code_bill,
              num: o.amount_package_export,
            }
          }),
        }
      })

      try {
        if (pass) {
          const res = await Promise.all(
            body.map((e) => {
              let tmp = { ...e }
              let idtmp = e.id

              delete tmp.id

              return updateCard(idtmp, removeNull(tmp))
            })
          )

          for (let i = 0; i < res.length; i++) {
            if (res[i].status === 200) {
              if (!res[i].data.success) {
                notification.error({
                  message: 'Thất bại',
                  description: res[i].data.mess || `Cập nhật phiếu thất bại`,
                })
                return
              }
            } else {
              notification.error({
                message: 'Thất bại',
                description: res[i].data.mess || `Cập nhật phiếu thất bại`,
              })
              return
            }
          }

          history.push({
            pathname: ROUTES.WAREHOUSES,
            state: {
              warehouseActiveTab: history.location?.state?.warehouseActiveTab,
            },
          })
        }
      } catch (err) {
        console.log(err)
        notification.error({ message: 'Cập nhật thất bại' })
      }
    } else {
      try {
        const body = data.map((e) => {
          return {
            id: e.id,
            cost: e.cost,
            is_activate,
            type: 'export',
            customer_code:
              e.customer_code || (e.user && e.user.customer_code) || '',
            shipping_partner_name:
              e.shipping_partner_name ||
              (e.shipping_partner && e.shipping_partner.name) ||
              '',
            cod: e.cod || 0,
            note: e.note,
            cost_export: e.cost_export,
            sub_cost: e.sub_cost,
            data: e.orders.map((o) => {
              return {
                code_bill: o.code_bill,
                num: o.amount_package_export,
              }
            }),
          }
        })
        const res = await Promise.all(
          body.map((e) => {
            let tmp = { ...e }
            let idtmp = e.id

            delete tmp.id

            return updateCard(idtmp, removeNull(tmp))
          })
        )
        for (let i = 0; i < res.length; i++) {
          if (res[i].status === 200) {
            if (!res[i].data.success) {
              notification.error({
                message: 'Thất bại',
                description: res[i].data.mess || `Cập nhật phiếu thất bại`,
              })
              return
            }
          } else {
            notification.error({
              message: 'Thất bại',
              description: res[i].data.mess || `Cập nhật phiếu thất bại`,
            })
            return
          }
        }

        history.push({
          pathname: ROUTES.WAREHOUSES,
          state: {
            warehouseActiveTab: history.location?.state?.warehouseActiveTab,
          },
        })
      } catch (err) {
        console.log(err)
        notification.error({ message: 'Cập nhật thất bại' })
      }
    }
  }

  const onUpdateBill = (card_id, bill) => {
    var cards = [...data]
    var index = data.findIndex((c) => c.id == card_id)
    if (index >= 0) {
      var _index = cards[index].orders.findIndex(
        (o) => o.code_bill == bill.code_bill
      )
      if (_index >= 0) cards[index].orders[_index] = bill
    }

    setData(cards)
  }

  const onDeleteBill = (card_id, bill) => {
    var cards = [...data]
    var index = cards.findIndex((b) => b.id == card_id)
    if (index >= 0) {
      var orders = [...cards[index].orders]
      orders = orders.filter((b) => b.code_bill != bill.code_bill)
      cards[index].orders = orders
    }
    setData([...cards])
  }

  const onLoadCardDetail = async () => {
    try {
      if (params.id) {
        setLoading(true)
        var response = await getCardByType({
          type: 'export',
          id: params.id,
        })
        if (
          response &&
          response.status == 200 &&
          response.data.data?.length > 0
        ) {
          if (response.data.data[0].is_activate) setDisableUpdate(true)
          setData(response.data.data)
        }
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
      notification.error({
        message: 'Có lỗi xảy ra khi tải phiếu, vui lòng thử lại',
      })
    }
  }

  useEffect(() => {
    onLoadCardDetail()
  }, [])
  return (
    <>
      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Spin />
        </div>
      )}
      {!loading && (
        <div
          style={{
            padding: '1em',
            backgroundColor: '#fff',
          }}>
          <Row
            align="middle"
            style={{
              fontSize: 18,
              padding: '0.75em 0',
              fontWeight: 600,
              borderBottom: '1px solid',
            }}>
            <Col>
              <Button
                type={'link'}
                onClick={(e) => {
                  history.push(ROUTES.WAREHOUSES)
                }}>
                <ArrowLeftOutlined style={{ marginRight: 8, color: '#000' }} />
              </Button>
            </Col>
            <Col>Cập nhật phiếu xuất kho</Col>
          </Row>
          <Tabs onChange={(e) => setACtiveTabs(e)}>
            {data.map((e, index) => (
              <Tabs.TabPane tab={e.code} key={index}>
                <WarehouseUpdateExportForm
                  formkey={index}
                  data={e}
                  onUpdateBill={onUpdateBill}
                  onDeleteBill={onDeleteBill}
                  allData={data}
                  setData={setData}
                />
              </Tabs.TabPane>
            ))}
          </Tabs>
          <Row justify="end" style={{ marginTop: '1rem 0' }}>
            <Button
              type="primary"
              size="large"
              disabled={disableUpdate}
              style={{ borderRadius: 5, marginRight: 20 }}
              onClick={() => setShowConfirm({ visible: true, type: 0 })}>
              {params?.id ? 'Cập nhật lưu nháp' : 'Lưu'}
            </Button>
            <Button
              type="primary"
              size="large"
              disabled={disableUpdate}
              style={{ minWidth: 120, borderRadius: 5 }}
              onClick={() => setShowConfirm({ visible: true, type: 1 })}>
              {params?.id ? 'Xuất Phiếu' : 'Lưu'}
            </Button>
          </Row>
          <Modal
            okText="Đồng ý"
            cancelText="Hủy"
            title={`Xác nhận ${params?.id ? 'cập nhật' : 'tạo'} phiếu`}
            visible={showConfirm.visible}
            onCancel={() => setShowConfirm({ visible: false, type: true })}
            onOk={() => {
              setShowConfirm({ visible: false, type: true })
              onUpdate(showConfirm.type)
            }}>
            <h2>
              Xác nhận
              {params?.id ? ' cập nhật ' : ' tạo '}
              phiếu xuất kho
            </h2>
          </Modal>
        </div>
      )}
    </>
  )
}
