import React, { useState } from 'react'
//antd
import { Col, notification, Row, Tree } from 'antd'
//consts
import { ACTION } from 'consts'
import { PERMISSIONS } from 'consts/permissions'
//apis
import { updateRole } from 'apis/role'

import treeData from './tree-data'

export default function PermissionForm({ role, _getRoles }) {
  // const [rolePermision, setRolePermision] = useState(role.permissions)
  const [checkedKeys, setCheckedKeys] = useState(role.permissions)

  const list = [...treeData]
    .filter((data) => {
      if (role.permissions.includes(data.key)) return true
      return false
    })
    .map((data) => {
      if (data.children)
        return {
          ...data,
          children: data.children.filter((res) =>
            role.permissions.includes(res.key)
          ),
        }
      return data
    })
  const half = Math.ceil(list.length / 2)
  const firstHalf = list.splice(0, half)
  const secondHalf = list.splice(-half)

  const _handleSubmit = async (checkedKeys) => {
    const body = { ...role, permissions: checkedKeys }
    let res
    try {
      res = await updateRole(body, role.id)

      if (res.status === 200) {
        // setRolePermision(rolePermision.filter((e) => e !== checkedKeys))
        _getRoles()
        notification.success({
          key: 'notice-update',
          message: 'Cập nhật vai trò thành công!',
          duration: 1,
        })
      }
    } catch (error) {
      notification.error({
        message: 'Cập nhật vai trò thất bại!',
      })
    }
  }
  return (
    <Row>
      <Col span={12}>
        <Tree
          // checkable
          treeData={firstHalf}
          checkedKeys={checkedKeys}
          checkStrictly
          defaultExpandAll
          selectable={false}
          // onCheck={(value, e) => {
          //   // if (!updatePermision) return false
          //   if (!e.checked && checkedKeys.length === 1) {
          //     notification.warning({
          //       message: 'Một vai trò phải có ít nhất một quyền!',
          //     })
          //     return false
          //   }
          //   setCheckedKeys(value.checked)
          //   _handleSubmit(value.checked)
          // }}
        />
      </Col>
      <Col span={12}>
        <Tree
          // checkable
          treeData={secondHalf}
          checkedKeys={checkedKeys}
          checkStrictly
          defaultExpandAll
          selectable={false}
          // onCheck={(value, e) => {
          //   //if (!updatePermision) return false
          //   if (!e.checked && checkedKeys.length === 1) {
          //     notification.warning({
          //       message: 'Một vai trò phải có ít nhất một quyền!',
          //     })
          //     return false
          //   }
          //   setCheckedKeys(value.checked)
          //   _handleSubmit(value.checked)
          // }}
        />
      </Col>
    </Row>
  )
}
