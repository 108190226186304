import React, { useEffect, useState, useContext, useRef } from 'react'
import { useHistory, Link } from 'react-router-dom'
import locale from 'antd/es/date-picker/locale/vi_VN'

//component antd
import {
  Table,
  Space,
  Row,
  Typography,
  Button,
  Modal,
  Checkbox,
  Col,
  Input,
  Select,
  DatePicker,
  Upload,
  notification,
  Popconfirm,
  Drawer,
  Form,
} from 'antd'
import {
  DownloadOutlined,
  DeleteOutlined,
  UploadOutlined,
  SearchOutlined,
  EditOutlined,
  LinkOutlined,
} from '@ant-design/icons'
//api
import { getShipping } from 'apis/shipping'
import {
  getOrder,
  deleteOrder,
  createMultiOrder,
  exportAll,
  exportByFilter,
  exportCurrentPage,
  getAllOrder,
  updateOrder,
  updateOrderBulk,
} from 'apis/order'

import XLSX from 'xlsx'
//utils
import useGetData from 'utils/hooks/useGetData'
import Context from 'utils/Context'
import { PERMISSIONS } from '../../consts/permissions'
import { translate } from '../../utils/i18n'
import { ACTION, ROUTES } from 'consts'
import { getTimeRange, formatNumber } from 'utils'

//component
import Permission from '../../components/Permission'

import shipmentsColumns from './shipmentsColumns'
import ReactDragListView from 'react-drag-listview'
import SettingColumns from 'components/setting-column'
import exportToCSV from 'components/ExcelModal/exportData'
import { orderConvertFields } from 'components/ExcelModal/fieldConvert'
//lodash
import _ from 'lodash'
import moment from 'moment-timezone'

import styles from './shipments.module.scss'
const { Option } = Select
const { RangePicker } = DatePicker
function removeNull(a) {
  return Object.keys(a)
    .filter((key) => a[key] !== '' && a[key] !== undefined)
    .reduce((res, key) => ((res[key] = a[key]), res), {})
}
export default () => {
  const typingTimeoutRef = useRef(null)
  const context = useContext(Context)
  const abordController = new AbortController()
  const [shipments, reloadShipments, abordShipments] = useGetData(getOrder, {
    signal: abordController.signal,
  })
  const OPTIONS_SEARCH = ['customer_code', 'code_bill', 'tracking_number']
  const [dataShipment, setDataShipment] = useState([])
  const [shipmentDefault, setShipmentDefault] = useState(null)
  const [showImport, setShowImport] = useState(false)
  const [importData, setImportData] = useState([])
  const [importLoading, setImportLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [partnerList, setPartnerList] = useState([])
  const [errors, setErrors] = useState([])
  const [visibleError, setVisibleError] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [maxRecord, setMaxRecord] = useState(0)
  const [columns, setColumns] = useState(shipmentsColumns)
  const history = useHistory()
  const filterCurrent =
    localStorage.itineraryFilters && JSON.parse(localStorage.itineraryFilters)
  const optionSearch =
    filterCurrent &&
    Object.keys(filterCurrent).find((key) => OPTIONS_SEARCH.includes(key))
  const [valueSearch, setValueSearch] = useState(
    optionSearch ? filterCurrent[optionSearch] : ''
  )
  const [searchKey, setSearchKey] = useState(optionSearch || 'customer_code')
  const [filter, setFilter] = useState(
    filterCurrent
      ? filterCurrent
      : {
          page: 1,
          pageSize: 300,
        }
  )
  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const [importParner, setImportPartner] = useState(undefined)
  const [selectedDate, setSeletedDate] = useState(
    !localStorage.selectedDate || localStorage.selectedDate == 'undefined'
      ? undefined
      : localStorage.selectedDate
  )

  const searchRef = useRef()
  const toggleOpenSelect = () => setIsOpenSelect(!isOpenSelect)
  const changeRange = (date, dateString) => {
    setFilter({
      ...filter,
      startDate: dateString[0],
      endDate: dateString[1],
    })
    setSeletedDate(`${dateString[0]} -> ${dateString[1]}`)
    toggleOpenSelect()
  }
  const changeTimeOption = (value) => {
    let tmp = getTimeRange(value)
    setFilter({
      ...filter,
      startDate: tmp.from_date,
      endDate: tmp.to_date,
    })
    setSeletedDate(value)
  }
  const _effectShipments = () => {
    reloadShipments()
    return abordShipments
  }

  const onChangeOptionSearch = (value) => {
    setSearchKey(value)
    delete filter[optionSearch]
    localStorage.orderSearchKey = value
    if (valueSearch) setFilter({ ...filter, [value]: valueSearch, page: 1 })
  }

  const onExportCurrentPage = async () => {
    try {
      setLoading(true)
      const res = await exportCurrentPage({
        page: filter.page,
        pageSize: filter.pageSize,
      })
      setLoading(false)
      if (res.data && res.data.success) {
        window.open(res.data.data)
      } else notification.error({ message: 'Export không thành công' })
    } catch (error) {
      setLoading(false)
      console.log(error)
      notification.error({
        message: 'Có lỗi xảy ra tong quá trình xử lý dữ liệu',
      })
    }
  }
  const onExportByFilter = async () => {
    try {
      setLoading(true)
      const res = await exportByFilter({
        ...filter,
      })
      setLoading(false)
      if (res.data && res.data.success) {
        window.open(res.data.data)
      } else notification.error({ message: 'Export không thành công' })
    } catch (error) {
      setLoading(false)
      console.log(error)
      notification.error({
        message: 'Có lỗi xảy ra tong quá trình xử lý dữ liệu',
      })
    }
  }

  const onExportAllData = async () => {
    try {
      setLoading(true)
      const res = await exportAll({
        ...filter,
      })
      setLoading(false)
      if (res.data && res.data.success) {
        window.open(res.data.data)
      } else notification.error({ message: 'Export không thành công' })
    } catch (error) {
      setLoading(false)
      console.log(error)
      notification.error({
        message: 'Có lỗi xảy ra tong quá trình xử lý dữ liệu',
      })
    }
  }

  const onSearch = (e) => {
    const value = e.target.value
    setValueSearch(value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (value) filter[searchKey] = value
      else delete filter[searchKey]
      setFilter({ ...filter, page: 1 })
    }, 500)
  }

  const ModalUpdateOrder = ({ children, order = {} }) => {
    const trackings = order.trackings ? JSON.parse(order.trackings) : []

    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [form] = Form.useForm()

    const handleUpdate = async () => {
      try {
        setLoading(true)

        const dataForm = form.getFieldsValue()

        const trackingList = trackings.map((tracking) => {
          const trackingObject = Object.keys(dataForm).find(
            (key) => key === tracking.title
          )
          if (trackingObject)
            return {
              ...tracking,
              updated_time: dataForm[tracking.title]
                ? moment(dataForm[tracking.title]).format('YYYY-MM-DD HH:mm')
                : '',
            }
          return tracking
        })

        const body = { ...order, trackings: JSON.stringify(trackingList) }
        const res = await updateOrder(order.id, body)
        setLoading(false)
        if (res.status === 200) {
          if (res.data.success) {
            _getAllOrder(removeNull(filter))
            notification.success({ message: 'Cập nhât đơn hàng thành công' })
            toggle()
          } else
            notification.error({ message: res.data.mess || res.data.message })
        } else
          notification.error({ message: res.data.mess || res.data.message })
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }

    useEffect(() => {
      if (visible)
        trackings.map((tracking) => {
          if (tracking.updated_time)
            form.setFieldsValue({
              [tracking.title]: moment(tracking.updated_time),
            })
        })
    }, [visible])

    return (
      <>
        <div onClick={toggle}>{children}</div>
        <Drawer
          footer={
            <Row justify="end">
              <Button onClick={handleUpdate} loading={loading} type="primary">
                Cập nhật
              </Button>
            </Row>
          }
          width={400}
          onClose={toggle}
          title="Cập nhật đơn hàng"
          visible={visible}>
          <Form onFinish={handleUpdate} form={form} layout="vertical">
            {trackings.map((tracking) => (
              <Form.Item label={tracking.title} name={`${tracking.title}`}>
                <DatePicker
                  locale={locale}
                  showTime
                  format={'YYYY-MM-DD HH:mm'}
                  placeholder="Chọn ngày"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            ))}
          </Form>
        </Drawer>
      </>
    )
  }

  const ModalUpdateOrders = ({ children }) => {
    const trackings = [
      { status: 1, name: 'Cập nhật thời gian đã nhập kho' },
      { status: 2, name: 'Cập nhật thời gian đã xếp xe thông quan' },
      { status: 3, name: 'Cập nhật thời gian đã đến hải quan Việt Nam' },
      { status: 4, name: 'Cập nhật thời gian đã đến kho Hà Nội' },
      { status: 5, name: 'Cập nhật thời gian hàng hoá đã ký nhận' },
    ]
    const [dateType, setDateType] = useState()
    const [value, setValue] = useState()
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [form] = Form.useForm()

    const handleUpdate = async () => {
      try {
        setLoading(true)

        if (!value) {
          notification.error({ message: 'Vui lòng chọn thời gian' })
          return
        }

        const body = {
          order_ids: selectedRowKeys,
          status: dateType.status,
          updated_time: moment(value).format('YYYY-MM-DD HH:mm'),
        }

        const res = await updateOrderBulk(body)
        setLoading(false)
        if (res.status === 200) {
          if (res.data.success) {
            _getAllOrder(removeNull(filter))
            setSelectedRowKeys([])
            notification.success({ message: 'Cập nhât đơn hàng thành công' })
            toggle()
          } else
            notification.error({ message: res.data.mess || res.data.message })
        } else
          notification.error({ message: res.data.mess || res.data.message })
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }

    useEffect(() => {
      if (visible) {
        form.resetFields()
        setDateType()
        setValue()
      }
    }, [visible])

    return (
      <>
        <div onClick={toggle}>{children}</div>
        <Modal
          footer={
            <Row justify="end">
              <Button onClick={handleUpdate} loading={loading} type="primary">
                Cập nhật
              </Button>
            </Row>
          }
          width={400}
          onCancel={toggle}
          title="Cập nhật đơn hàng"
          visible={visible}>
          <Form onFinish={handleUpdate} form={form} layout="vertical">
            <div>Chọn thời gian để cập nhật</div>
            <Select
              onChange={(value) => {
                const tracking = trackings.find((item) => item.name === value)
                if (tracking) setDateType(tracking)
              }}
              placeholder="Chọn thời gian cập nhật"
              style={{ width: '100%' }}>
              {trackings.map((tracking) => (
                <Select.Option value={tracking.name}>
                  {tracking.name}
                </Select.Option>
              ))}
            </Select>
            <br />
            <br />
            {dateType && (
              <div>
                <div>{dateType.name}</div>
                <DatePicker
                  locale={locale}
                  value={value}
                  onChange={setValue}
                  showTime
                  format={'YYYY-MM-DD HH:mm'}
                  placeholder="Chọn ngày"
                  style={{ width: '100%' }}
                />
              </div>
            )}
          </Form>
        </Modal>
      </>
    )
  }

  const tableColumnRender = {
    0: {
      align: 'center',
      render: (text, record, index) => (
        <span style={{ fontSize: '10pt' }}>
          {(filter.page - 1) * filter.pageSize + index + 1}
        </span>
      ),
    },
    5: {
      align: 'center',
      render(data) {
        return data && moment(data).format('DD/MM/YYYY')
      },
    },
    6: {
      align: 'center',
      render(data, record) {
        return (
          record.trackings && (
            <Permission permissions={[PERMISSIONS.cap_nhat_hanh_trinh]}>
              <ModalUpdateOrder order={record}>
                <Button type="primary">Cập nhật</Button>
              </ModalUpdateOrder>
            </Permission>
          )
        )
      },
    },
  }

  const _getAllOrder = async (params) => {
    try {
      setLoading(true)
      const res = await getAllOrder({ ...params, page: 1, pageSize: 10000 })
      if (res.data && res.status === 200 && res.data.success) {
        setDataShipment(res.data.data)
        setMaxRecord(res.data.count)
      } else {
        notification.error({
          message: 'Có lỗi xảy ra, vui lòng thử lại',
        })
      }
      setLoading(false)
    } catch (e) {
      notification.error({
        message: 'Hệ thống đang khởi động, vui lòng thử lại',
      })
      setLoading(false)
      console.log(e)
    }
  }

  const settings = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      authorization: 'authorization-text',
    },
    maxCount: 1,
    onChange(info) {
      if (info.file.status === 'uploading') {
        setImportLoading(true)
        const reader = new FileReader()
        reader.onload = async (e) => {
          const bstr = e.target.result
          const workBook = XLSX.read(bstr, {
            type: 'binary',
            cellDates: true,
          })
          const workSheetname = workBook.SheetNames[0]
          const workSheet = workBook.Sheets[workSheetname]

          const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 0 })

          const fileDataConverted = fileData
            .map((e) => {
              const newData = orderConvertFields(e)
              if (newData) {
                return {
                  ...newData,
                  customer_code: newData.customer_code + '',
                  date_sign: newData.date_sign,

                  cod: newData.cod || 0,
                  fee_bag: newData.fee_bag || 0,
                  fee_package: newData.fee_package || 0,
                  sub_cost: newData.sub_cost || 0,
                }
              }
            })
            .filter((e) => e)

          setImportData(fileDataConverted)
        }

        reader.readAsBinaryString(info.file.originFileObj)
      }
      setImportLoading(false)
    },
  }

  const onImport = async (data) => {
    try {
      setImportLoading(true)
      const res = await createMultiOrder({
        data: data.map((e) => {
          return {
            ...e,
            shipping_partner_name: importParner,
            order_status: 'StackCar',
            // status_check: 'Checking'
          }
        }),
      })
      if (res.data.success) {
        setImportLoading(false)
        reloadShipments()
        _getAllOrder(removeNull(filter))
        setShowImport(false)
        setImportData([])
        notification.success({
          message: 'Import đơn hàng thành công',
        })
      } else {
        setImportLoading(false)
        setVisibleError(true)
        setErrors(res.data.errors)
        notification.error({
          message:
            res.data.errors?.length > 0 ? res.data.errors[0] : res.data.mess,
        })
      }
    } catch (err) {
      setImportLoading(false)
      notification.error({
        message: 'Tạo đơn hàng thất bại',
      })
    }
  }

  const onExport = async () => {
    try {
      const res = await getOrder({ export: true })
      console.log(res)
      if (res.status === 200) {
        exportToCSV(res.data.data, orderConvertFields, 'Đơn hàng')
      } else {
        notification.error({
          message: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu',
        })
      }
    } catch (err) {
      console.log(err)
      notification.error({
        message: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu!',
      })
    }
  }
  const deleteOrders = async () => {
    try {
      const res = await deleteOrder({ data: selectedRowKeys })

      if (res.data.success) {
        notification.success({
          message: res.data.mess || 'Xóa hóa đơn thành công',
        })
        setSelectedRowKeys([])
        _getAllOrder(removeNull(filter))
      } else {
        notification.error({
          message: res.data.mess || 'Xóa hóa đơn thất bạ!',
        })
      }
    } catch (err) {
      console.log(err)
      notification.error({ message: 'Thất bại' })
    }
  }
  const ImportButton = () => (
    <Upload {...settings}>
      <Button>Chọn File Nhập</Button>
    </Upload>
  )
  const getPartner = async () => {
    try {
      const res = await getShipping({ area: 'International' })
      if (res.data.success) {
        setPartnerList(res.data.data)
        setImportPartner(
          (res.data.data[0] && res.data.data[0].name) || undefined
        )
      }
    } catch (e) {
      console.log(e)
    }
  }

  const resetFilter = () => {
    let tmp = {
      page: 1,
      pageSize: filter.pageSize,
    }
    setFilter(tmp)
    setSeletedDate(undefined)
    localStorage.orderSearchKey = 'customer_code'
    localStorage.selectedDate = ''
    searchRef.current.value = undefined
  }

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const tmpColumns = [...columns]
      const item = tmpColumns.splice(fromIndex - 1, 1)[0]
      console.log('from', fromIndex, 'to', toIndex)
      tmpColumns.splice(toIndex - 1, 0, item)
      localStorage.setItem('itineraryColumns', JSON.stringify(tmpColumns))
      setColumns(tmpColumns)
    },
    nodeSelector: 'th',
  }

  const passStateUpdate = async () => {
    let data = dataShipment.filter((e) => selectedRowKeys.includes(e.id))
    const partner = await getShipping()
    const partnerObj = partner.data.data?.reduce(
      (a, b) => ((a[b.id + ''] = b.name), a),
      {}
    )
    let data2 = data.map((e) => {
      return { ...e, shipping_partner_name: partnerObj[e.shipping_partner_id] }
    })
    history.push({ pathname: ROUTES.ORDERS_UPDATE, state: data2 })
  }

  useEffect(() => {
    if (!localStorage.getItem('itineraryColumns')) {
      localStorage.setItem('itineraryColumns', JSON.stringify(columns))
    }

    getPartner()
  }, [])

  useEffect(_effectShipments, [])
  // useEffect(() => {
  //   // context.dispatch({ name: ACTION.LOADING, data: shipments.loading });

  //   delete shipments.loading;

  //   //parse object to array
  //   const dataShipments = Object.keys(shipments).map((e) => shipments[e]);

  //   //sort date
  //   const newDataShipments = _.sortBy(dataShipments, (shipment) => {
  //     return new Date(shipment.created_at);
  //   });

  //   setDataShipment(newDataShipments[2]);
  //   console.log(shipments);
  // }, [shipments]);
  useEffect(() => {
    _getAllOrder(removeNull(filter))
    searchRef.current.value =
      filter.customer_code || filter.code_bill || filter.tracking_number
    localStorage.itineraryFilters = JSON.stringify(filter)
  }, [filter])
  useEffect(() => {
    localStorage.selectedDate = selectedDate
  }, [selectedDate])
  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
      }}>
      <Modal
        onCancel={(e) => {
          setVisibleError(false)
        }}
        onOk={(e) => {
          setVisibleError(false)
        }}
        visible={visibleError}>
        {errors && (
          <div>
            {errors.map((error) => {
              return <Row>{error}</Row>
            })}
          </div>
        )}
      </Modal>

      <Row justify="space-between" align="middle">
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          Hành trình
        </Typography.Title>
      </Row>

      <Row gutter={20}>
        <Col span={9}>
          <Row>Tìm kiếm</Row>
          <Row>
            <Input.Group compact>
              <Input
                style={{ width: '60%' }}
                prefix={<SearchOutlined style={{ color: '#bdc3c7' }} />}
                // size="large"
                // value={
                //   filter.customer_code ||
                //   filter.code_bill ||
                //   filter.tracking_number
                // }
                value={valueSearch}
                ref={searchRef}
                placeholder="Tìm kiếm theo..."
                allowClear
                onChange={(e) => onSearch(e)}
              />
              <Select
                style={{ width: '40%' }}
                value={searchKey}
                onChange={onChangeOptionSearch}>
                <Select.Option value="customer_code">
                  Mã khách hàng
                </Select.Option>
                <Select.Option value="code_bill">Mã đơn hàng</Select.Option>
                <Select.Option value="tracking_number">
                  Mã vận đơn
                </Select.Option>
              </Select>
            </Input.Group>
          </Row>
        </Col>
        <Col span={6}>
          <Row>Lọc theo ngày ký nhận</Row>
          <Row>
            <Select
              open={isOpenSelect}
              onBlur={() => {
                if (isOpenSelect) toggleOpenSelect()
              }}
              onClick={() => {
                if (!isOpenSelect) toggleOpenSelect()
              }}
              style={{ width: 380 }}
              placeholder="Chọn thời gian"
              allowClear
              onClear={(e) => {
                var _filter = { ...filter }
                delete _filter.startDate
                delete _filter.endDate
                setFilter({ ..._filter })
              }}
              value={selectedDate}
              onChange={async (value) => {
                if (isOpenSelect) toggleOpenSelect()
                changeTimeOption(value)
              }}
              dropdownRender={(menu) => (
                <div>
                  <RangePicker
                    placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                    onFocus={() => {
                      if (!isOpenSelect) toggleOpenSelect()
                    }}
                    onBlur={() => {
                      if (isOpenSelect) toggleOpenSelect()
                    }}
                    style={{ width: '100%' }}
                    showTime
                    format="YYYY-MM-DD"
                    onOpenChange={() => {
                      setTimeout(() => {
                        let timePicker = document.querySelector(
                          '.ant-picker-panel-container .ant-picker-time-panel'
                        )
                        if (timePicker) timePicker.style = 'display:none'
                      }, 500)
                    }}
                    onChange={changeRange}
                  />
                  {menu}
                </div>
              )}>
              <Option value="to_day">{translate('Today')}</Option>
              <Option value="yesterday">{translate('Yesterday')}</Option>
              <Option value="this_week">{translate('This week')}</Option>
              <Option value="last_week">{translate('Last week')}</Option>
              <Option value="last_month">{translate('Last month')}</Option>
              <Option value="this_month">{translate('This month')}</Option>
              <Option value="this_year">{translate('This year')}</Option>
              <Option value="last_year">{translate('Last year')}</Option>
            </Select>
          </Row>
        </Col>
        <Col span={4}>
          <Row>{translate('Filter by status')}</Row>
          <Row>
            <Select
              defaultValue=""
              style={{ width: '100%' }}
              value={filter.order_status}
              onClear={(e) => {
                var _filter = { ...filter }
                delete _filter.order_status
                setFilter({ ..._filter })
              }}
              onChange={(e) =>
                setFilter({ ...filter, order_status: e, page: 1 })
              }>
              <Select.Option value="">Tất cả</Select.Option>
              <Select.Option value="StackCar">Xếp xe</Select.Option>
              <Select.Option value="CameBack">Đã về kho</Select.Option>
              <Select.Option value="Delivery">Đã nhận</Select.Option>
              <Select.Option value="CANCEL">Đã huỷ</Select.Option>{' '}
            </Select>
          </Row>
        </Col>
        <Col span={5}>
          <Row>Vận chuyển quốc tế</Row>
          <Row>
            <Select
              defaultValue=""
              style={{ width: '100%' }}
              value={filter.shipping_partner_code}
              onClear={(e) => {
                var _filter = { ...filter }
                delete _filter.shipping_partner_code
                setFilter({ ..._filter })
              }}
              onChange={(e) =>
                setFilter({ ...filter, shipping_partner_code: e, page: 1 })
              }>
              <Select.Option value="">Tất cả</Select.Option>
              {partnerList.map((e) => (
                <Select.Option value={e.shipping_partner_code}>
                  {e.name}
                </Select.Option>
              ))}
            </Select>
          </Row>
        </Col>
      </Row>
      {selectedRowKeys.length ? (
        <Permission permissions={[PERMISSIONS.cap_nhat_hanh_trinh]}>
          <ModalUpdateOrders>
            <Button type="primary">Cập nhật hàng loạt</Button>
          </ModalUpdateOrders>
        </Permission>
      ) : (
        ''
      )}
      <div style={{ background: 'white' }} className={styles['orders']}>
        <ReactDragListView.DragColumn {...dragProps} ignoreSelector>
          <Table
            rowKey="id"
            rowSelection={{
              selectedRowKeys: selectedRowKeys,
              onChange: (keys) => setSelectedRowKeys(keys),
            }}
            loading={loading}
            size="small"
            pagination={{
              pageSize: filter.pageSize,
              pageSizeOptions: [100, 200, 300, 400],
              total: maxRecord,
              current: filter.page,
              onChange: (page, pageSize) =>
                setFilter({ ...filter, page, pageSize }),
            }}
            columns={columns.map((e) => {
              if (e.key == 'stt')
                return {
                  ...e,
                  render: (text, record, index) => index + 1,
                  width: 50,
                }

              if (e.key == 'view_detail')
                return {
                  ...e,
                  render: (text, record) => (
                    <a
                      target={'_blank'}
                      href={
                        ROUTES.ORDER_TRACKING + '?order_id=' + record.code_bill
                      }>
                      {'Xem'}
                    </a>
                  ),
                }

              if (tableColumnRender[e.key])
                return { ...e, ...tableColumnRender[e.key] }

              return e
            })}
            dataSource={dataShipment}
            scroll={{ x: 'max-content', y: '60vh' }}
            style={{ width: '100%' }}
          />
        </ReactDragListView.DragColumn>

        <Modal
          title={
            <a
              target="_blank"
              href="https://s3.ap-northeast-1.wasabisys.com/botx/2023_06_08_db81c621-965b-45e9-8411-e0e9bd4b60dd_import_order_template.xlsx">
              <Button type="link" icon={<LinkOutlined />}>
                Tải xuống mẫu file
              </Button>
            </a>
          }
          visible={showImport}
          onCancel={() => {
            setShowImport(false)
            setImportData([])
          }}
          width={1000}
          centered
          footer={[
            <Button
              key="back"
              onClick={() => {
                setShowImport(false)
                setImportData([])
              }}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => onImport(importData)}>
              Ok
            </Button>,
          ]}>
          <Row style={{ marginBottom: 15 }} gutter={20}>
            <Col>
              <h4>Chọn file nhập</h4>
              <ImportButton />
            </Col>
            <Col>
              <h4>Chọn vận chuyển quốc tế</h4>
              <Select
                style={{ width: 200, marginRight: 8 }}
                value={importParner}
                onChange={(e) => setImportPartner(e)}>
                {partnerList.map((e) => (
                  <Option value={e.name}>{e.name}</Option>
                ))}
              </Select>
            </Col>
          </Row>

          <Table
            columns={[1, 2, 3, 4, 5, 6, 7, 8, 10, 21, 11, 20, 14].map((e) => {
              let col = shipmentsColumns.find((c) => c.key == e) || {}
              if (e == 1)
                return {
                  ...col,
                  ...tableColumnRender[e],
                  render(data) {
                    return data && moment(data).format('DD/MM/YYYY HH:mm:ss')
                  },
                }
              return { ...col, ...tableColumnRender[e] }
            })}
            pagination={{
              pageSize: 300,
            }}
            size="small"
            loading={importLoading}
            dataSource={importData}
            scroll={{ x: 'max-content' }}
          />
        </Modal>
      </div>
    </Space>
  )
}
