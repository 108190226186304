import React, { useEffect, useState } from 'react'
//antd
import {
  Row,
  Button,
  Input,
  Collapse,
  Popconfirm,
  notification,
  Col,
  Space,
} from 'antd'
//styles
import styles from './role-management.module.scss'

//components
import CreateRole from './create-role'
import RoleTree from './role-tree'
import Permission from 'components/Permission'

//apis
import { getRoles, deleteRole } from 'apis/role'

//consts
import { ACTION, FIXEDROLES } from 'consts'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { PERMISSIONS } from 'consts/permissions'

export default function RoleManagement({ type }) {
  const [dataRoles, setDataRoles] = useState([])
  const [search, setSearch] = useState('')

  const _getRoles = async () => {
    try {
      const res = await getRoles()
      const data = res.data.data.filter((role) => role.id) // Không hiển thị admin
      if (res.status === 200) setDataRoles(data)
    } catch (error) {
      console.log(error)
    }
  }

  const _deleteRole = async (roleId) => {
    try {
      const res = await deleteRole(roleId)
      if (res.status === 200) {
        notification.success({ message: 'Xóa vai trò thành công!' })
        _getRoles()
      } else {
        notification.error({
          message: res.data.message || 'Xóa vai trò thất bại',
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getRoles()
  }, [])

  return (
    <div className={styles[type === 'child' ? '' : 'role']}>
      <Row style={{ paddingBottom: '15px' }}>
        <Col style={{ width: '100%' }} xs={24} sm={24} md={24} lg={12} xl={12}>
          <span style={{ marginBottom: 0, fontSize: 16, fontWeight: 700 }}>
            {'Vai trò'}
          </span>
        </Col>
        <Col style={{ width: '100%' }} xs={24} sm={24} md={24} lg={12} xl={12}>
          <Row justify="end">
            <Space>
              <CreateRole _getRoles={_getRoles}>
                <Button type="primary">Tạo vai trò</Button>
              </CreateRole>
            </Space>
          </Row>
        </Col>
      </Row>
      {/* <Input.Search
        value={search}
        allowClear
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Tìm kiếm theo tên vai trò hoặc tên quyền"
        enterButton
        style={{ width: 400 }}
      /> */}
      <div style={{ marginTop: 15, maxHeight: '69vh', overflowY: 'auto' }}>
        <Collapse accordion>
          {dataRoles.map((role, index) => (
            <Collapse.Panel
              extra={
                <Row wrap={false} onClick={(e) => e.stopPropagation()}>
                  <CreateRole role={role} _getRoles={_getRoles}>
                    <Button
                      disabled={role.id == 1}
                      style={{
                        marginRight: 5,
                        backgroundColor: 'green',
                        borderColor: 'green',
                      }}
                      type="primary"
                      icon={<EditOutlined />}
                    />
                  </CreateRole>
                  {/* <Permission permissions={[PERMISSIONS.xoa_quyen]}> */}
                  <Popconfirm
                    disabled={role.id == 1}
                    placement="topRight"
                    title="Bạn có muốn xóa vai trò này không?"
                    cancelText="Từ chối"
                    okText="Đồng ý"
                    onCancel={(e) => {
                      e.stopPropagation()
                    }}
                    onConfirm={(e) => {
                      e.stopPropagation()
                      _deleteRole(role.id)
                    }}>
                    <Button
                      disabled={role.id == 1}
                      icon={<DeleteOutlined />}
                      type="primary"
                      danger
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Popconfirm>
                  {/* </Permission> */}
                </Row>
              }
              header={
                <div
                  style={{
                    textTransform: 'uppercase',
                    display: 'inline-block',
                  }}>
                  {search &&
                  role.name.toLowerCase().includes(search.toLowerCase()) ? (
                    <mark style={{ backgroundColor: '#00D3E0' }}>
                      {role.name}
                    </mark>
                  ) : (
                    role.name
                  )}
                </div>
              }
              key={index}>
              <RoleTree search={search} role={role} _getRoles={_getRoles} />
            </Collapse.Panel>
          ))}
        </Collapse>
      </div>
    </div>
  )
}
