import React, { useState, useEffect, useRef } from 'react'
import { translate } from 'utils/i18n'
import { formatNumber } from 'utils'
import moment from 'moment-timezone'
import locale from 'antd/es/date-picker/locale/vi_VN'

//antd
import { Row, Typography, Input, Select, Table, DatePicker } from 'antd'

//icons
import { SearchOutlined } from '@ant-design/icons'

//apis
import { getOrdersInventory } from 'apis/order'

export default function Inventory() {
  const typingTimeoutRef = useRef(null)

  const [orders, setOrders] = useState([])
  const [countOrder, setCountOrder] = useState(0)
  const [loading, setLoading] = useState(false)

  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    pageSize: 20,
    this_week: true,
  })

  const [valueDateSearch, setValueDateSearch] = useState(null) //dùng để hiện thị date trong filter by date
  const [valueDateTimeSearch, setValueDateTimeSearch] = useState({
    this_week: true,
  })
  const [optionsSearch, setOptionsSearch] = useState('search')
  const [valueTime, setValueTime] = useState('this_week') //dùng để hiện thị value trong filter by time
  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const toggleOpenSelect = () => setIsOpenSelect(!isOpenSelect)

  const _onSearch = (e) => {
    const value = e.target.value
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (value) paramsFilter.search = value
      else delete paramsFilter.search

      setParamsFilter({ ...paramsFilter })
    }, 550)
  }

  function onChange(dates, dateStrings) {
    //khi search hoac filter thi reset page ve 1
    paramsFilter.page = 1

    if (isOpenSelect) toggleOpenSelect()

    //nếu search date thì xoá các params date
    delete paramsFilter.to_day
    delete paramsFilter.yesterday
    delete paramsFilter.this_week
    delete paramsFilter.last_week
    delete paramsFilter.last_month
    delete paramsFilter.this_month
    delete paramsFilter.this_year
    delete paramsFilter.last_year

    //Kiểm tra xem date có được chọn ko
    //Nếu ko thì thoát khỏi hàm, tránh cash app
    //và get danh sách order
    if (!dateStrings[0] && !dateStrings[1]) {
      delete paramsFilter.startDate
      delete paramsFilter.endDate

      setValueDateSearch(null)
      setValueTime()
    } else {
      const dateFirst = dateStrings[0]
      const dateLast = dateStrings[1]
      setValueDateSearch(dates)
      setValueTime(`${dateFirst} -> ${dateLast}`)

      dateFirst.replace(/-/g, '/')
      dateLast.replace(/-/g, '/')
      paramsFilter.startDate = dateFirst
      paramsFilter.endDate = dateLast
    }

    setParamsFilter({ ...paramsFilter })
  }

  const columns = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Mã Bill',
      dataIndex: 'code_bill',
    },
    {
      title: 'Mã vận đơn',
      render: (data) => data.tracking_number || '',
    },
    {
      title: 'Mã khách hàng',
      render: (data) => data.customer_code || '',
    },
    {
      title: 'Số lượng tồn',
      dataIndex: 'amount_package_imported',
      render: (text) => text && formatNumber(text),
    },
    {
      title: 'Trọng lượng',
      dataIndex: 'mass',
      render: (text) => text && formatNumber(+text),
    },
    {
      title: 'Khối lượng',
      dataIndex: 'volume',
    },

    {
      title: 'Ngày nhập kho',
      dataIndex: 'created_at',
      render(data) {
        return moment(data).format('DD/MM/YYYY')
      },
    },
    {
      title: 'Lần cuối cập nhật',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (text) => text && moment(text).format('YYYY/MM/DD HH:mm'),
    },
  ]

  const _getOrderByStatus = async (params) => {
    try {
      setLoading(true)
      const res = await getOrdersInventory(params)
      console.log(res)
      if (res.status === 200) {
        setCountOrder(res.data.count)
        setOrders(res.data.data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    _getOrderByStatus(paramsFilter)
  }, [paramsFilter])

  return (
    <div>
      <Typography.Title level={4}>Tồn kho</Typography.Title>
      <Row>
        <div>
          <div>Tìm kiếm theo mã khách hàng</div>
          <Input
            allowClear
            style={{ width: 350, marginRight: 15 }}
            prefix={<SearchOutlined />}
            placeholder="Nhập mã khách hàng"
            onChange={_onSearch}
          />
        </div>

        <div>
          <div>Lọc theo thời gian</div>
          <Select
            open={isOpenSelect}
            onBlur={() => {
              if (isOpenSelect) toggleOpenSelect()
            }}
            onClick={() => {
              if (!isOpenSelect) toggleOpenSelect()
            }}
            style={{ width: 300 }}
            placeholder="Choose time"
            allowClear
            value={valueTime}
            onChange={async (value) => {
              setValueTime(value)

              //khi search hoac filter thi reset page ve 1
              paramsFilter.page = 1

              //xoa params search date hien tai
              const p = Object.keys(valueDateTimeSearch)
              if (p.length) delete paramsFilter[p[0]]

              setValueDateSearch(null)
              delete paramsFilter.startDate
              delete paramsFilter.endDate

              if (isOpenSelect) toggleOpenSelect()

              if (value) {
                const searchDate = Object.fromEntries([[value, true]]) // them params search date moi

                setParamsFilter({ ...paramsFilter, ...searchDate })
                setValueDateTimeSearch({ ...searchDate })
              } else {
                setParamsFilter({ ...paramsFilter })
                setValueDateTimeSearch({})
              }
            }}
            dropdownRender={(menu) => (
              <div>
                <DatePicker.RangePicker
                  locale={locale}
                  dropdownClassName="dropdown-datepicker-custom"
                  className="datepicker-custom"
                  onFocus={() => {
                    if (!isOpenSelect) toggleOpenSelect()
                  }}
                  onBlur={() => {
                    if (isOpenSelect) toggleOpenSelect()
                  }}
                  value={valueDateSearch}
                  onChange={onChange}
                  style={{ width: '100%' }}
                />
                {menu}
              </div>
            )}>
            <Select.Option value="to_day">{translate('Today')}</Select.Option>
            <Select.Option value="yesterday">
              {translate('Yesterday')}
            </Select.Option>
            <Select.Option value="this_week">
              {translate('This week')}
            </Select.Option>
            <Select.Option value="last_week">
              {translate('Last week')}
            </Select.Option>
            <Select.Option value="last_month">
              {translate('Last month')}
            </Select.Option>
            <Select.Option value="this_month">
              {translate('This month')}
            </Select.Option>
            <Select.Option value="this_year">
              {translate('This year')}
            </Select.Option>
            <Select.Option value="last_year">
              {translate('Last year')}
            </Select.Option>
          </Select>
        </div>
      </Row>
      <div style={{ marginTop: 30 }}>
        <Table
          loading={loading}
          size="small"
          style={{ width: '100%' }}
          scroll={{ x: 'max-content', y: '60vh' }}
          columns={columns}
          dataSource={orders}
          summary={(data) => (
            <Table.Summary.Row style={{ fontWeight: 600 }}>
              <Table.Summary.Cell>Tổng</Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell>
                {data.length &&
                  data.reduce(
                    (total, current) => total + current.amount_package_imported,
                    0
                  )}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {data.length &&
                  data.reduce((total, current) => total + current.mass, 0)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {data.length &&
                  data.reduce((total, current) => total + current.volume, 0)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
          pagination={{
            current: paramsFilter.page,
            pageSize: paramsFilter.pageSize,
            pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
            showQuickJumper: true,
            onChange: (page, pageSize) => {
              paramsFilter.page = page
              paramsFilter.pageSize = pageSize

              setParamsFilter({ ...paramsFilter })
            },
            total: countOrder,
          }}
        />
      </div>
    </div>
  )
}
