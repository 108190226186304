import React, { useEffect, useState } from 'react'
import { Row, Col, Tabs, Button, notification, Modal, Spin } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { ROUTES } from 'consts'
import { updateCard, getCardByType } from 'apis/card'
import WarehouseUpdateImportForm from 'components/WarehouseForm/UpdateImport'
export default function UpdateImportWarehouse() {
  const history = useHistory()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [activeTabs, setActiveTabs] = useState(0)
  const [disableUpdate, setDisableUpdate] = useState(false)
  const [showConfirm, setShowConfirm] = useState({
    visible: false,
    type: true,
  })
  const location = useLocation()
  const params = useParams()
  // const cardId = new URLSearchParams(location.search).get('card_id')
  // const type = new URLSearchParams(location.search).get('type')

  const onUpdate = async (is_activate) => {
    if (is_activate) {
      let pass = true
      const body = data.map((e) => {
        if (!e.cost_import && e.cost_import != 0) {
          notification.warning({ message: 'Vui lòng nhập cước nhập kho' })
          pass = false
          return
        }
        if (!e.id_shipping) {
          notification.warning({ message: 'Vui lòng chọn đơn vị vận chuyển' })
          pass = false
          return
        }
        if (!e.orders.length) {
          notification.warning({ message: 'Vui lòng thêm đơn hàng' })
          pass = false
          return
        }
        return {
          id: e.id,
          is_activate,
          type: 'import',
          cost_import: e.cost_import,
          created_at: e.created_at,
          shipping_partner_name: e.shipping_partner && e.shipping_partner.name,
          link_image: e.link_image,
          data: e.orders.map((o) => {
            return {
              code_bill: o.code_bill,
              num:
                o.amount_package_imported > 0 ? o.amount_package_imported : 0,
            }
          }),
        }
      })

      try {
        if (pass) {
          const listRes = await Promise.all(
            body.map((e) => {
              let tmp = { ...e }
              let idtmp = e.id
              delete tmp.id

              return updateCard(idtmp, tmp)
            })
          )

          for (let i = 0; i < listRes.length; i++) {
            if (listRes[i].status === 200) {
              if (listRes[i].data.success)
                notification.success({ message: 'Cập nhật thành công' })
              else {
                notification.error({
                  message: 'Thất bại',
                  description:
                    listRes[i].data.mess ||
                    listRes[i].data.message ||
                    `Cập nhật phiếu thất bại`,
                })
                return
              }
            } else {
              notification.error({
                message: 'Thất bại',
                description:
                  listRes[i].data.mess ||
                  listRes[i].data.message ||
                  `Cập nhật phiếu thất bại`,
              })
              return
            }
          }

          history.push({
            pathname: ROUTES.WAREHOUSES,
            state: {
              warehouseActiveTab: history.location?.state?.warehouseActiveTab,
            },
          })
        }
      } catch (err) {
        console.log(err)
        notification.error({ message: 'Cập nhật thất bại' })
      }
    } else {
      const body = data.map((e) => {
        return {
          id: e.id,
          is_activate,
          type: 'import',
          cost_import: e.cost_import,
          created_at: e.created_at,
          shipping_partner_name: e.shipping_partner && e.shipping_partner.name,
          link_image: e.link_image,
          data: e.orders.map((o) => {
            return {
              code_bill: o.code_bill,
              num: o.amount_package_import > 0 ? o.amount_package_import : 0,
            }
          }),
        }
      })
      try {
        const listRes = await Promise.all(
          body.map((e) => {
            let tmp = { ...e }
            let idtmp = e.id
            delete tmp.id

            return updateCard(idtmp, tmp)
          })
        )

        for (let i = 0; i < listRes.length; i++) {
          if (listRes[i].status === 200) {
            if (listRes[i].data.success)
              notification.success({ message: 'Cập nhật thành công' })
            else {
              notification.error({
                message: 'Thất bại',
                description:
                  listRes[i].data.mess ||
                  listRes[i].data.message ||
                  `Cập nhật phiếu thất bại`,
              })
              return
            }
          } else {
            notification.error({
              message: 'Thất bại',
              description:
                listRes[i].data.mess ||
                listRes[i].data.message ||
                `Cập nhật phiếu thất bại`,
            })
            return
          }
        }

        history.push({
          pathname: ROUTES.WAREHOUSES,
          state: {
            warehouseActiveTab: history.location?.state?.warehouseActiveTab,
          },
        })
      } catch (err) {
        console.log(err)
        notification.error({ message: 'Cập nhật thất bại' })
      }
    }
  }
  const onLoadCardDetail = async () => {
    try {
      if (params.id) {
        setLoading(true)
        var response = await getCardByType({
          type: 'import',
          id: params.id,
        })
        if (
          response &&
          response.status == 200 &&
          response.data.data?.length > 0
        ) {
          if (response.data.data[0].is_activate) setDisableUpdate(true)
          setData(response.data.data)
        }
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
      notification.error({
        message: 'Có lỗi xảy ra khi tải phiếu, vui lòng thử lại',
      })
    }
  }
  useEffect(() => {
    onLoadCardDetail()
  }, [])
  return (
    <>
      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Spin size={30} />
        </div>
      )}
      {!loading && (
        <div
          style={{
            padding: '1em',
            backgroundColor: '#fff',
          }}>
          <Row
            align="middle"
            style={{
              fontSize: 18,
              padding: '0.75em 0',
              fontWeight: 600,
              borderBottom: '1px solid',
            }}>
            <Col>
              <Button
                type={'link'}
                onClick={(e) => {
                  history.push(ROUTES.WAREHOUSES)
                }}>
                <ArrowLeftOutlined style={{ marginRight: 8, color: '#000' }} />
              </Button>
            </Col>
            <Col>Cập nhật phiếu nhập kho</Col>
          </Row>
          <Tabs onChange={(e) => setActiveTabs(e)}>
            {data.length > 0 &&
              data.map((e, index) => {
                try {
                  if (e.orders) e.orders = JSON.parse(e.orders)
                } catch (e) {}

                var card = {
                  ...e,
                  orders: e.orders,
                  is_activate: e.is_activate,
                }
                return (
                  <Tabs.TabPane tab={e.code} key={index}>
                    <WarehouseUpdateImportForm
                      data={card}
                      setData={setData}
                      formkey={index}
                      allData={data}
                    />
                  </Tabs.TabPane>
                )
              })}
          </Tabs>
          <Row justify="end" style={{ marginTop: '1rem 0' }}>
            <Button
              type="primary"
              size="large"
              disabled={disableUpdate}
              style={{ borderRadius: 5, marginRight: 20 }}
              onClick={() => setShowConfirm({ visible: true, type: false })}>
              {params?.id ? 'Cập nhật lưu nháp' : 'Lưu Nháp'}
            </Button>
            <Button
              type="primary"
              size="large"
              disabled={disableUpdate}
              style={{ borderRadius: 5 }}
              onClick={() => setShowConfirm({ visible: true, type: true })}>
              {params?.id ? 'Kích hoạt phiếu' : 'Lưu'}
            </Button>
          </Row>
          <Modal
            okText="Đồng ý"
            cancelText="Hủy"
            title={`Xác nhận ${params?.id ? 'cập nhật' : 'tạo'} phiếu`}
            visible={showConfirm.visible}
            onCancel={() => setShowConfirm({ visible: false, type: true })}
            onOk={() => onUpdate(showConfirm.type)}>
            <h2>
              Xác nhận
              {params?.id ? ' cập nhật ' : ' tạo '}
              phiếu nhập kho
            </h2>
          </Modal>
        </div>
      )}
    </>
  )
}
