import React, { useState } from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Row, Col, Form, Button, notification, Modal } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import { ROUTES } from 'consts'
import WarehouseImportForm from 'components/WarehouseForm/CreateImport'
import { createCardIn, importCardIn } from 'apis/card'
export default function CreateWarehouseImport() {
  const [ImportData, setImportData] = useState({
    data: [],
    type: 'import',
    cost_import: 0,
  })
  const [showConfirm, setShowConfirm] = useState({
    visible: false,
    type: true,
  })
  const history = useHistory()
  const onCreateCardIn = async (is_activate) => {
    if (is_activate) {
      if (ImportData.data.reduce((a, b) => a && b.num >= 0, true)) {
        if (!ImportData.cost_import && ImportData.cost_import != 0) {
          notification.warning({ message: 'Vui lòng nhập cước nhập kho' })
          return
        }
        if (!ImportData.shipping_partner_name) {
          notification.warning({ message: 'Vui lòng chọn đơn vị vận chuyển' })
          return
        }
        if (!ImportData.data.length) {
          notification.warning({ message: 'Vui lòng thêm đơn hàng' })
          return
        }
        try {
          const body = {
            ...ImportData,
            is_activate,
            data: ImportData.data.map((e) => {
              return { code_bill: e.code_bill, num: e.num > 0 ? e.num : 0 }
            }),
          }

          //validated cost
          // if (body.cost_import % 1000 !== 0) {
          //   notification.warning({
          //     message: 'Vui lòng nhập cước phí kho hợp lệ',
          //   })
          //   setShowConfirm({ ...showConfirm, visible: false })
          //   return
          // }

          const res = await importCardIn({ data: [body] })
          if (res.status === 200 && res.data.success) {
            notification.success({ message: 'Thành công' })
            history.push({
              pathname: ROUTES.WAREHOUSES,
              state: {
                warehouseActiveTab: history.location?.state?.warehouseActiveTab,
              },
            })
          } else {
            notification.error({
              message: 'Thất bại',
              description: res.data ? res.data.mess : '',
            })
          }
        } catch (err) {
          console.log(err)
          notification.error({
            message: 'Thất bại',
            description: err.data ? err.data.mess : '',
          })
        }
      } else {
        let tmp = ImportData.data.find((e) => e.num === -1)
        notification.warning({
          message: 'Thất bại',
          description: 'Vui lòng nhập đầy đủ số lượng kiện',
        })
      }
    } else {
      try {
        const body = {
          ...ImportData,
          is_activate,
          data: ImportData.data.map((e) => {
            return { code_bill: e.code_bill, num: e.num > 0 ? e.num : 0 }
          }),
        }

        //validated cost
        // if (body.cost_import % 1000 !== 0) {
        //   notification.warning({
        //     message: 'Vui lòng nhập cước phí kho hợp lệ',
        //   });
        //   setShowConfirm({ ...showConfirm, visible: false });
        //   return;
        // }

        const res = await importCardIn({ data: [body] })
        if (res.status === 200 && res.data.success) {
          notification.success({ message: 'Lưu phiếu nhập Thành công' })
          history.push({
            pathname: ROUTES.WAREHOUSES,
            state: {
              warehouseActiveTab: history.location?.state?.warehouseActiveTab,
            },
          })
        } else {
          notification.error({
            message: 'Lưu phiếu nhập thất bại',
            description: res.data ? res.data.mess : '',
          })
        }
      } catch (err) {
        console.log(err)
        notification.error({
          message: 'Thất bại',
          description: err.data ? err.data.mess : '',
        })
      }
    }
  }
  return (
    <div
      style={{
        padding: '1em',
        backgroundColor: '#fff',
      }}>
      <Row
        align="middle"
        style={{
          fontSize: 18,
          padding: '0.75em 0',
          fontWeight: 600,
          borderBottom: '1px solid',
        }}>
        <Col>
          <Link to={ROUTES.WAREHOUSES}>
            <ArrowLeftOutlined style={{ marginRight: 8, color: '#000' }} />
          </Link>
        </Col>
        <Col>Tạo phiếu nhập kho</Col>
      </Row>
      <WarehouseImportForm data={ImportData} setData={setImportData} />
      <Row justify="end" gutter={20}>
        <Col>
          <Button
            type="primary"
            disabled={!ImportData.data.length}
            style={{
              width: 120,
              borderRadius: 5,
              backgroundColor: '#bdc3c7',
              border: 'none',
            }}
            size="large"
            onClick={() => setShowConfirm({ visible: true, type: false })}>
            Lưu nháp
          </Button>
        </Col>
        <Col>
          <Button
            size="large"
            type="primary"
            disabled={!ImportData.data.length}
            style={{ width: 120, borderRadius: 5 }}
            onClick={() => setShowConfirm({ visible: true, type: true })}>
            Nhập kho
          </Button>
        </Col>
      </Row>
      <Modal
        okText="Đồng ý"
        cancelText="Hủy"
        title={`Xác nhận ${showConfirm.type ? 'tạo' : 'lưu'} phiếu`}
        visible={showConfirm.visible}
        onCancel={() => setShowConfirm({ visible: false, type: true })}
        onOk={() => onCreateCardIn(showConfirm.type)}>
        <h2>Xác nhận {showConfirm.type ? 'tạo' : 'lưu'} phiếu nhập kho</h2>
      </Modal>
    </div>
  )
}
