export const PERMISSIONS = {
  tong_quan: 'tong_quan',
  tra_cuu_don: 'tra_cuu_don',
  xem_don_hang: 'don_hang',
  xem_don_gia_don_hang: 'xem_don_gia_don_hang',
  xem_tong_cuoc_don_hang: 'xem_tong_cuoc_don_hang',
  xem_gia_von_don_hang: 'xem_gia_von_don_hang',
  loc_van_chuyen_quoc_te_don_hang: 'loc_van_chuyen_quoc_te_don_hang',
  xuat_toan_bo_don_hang: 'xuat_toan_bo_don_hang',
  xuat_trang_hien_tai_don_hang: 'xuat_trang_hien_tai_don_hang',
  xuat_theo_bo_loc_don_hang: 'xuat_theo_bo_loc_don_hang',
  tao_don_hang: 'tao_don_hang',
  tao_don_hang_noi_bo: 'tao_don_hang_noi_bo',
  cap_nhat_don_hang: 'cap_nhat_don_hang',
  in_phieu_don_hang: 'in_phieu_don_hang',
  huy_don_hang: 'huy_don_hang',
  xoa_don_hang: 'xoa_don_hang',
  xem_phieu_nhap: 'xem_phieu_nhap',
  xem_gia_von_phieu_nhap: 'xem_gia_von_phieu_nhap',
  tao_phieu_nhap: 'tao_phieu_nhap',
  cap_nhat_phieu_nhap: 'cap_nhat_phieu_nhap',
  huy_phieu_nhap: 'huy_phieu_nhap',
  xem_phieu_xuat: 'xem_phieu_xuat',
  xem_tong_cuoc_phieu_xuat: 'xem_tong_cuoc_phieu_xuat',
  tao_phieu_xuat: 'tao_phieu_xuat',
  cap_nhat_phieu_xuat: 'cap_nhat_phieu_xuat',
  huy_phieu_xuat: 'huy_phieu_xuat',
  xem_ton_kho: 'xem_ton_kho',
  xem_cong_no: 'xem_cong_no',
  hanh_trinh: 'hanh_trinh',
  cap_nhat_hanh_trinh: 'cap_nhat_hanh_trinh',
  danh_sach_kho: 'danh_sach_kho',
  tao_kho: 'tao_kho',
  cap_nhat_kho: 'cap_nhat_kho',
  cap_nhat_cong_no: 'cap_nhat_cong_no',
  xuat_file_cong_no: 'xuat_file_cong_no',
  xem_cong_thuc_tinh_gia: 'xem_cong_thuc_tinh_gia',
  tao_cong_thuc_tinh_gia: 'tao_cong_thuc_tinh_gia',
  cap_nhat_cong_thuc_tinh_gia: 'cap_nhat_cong_thuc_tinh_gia',
  xoa_cong_thuc_tinh_gia: 'xoa_cong_thuc_tinh_gia',
  xem_khach_hang: 'xem_khach_hang',
  tao_khach_hang: 'tao_khach_hang',
  cap_nhat_khach_hang: 'cap_nhat_khach_hang',
  xoa_khach_hang: 'xoa_khach_hang',
  van_chuyen: 'van_chuyen',
  tao_van_chuyen: 'tao_van_chuyen',
  cap_nhat_van_chuyen: 'cap_nhat_van_chuyen',
  xoa_van_chuyen: 'xoa_van_chuyen',
  nhan_vien: 'nhan_vien',
  tao_nhan_vien: 'tao_nhan_vien',
  cap_nhat_nhan_vien: 'cap_nhat_nhan_vien',
  xoa_nhan_vien: 'xoa_nhan_vien',
  vai_tro: 'vai_tro',
}

export const PERMISSIONS_ID = {
  CREATE_EDIT_SUBADMIN: 1,
  CREATE_EDIT_USER: 2,
  VIEW_ALL_USERS: 3,
  VIEW_ALL_COMPANIES: 4,
  VIEW_ALL_WAREHOUSE: 5,
  VIEW_ALL_SHIPMENTS: 6,
  CREATE_EDIT_ROLE: 7,
  ADD_PERMISSION_ROLE: 8,
  VIEW_SHIPMENT_OWNER: 9,
  CREATE_EDIT_SHIPMENT: 10,
  CREATE_EDIT_PKG: 11,
  SUBMIT_SHIPMENT: 12,
  APPROVAL_SHIPMENT: 13,
  UPDATE_SHIPMENT_WAREHOUSE: 14,
  DELETE_PKG_IN_SHIPMENT: 15,
  VIEW_COD_CLIENT: 16,
  VIEW_EFFORT_PRICE: 17,
  CREATE_EDIT_EFFORT_PRICE: 18,
  VIEW_ALL_BAGS: 19,
  CREATE_EDIT_BAG: 20,
  VIEW_CUSTOMER_ONLY: 21,
  CREATE_EDIT_CUSTOMER: 22,
  VIEW_SHIPMENT_WAREHOUSE: 23,
  UPDATE_RW_PKG: 24,
  PREPARE_SHIPPING_PKG: 25,
  VIEW_SHIPPER_WAREHOUSE: 26,
  SEND_PKG_CUSMTOMER: 27,
  UPDATE_REASON_RETURN_PKG: 28,
  UPDATE_REAL_CODE: 29,
  VIEW_INVENTORY_WAREHOUSE: 30,
  VIEW_SUCCESS_PKG: 31,
  RESOLVE_PKG: 32,
  VIEW_SET_PRICE: 33,
}
