import React from 'react'

import { translate } from 'utils/i18n'
import moment from 'moment-timezone'

//components antd
import { formatNumber } from 'utils'

export default [
  {
    title: 'STT',
    key: 0,
    width: 50,
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: 'Mã Bill',
    dataIndex: 'code_bill',
    key: 1,
  },
  {
    title: 'Mã vận đơn',
    dataIndex: 'tracking_number',
  },
  {
    title: 'Mã khách hàng',
    dataIndex: 'customer_code',
    key: 1,
    // render(data) {
    //   return data && data.customer_code;
    // },
  },
  {
    title: 'Số lượng nhập',
    key: 'amount_package_imported',
    width: 120,
  },
  {
    title: 'Số lượng tồn',
    key: 'available',
    dataIndex: 'available',
    width: 120,
  },
  {
    title: 'Số lượng xuất',
    key: 'amount_package_exported',
    dataIndex: 'amount_package_exported',
    width: 120,
  },
  {
    title: 'Trọng lượng',
    dataIndex: 'mass',
    key: 1,
    render(data) {
      return <>{formatNumber(data)} kg</>
    },
  },
  {
    title: 'Khối lượng',
    dataIndex: 'volume',
    key: 1,
    render(data) {
      return (
        <>
          {formatNumber(data)} m<sup>3</sup>
        </>
      )
    },
  },
  {
    title: 'Ngày nhập kho',
    dataIndex: 'created_at',
    key: 1,
    render(data) {
      return moment(data).format('DD/MM/YYYY')
    },
  },
]
