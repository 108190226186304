import { PERMISSIONS } from 'consts/permissions'
const data = [
  {
    title: 'Tra cứu đơn',
    key: PERMISSIONS.tra_cuu_don,
  },
  {
    title: 'Tổng quan',
    key: PERMISSIONS.tong_quan,
  },
  {
    title: 'Đơn hàng',
    key: PERMISSIONS.xem_don_hang,
    children: [
      {
        title: 'Tạo đơn hàng',
        key: PERMISSIONS.tao_don_hang,
      },
      {
        title: 'Tạo đơn hàng nội bộ',
        key: PERMISSIONS.tao_don_hang_noi_bo,
      },
      {
        title: 'Cập nhật đơn hàng',
        key: PERMISSIONS.cap_nhat_don_hang,
      },
      {
        title: 'In kiện hàng',
        key: PERMISSIONS.in_phieu_don_hang,
      },
      {
        title: 'Huỷ đơn hàng',
        key: PERMISSIONS.huy_don_hang,
      },
      {
        title: 'Xóa đơn hàng',
        key: PERMISSIONS.xoa_don_hang,
      },
      {
        title: 'Xem đơn giá',
        key: PERMISSIONS.xem_don_gia_don_hang,
      },
      {
        title: 'Xem tổng cước',
        key: PERMISSIONS.xem_tong_cuoc_don_hang,
      },
      {
        title: 'Xem giá vốn',
        key: PERMISSIONS.xem_gia_von_don_hang,
      },
      {
        title: 'Lọc theo vận chuyển quốc tế',
        key: PERMISSIONS.loc_van_chuyen_quoc_te_don_hang,
      },
      {
        title: 'Xuất toàn bộ đơn hàng',
        key: PERMISSIONS.xuat_toan_bo_don_hang,
      },
      {
        title: 'Xuất đơn hàng theo trang hiện tại',
        key: PERMISSIONS.xuat_trang_hien_tai_don_hang,
      },
      {
        title: 'Xuất đơn hàng theo bộ lọc',
        key: PERMISSIONS.xuat_theo_bo_loc_don_hang,
      },
    ],
  },
  {
    title: 'Hành trình',
    key: PERMISSIONS.hanh_trinh,
    children: [
      {
        title: 'Cập nhật hành trình',
        key: PERMISSIONS.cap_nhat_hanh_trinh,
      },
    ],
  },
  {
    title: 'Phiếu nhập kho',
    key: PERMISSIONS.xem_phieu_nhap,
    children: [
      {
        title: 'Tạo phiếu nhập kho',
        key: PERMISSIONS.tao_phieu_nhap,
      },
      {
        title: 'Cập nhật phiếu nhập kho',
        key: PERMISSIONS.cap_nhat_phieu_nhap,
      },
      {
        title: 'Huỷ phiếu nhập kho',
        key: PERMISSIONS.huy_phieu_nhap,
      },
      {
        title: 'Xem giá vốn',
        key: PERMISSIONS.xem_gia_von_phieu_nhap,
      },
    ],
  },
  {
    title: 'Xem tồn kho',
    key: PERMISSIONS.xem_ton_kho,
  },
  {
    title: 'Xem phiếu xuất kho',
    key: PERMISSIONS.xem_phieu_xuat,
    children: [
      {
        title: 'Tạo phiếu xuất kho',
        key: PERMISSIONS.tao_phieu_xuat,
      },
      {
        title: 'Cập nhật phiếu xuất kho',
        key: PERMISSIONS.cap_nhat_phieu_xuat,
      },
      {
        title: 'Huỷ phiếu xuất kho',
        key: PERMISSIONS.huy_phieu_xuat,
      },
      {
        title: 'Xem tổng cước',
        key: PERMISSIONS.xem_tong_cuoc_phieu_xuat,
      },
    ],
  },
  {
    title: 'Danh sách kho',
    key: PERMISSIONS.danh_sach_kho,
    children: [
      {
        title: 'Tạo kho',
        key: PERMISSIONS.tao_kho,
      },
      {
        title: 'Cập nhật kho',
        key: PERMISSIONS.cap_nhat_kho,
      },
    ],
  },
  {
    title: 'Công nợ',
    key: PERMISSIONS.xem_cong_no,
    children: [
      {
        title: 'Cập nhật công nợ',
        key: PERMISSIONS.cap_nhat_cong_no,
      },
      {
        title: 'Xuất File công nợ',
        key: PERMISSIONS.xuat_file_cong_no,
      },
    ],
  },
  {
    title: 'Công thức',
    key: PERMISSIONS.xem_cong_thuc_tinh_gia,
    children: [
      {
        title: 'Tạo công thức',
        key: PERMISSIONS.tao_cong_thuc_tinh_gia,
      },
      {
        title: 'Cập nhật công thức',
        key: PERMISSIONS.cap_nhat_cong_thuc_tinh_gia,
      },
      {
        title: 'Xoá công thức',
        key: PERMISSIONS.xoa_cong_thuc_tinh_gia,
      },
    ],
  },
  {
    title: 'Khách hàng',
    key: PERMISSIONS.xem_khach_hang,
    children: [
      {
        title: 'Tạo khách hàng',
        key: PERMISSIONS.tao_khach_hang,
      },
      {
        title: 'Cập nhật khách hàng',
        key: PERMISSIONS.cap_nhat_khach_hang,
      },
      {
        title: 'Xoá khách hàng',
        key: PERMISSIONS.xoa_khach_hang,
      },
    ],
  },
  {
    title: 'Vận chuyển',
    key: PERMISSIONS.van_chuyen,
    children: [
      {
        title: 'Tạo vận chuyển',
        key: PERMISSIONS.tao_van_chuyen,
      },
      {
        title: 'Cập nhật vận chuyển',
        key: PERMISSIONS.cap_nhat_van_chuyen,
      },
      {
        title: 'Xoá vận chuyển',
        key: PERMISSIONS.xoa_van_chuyen,
      },
    ],
  },
  {
    title: 'Nhân viên',
    key: PERMISSIONS.nhan_vien,
    children: [
      {
        title: 'Tạo nhân viên',
        key: PERMISSIONS.tao_nhan_vien,
      },
      {
        title: 'Cập nhật nhân viên',
        key: PERMISSIONS.cap_nhat_nhan_vien,
      },
      {
        title: 'Xoá nhân viên',
        key: PERMISSIONS.xoa_nhan_vien,
      },
    ],
  },
  {
    title: 'Quản lí vai trò',
    key: PERMISSIONS.vai_tro,
  },
]

export default data
