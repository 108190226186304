import React from 'react'

import moment from 'moment-timezone'
import { translate } from 'utils/i18n'
import { formatNumber } from 'utils'
import ModalListOrder from './modalListOrder'
// moment().tz('Asia/Ho_Chi_Minh');
export default [
  {
    title: translate('Card code'),
    dataIndex: 'code',
    key: 'code_io',
  },
  {
    title: 'Trạng thái',
    key: 'status',
  },
  {
    title: 'Tổng SL đã xuất',
    dataIndex: 'amount_export_package',
    width: 50,
    key: 'amount_export_package',
    // render(data) {
    //   return (
    //     data && data.reduce((a, b) => a + (b.amount_package_imported || 0), 0)
    //   );
    // },
  },
  {
    title: translate('List Order'),
    key: 'list_order',
    // render: (data) => data || 0,
    render(text, record) {
      return (
        record &&
        record.orders && <ModalListOrder type={'export'} data={record.orders} />
      )
    },
  },
  {
    title: 'Ngày xuất kho',
    key: 'created_at',
    render(text, record) {
      return (
        record &&
        moment(String(record.created_at).replace(/\.000Z$/, '')).format(
          'DD/MM/YYYY HH:mm:ss'
        )
      )
    },
  },
  // {
  //   title: 'Lần cuối cập nhật',
  //   dataIndex: 'updated_at',
  //   key: 'updated_at',
  // },
  {
    title: 'Vận chuyển',
    dataIndex: 'shipping_partner',
    key: 'shipping_partner',
    render(data) {
      return data && data.name
    },
  },
  {
    title: 'Cước xuất kho',
    dataIndex: 'cost_export',
    key: 'cost',
    render(data) {
      return formatNumber(data) + ' VND'
    },
  },
  {
    title: 'Lưu nháp',
    key: 'is_activate',
  },
  {
    title: 'Phụ phí',
    dataIndex: 'sub_cost',
    key: 'sub_cost',
    render(data) {
      return formatNumber(data) + ' VND'
    },
  },
  {
    title: 'COD',
    dataIndex: 'cod',
    key: 'cod',
    render(data) {
      return formatNumber(data) + ' VND'
    },
  },
  {
    title: 'Ghi chú',
    dataIndex: 'note',
    key: 'note',
    width: 150,
  },
  // {
  //   title: 'Trạng thái',
  //   key: 'is_activate',
  //   render(data, record) {
  //     return record.is_activate ? 'Đã xuất' : 'Lưu nháp'
  //   },
  // },
  { key: 'action' },
]
