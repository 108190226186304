import React, { useEffect, useState } from 'react'

import { Form, Drawer, Input, Row, Button, notification } from 'antd'

import { createWarehouse, updateWarehouse } from 'apis/warehouses'

export default function WarehouseForm({ children, record, reload }) {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState()
  const toggle = () => setVisible(!visible)
  const [loading, setLoading] = useState(false)

  const onCreateOrUpdateWarehouse = async () => {
    try {
      setLoading(true)
      const body = form.getFieldsValue()
      let res
      if (!record) res = await createWarehouse(body)
      else res = await updateWarehouse(record.id, body)
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          if (reload) reload()
          notification.success({
            message: `${record ? 'Cập nhật' : 'Thêm mới'} kho thành côgn`,
          })
          toggle()
        } else
          notification.error({ message: res.data.mess || res.data.message })
      } else notification.error({ message: res.data.mess || res.data.message })
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (record)
      form.setFieldsValue({
        code: record.code,
        name: record.name,
        address: record.address,
        tel: record.tel,
        email: record.email,
      })
  }, [visible])

  return (
    <>
      <div onClick={toggle}>{children}</div>
      <Drawer
        footer={
          <Row justify="end">
            <Button style={{ marginRight: 8 }} onClick={toggle}>
              Hủy
            </Button>
            <Button
              loading={loading}
              onClick={onCreateOrUpdateWarehouse}
              type="primary">
              Thêm mới
            </Button>
          </Row>
        }
        width={500}
        onClose={toggle}
        visible={visible}
        title={`${record ? 'Cập nhật' : 'Thêm mới'} kho`}>
        <Form layout="vertical" form={form}>
          <Form.Item
            name="code"
            label="Mã kho"
            required={[{ require: true, message: 'Vui lòng nhập mã kho' }]}>
            <Input placeholder="Nhập mã kho" />
          </Form.Item>
          <Form.Item
            name="name"
            label="Tên kho"
            required={[{ require: true, message: 'Vui lòng nhập tên kho' }]}>
            <Input placeholder="Nhập tên kho" />
          </Form.Item>
          <Form.Item initialValue="" name="email" label="Email">
            <Input placeholder="Nhập email" />
          </Form.Item>
          {/* <Form.Item initialValue="" name="tel" label="Liên hệ">
            <Input placeholder="Nhập liên hệ" />
          </Form.Item> */}
          <Form.Item initialValue="" name="phone" label="Số điện thoại">
            <Input placeholder="Nhập số điện thoại" />
          </Form.Item>
          <Form.Item initialValue="" name="address" label="Địa chỉ">
            <Input placeholder="Nhập địa chỉ" />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}
