export default [
  {
    title: 'Mã bill',
    dataIndex: 'code_bill',
    key: 'code_bill',
  },
  {
    title: 'Mã khách hàng',
    dataIndex: 'customer_code',
    key: 'customer_code',
  },
  {
    title: 'SL Đã nhập kho',
    dataIndex: 'amount_package_imported',
    key: 'amount_package_imported',
  },
  // {
  //   title: 'SL Xuất kho',
  //   dataIndex: 'amount_package_exported',
  //   key: 'amount_package_exported',
  // },
  {
    title: 'Tổng SL kiện',
    dataIndex: 'total_quantity_package',
    key: 'total_quantity_package',
  },
]
