import { get, post, patch, destroy } from 'utils/Fetch'

export const genCodeOrder = () => get('/order/gen-code')
export const getOrder = (params) => get('/order/getallorder', params && params)
export const getOrderByDate = (params) =>
  get('/order/getorderbyalldates', params && params)
export const orderScan = (params) => get('/order/detail', params && params)
export const getOrderDetail = (params) => get('/order/detail', params)
export const createOrder = (data) => post('/order/create', data)
export const createMultiOrder = (data) => post('/order/createbulk', data)
export const updateOrder = (id, data) => patch('/order/update/' + id, data)
export const deleteOrder = (data) => destroy(`/order/delete`, data)
export const getOrdersInventory = (params) => get('/inventory', params)
export const exportCurrentPage = (query) => get('/order/export-page', query)
export const exportByFilter = (query) => get('/order/export-filter', query)
export const exportAll = (query) => get('/order/export-all', query)
export const getAllOrder = (query) => get('/order/getallorder', query)
export const updateOrderBulk = (body) =>
  post('/order/update-tracking-bulk', body)
export const cancelOrder = (body) => post('/order/cancel', body)
export const sendNotifyImport = (body) => post('/order/notify-import', body)
export const sendNotifyDebit = (body) => post('/order/notify-debit', body)
