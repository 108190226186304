import React, { useState, useEffect, useContext } from 'react'
import delay from 'delay'
import styles from 'views/Order/shipments.module.scss'

//component antd
import {
  Space,
  Form,
  Row,
  Button,
  notification,
  Typography,
  Col,
  Checkbox,
} from 'antd'

//icons
import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons'

import { translate } from 'utils/i18n'
import { useHistory, useLocation } from 'react-router-dom'
import { ROUTES, INVOICE_TYPE_TO_PAYMENT_TERMS, ACTION } from 'consts'
import Context from 'utils/Context'
import moment from 'moment'
//component
import ShipmentForm from 'components/OrderForm'
import PrintOrder from 'components/PrintOrder'

//api
import { createOrder, getOrder, createMultiOrder } from 'apis/order'
import { getShipping } from 'apis/shipping'

export default () => {
  const history = useHistory()
  const context = useContext(Context)
  const [shipmentForm] = Form.useForm()

  const [shipmentDefault, setShipmentDefault] = useState(null)
  const [isPre, setIsPre] = useState(false)

  const getShipmentDefault = async () => {
    try {
      const res = await getOrder()

      if (res.status === 200)
        if (res.data && res.data.data.length && res.data.data?.length > 0) {
          let tmp = { ...res.data.data[0] }
          const partner = await getShipping({
            shipping_partner_code: tmp.shipping_partner_code,
          })
          setShipmentDefault({
            ...tmp,
            shipping_partner_name:
              partner.data?.data &&
              partner.data?.data[0] &&
              partner.data?.data[0]?.name,
          })
        } else {
          // notification.warning({ message: 'Có lỗi xảy ra, vui lòng thử lại' })
        }
    } catch (error) {
      notification.warning({ message: 'Có lỗi xảy ra, vui lòng thử lại' })
      console.log(error)
    }
  }

  const _onSubmitShipment = async () => {
    try {
      await shipmentForm.validateFields()

      const value = shipmentForm.getFieldsValue()
      const body = {
        ...value,
        // date_sign: moment(value.date_sign).format('YYYY-MM-DD HH:mm'),
        order_status: value.order_status || 'StackCar',
        sub_cost: value.sub_cost || 0,
      }

      context.dispatch({ name: ACTION.LOADING, data: true })
      const response = await createMultiOrder({ data: [body] })
      console.log(response)

      if (response.status === 200) {
        if (response.data && response.data.success) {
          notification.success({ message: 'Tạo đơn hàng thành công' })
          history.push(ROUTES.ORDERS)
        } else {
          // let mess =
          //   response.data.mess == 'Not found effort_price suitable'
          //     ? 'Không tìm thấy công thức tính giá'
          //     : 'Mã đơn hàng đã tồn tại';
          notification.error({
            message:
              response.data.errors?.length > 0
                ? response.data.errors[0]
                : response.data.mess,
          })
        }
      } else
        notification.error({
          message:
            response.data.errors?.length > 0
              ? response.data.errors[0]
              : response.data.mess,
        })

      context.dispatch({ name: ACTION.LOADING, data: false })
    } catch (error) {
      notification.error({
        message: 'Tạo đơn hàng thất bại',
      })
      context.dispatch({ name: ACTION.LOADING, data: false })
    }
  }

  const _onPrintBill = async () => {
    try {
    } catch (e) {
      notification.error({ message: 'Có lỗi xảy ra, vui lòng thử lại' })
    }
  }

  useEffect(() => {
    shipmentForm.setFieldsValue({
      date_sign: moment(new Date()),
    })
    getShipmentDefault()
  }, [])

  return (
    // <Space direction="vertical">
    <>
      <Row justify="space-between" align="middle">
        <Typography.Title level={4}>
          <a onClick={() => history.goBack()}>
            <ArrowLeftOutlined style={{ marginRight: '7px' }} />
          </a>
          {translate('Create Shipment')}
        </Typography.Title>
        <Checkbox
          checked={isPre}
          onChange={(e) => {
            setIsPre(e.target.checked)
            if (e.target.checked) {
              if (shipmentDefault)
                shipmentForm.setFieldsValue({
                  ...shipmentDefault,
                  date_sign: moment(shipmentDefault.date_sign).tz(
                    'Asia/Ho_Chi_Minh'
                  ),
                })
            } else shipmentForm.resetFields()
          }}>
          Điền sẵn từ đơn hàng gần nhất
        </Checkbox>
      </Row>
      <ShipmentForm form={shipmentForm} required={true} />
      <Row justify="end">
        <PrintOrder form={shipmentForm}>
          <button className={styles['btn-print-order']}>
            <PrinterOutlined /> In Bill
          </button>
        </PrintOrder>

        <button
          style={{ backgroundColor: 'red', marginLeft: 10 }}
          onClick={_onSubmitShipment}
          className={styles['btn-print-order']}>
          Tạo Bill
        </button>
      </Row>
    </>
    // </Space>
  )
}
