import React, { useState, useEffect, useContext } from 'react'

import debitNoteColumns from './debitNoteColumns'
import { ACTION, ROUTES } from 'consts'
import { PERMISSIONS } from 'consts/permissions'
import formatNumber from 'utils/formatNumber'
import Context from 'utils/Context'
import moment from 'moment-timezone'
import locale from 'antd/es/date-picker/locale/vi_VN'
//components antd
import {
  Typography,
  Col,
  Row,
  DatePicker,
  Select,
  Table,
  Button,
  Modal,
  Input,
  Form,
  Drawer,
  notification,
  InputNumber,
  Tabs,
  Popconfirm,
} from 'antd'

//components
import { translate } from 'utils/i18n'
import { getUsers, getUsersByRole } from 'apis/users'
import { sendNotifyDebit } from 'apis/order'
import { getTimeRange, removeNull } from 'utils'
import {
  getAllDebit,
  updateDebit,
  updateRecieve,
  exportAll,
  exportByFilter,
  exportCurrentPage,
} from 'apis/debit'
import { getShipping } from 'apis/shipping'
import { getOrderInInventory } from 'apis/card'
import { EditOutlined } from '@ant-design/icons'
import BalanceItem from 'views/Information/balance-item'
import { useHistory } from 'react-router-dom'
import shipmentsColumns from 'views/Order/shipmentsColumns'

import '../Warehouses/warehouse.scss'
import exportToCSV from 'components/ExcelModal/exportData'
import Permission from 'components/Permission'
const { Option } = Select
const { RangePicker } = DatePicker
export default () => {
  const [customerList, setCustomerList] = useState([])
  const [debitList, setDebitList] = useState([])
  const [filter, setFilter] = useState({ page: 1, pageSize: 10000 })
  const [filterSummary, setFilterSummary] = useState({ is_month: true })
  const [totalRecord, setTotalRecord] = useState(0)
  const [debitSum, setDebitSum] = useState({})
  const [isOpenSelectSummary, setIsOpenSelectSummary] = useState(false)
  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const [showDetail, setShowDetail] = useState(false)
  const [debitDetail, setDebitDetail] = useState({})
  const [partnerList, setPartnerList] = useState([])
  const [partnerFilter, setPartnerFilter] = useState('id_shipping_partner')
  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [selectedRowKeys, setSelectedRowKey] = useState([])
  const [showRecieveUpdate, setShowRecieveUpdate] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)
  const [priceLimit, setPriceLimit] = useState(0)
  const [activeTabs, setActiveTabs] = useState('Customer')
  const [selectedDateSummary, setSeletedDateSummary] = useState()
  const [selectedDate, setSeletedDate] = useState()
  const [formUpdate] = Form.useForm()

  const history = useHistory()

  const getCustomer = async (params) => {
    try {
      const res = await getUsers({ role_id: 7, page: 1, pageSize: 10000 })
      if (res.data.success) {
        setCustomerList(res.data.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const toggleOpenSelectSummary = () =>
    setIsOpenSelectSummary(!isOpenSelectSummary)
  const toggleOpenSelect = () => setIsOpenSelect(!isOpenSelect)

  const changeRangeSummary = (date, dateString) => {
    setFilterSummary({
      ...filterSummary,
      startDate: dateString[0],
      endDate: dateString[1],
    })
    setSeletedDateSummary(`${dateString[0]} -> ${dateString[1]}`)
    toggleOpenSelectSummary()
  }

  const changeRange = (date, dateString) => {
    setFilter({
      ...filter,
      startDate: dateString[0],
      endDate: dateString[1],
    })
    setSeletedDate(`${dateString[0]} -> ${dateString[1]}`)
    toggleOpenSelect()
  }
  const changeTimeOption = (value) => {
    setSeletedDate(value)
    if (value) {
      let tmp = getTimeRange(value)
      filter.startDate = tmp.from_date
      filter.endDate = tmp.to_date
    } else {
      delete filter.startDate
      delete filter.endDate
    }

    setFilter({ ...filter })
  }
  const getPartnerList = async (params, setData = setPartnerList) => {
    try {
      const res = await getShipping(params)
      if (res.data.success) {
        setData(res.data.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getDebit = async (params) => {
    try {
      setTableLoading(true)
      const res = await getAllDebit(params)
      if (res.data.success) {
        setDebitList(res.data.data)
        setTotalRecord(res.data.count)
        setDebitSum(res.data)
      }
      setTableLoading(false)
    } catch (err) {
      console.log(err)
      setTableLoading(false)
    }
  }

  const onSendNotifyDebit = async () => {
    try {
      var response = await sendNotifyDebit({
        debit_ids: selectedRowKeys,
      })
      if (response && response.status == 200) {
        notification.success({
          message: 'Gửi thông báo thành công',
        })
      } else {
        notification.error({
          message: response?.data?.message,
        })
      }
    } catch (e) {
      notification.error({
        message: '',
      })
    }
  }

  const setDetailDebit = (record) => {
    const customer = customerList.find((e) => e.id == record.id_customer)
    const partner = partnerList.find((e) => e.id === record.id_shipping)
    setDebitDetail({
      ...record,
      customer,
      partner,
    })
  }
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKey(selectedRowKeys)
  }

  const recivedUpdate = async () => {
    try {
      const res = await updateRecieve({ data: selectedRowKeys })
      if (res.data.success) {
        notification.success({ message: 'Cập nhật thành công' })
        setSelectedRowKey([])
        getDebit(removeNull({ ...filter, category: activeTabs }))
        setShowRecieveUpdate(false)
      } else {
        notification.error('Update thất bại')
      }
    } catch (err) {
      notification.error('Update thất bại')
      console.log(err)
    }
  }
  const onUpdateDebit = async (value) => {
    try {
      let tmp = { ...value }
      delete tmp.id
      delete tmp.cost_remain
      const res = await updateDebit(value.id, tmp)
      if (res.data.success) {
        notification.success({ message: 'Cập nhật thành công' })
        getDebit(removeNull({ ...filter, category: activeTabs }))
        setShowModalUpdate(false)
      } else {
        notification.error({ message: 'Cập nhật thất bại' })
      }
    } catch (err) {
      console.log(err)
      notification.error({ message: 'Cập nhật thất bại!' })
    }
  }

  const onExportDataAll = async () => {
    try {
      if (!filter.id_shipping_partner && activeTabs == 'Inland') {
        notification.warning({
          message:
            'Bạn cần lọc theo 1 đơn vị vận chuyển trước khi xuất báo cáo',
        })
        return
      }
      if (!filter.startDate && activeTabs == 'Inland') {
        notification.warning({
          message: 'Bạn cần lọc theo thời gian trước khi xuất báo cáo',
        })
        return
      }
      setTableLoading(true)
      const res = await exportAll({
        ...filter,
        page: 1,
        pageSize: 10000,
        export: true,
        category: activeTabs,
      })
      setTableLoading(false)
      if (res.status === 200) {
        if (res.data && res.data.success) {
          window.open(res.data.data)
        } else notification.error({ message: 'Export không thành công' })
      }
    } catch (err) {
      setTableLoading(false)
      console.log(err)
    }
  }

  const onExportDataPage = async () => {
    try {
      if (!filter.id_shipping_partner && activeTabs == 'Inland') {
        notification.warning({
          message:
            'Bạn cần lọc theo 1 đơn vị vận chuyển trước khi xuất báo cáo',
        })
        return
      }
      if (!filter.startDate && activeTabs == 'Inland') {
        notification.warning({
          message: 'Bạn cần lọc theo thời gian trước khi xuất báo cáo',
        })
        return
      }
      setTableLoading(true)
      const res = await exportCurrentPage({
        ...filter,
        page: 1,
        pageSize: 10000,
        export: true,
        category: activeTabs,
      })
      setTableLoading(false)
      if (res.status === 200) {
        if (res.data && res.data.success) {
          window.open(res.data.data)
        } else notification.error({ message: 'Export không thành công' })
      }
    } catch (err) {
      setTableLoading(false)
      console.log(err)
    }
  }

  const onExportDataFilter = async () => {
    try {
      if (!filter.id_shipping_partner && activeTabs == 'Inland') {
        notification.warning({
          message:
            'Bạn cần lọc theo 1 đơn vị vận chuyển trước khi xuất báo cáo',
        })
        return
      }
      if (!filter.startDate && activeTabs == 'Inland') {
        notification.warning({
          message: 'Bạn cần lọc theo thời gian trước khi xuất báo cáo',
        })
        return
      }
      setTableLoading(true)
      const res = await exportByFilter({
        ...filter,
        page: 1,
        pageSize: 10000,
        export: true,
        category: activeTabs,
      })
      setTableLoading(false)
      if (res.status === 200) {
        if (res.data && res.data.success) {
          window.open(res.data.data)
        } else notification.error({ message: 'Export không thành công' })
      }
    } catch (err) {
      setTableLoading(false)
      console.log(err)
    }
  }

  const resetFilter = () => {
    let tmp = {
      page: 1,
      pageSize: filter.pageSize,
    }

    setFilter(tmp)
    setSeletedDate(undefined)
  }

  const columns = {
    Customer: [
      {
        title: 'STT',
        width: 50,
        render(data, record, index) {
          return (filter.page - 1) * filter.pageSize + index + 1
        },
      },
      {
        title: 'Xem phiếu',
        render: (data, record) => {
          return (
            <span
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={() => {
                if (String(record.code).includes('NK-')) {
                  window.open(
                    ROUTES.UPDATE_WAREHOUSE_IMPORT + '/' + record.card_io.id,
                    '_blank'
                  )
                } else {
                  window.open(
                    ROUTES.UPDATE_WAREHOUSE_EXPORT + '/' + record.card_io.id,
                    '_blank'
                  )
                }
              }}>
              {data && data.card_io ? data.card_io.code : ''}
            </span>
          )
        },
      },
      {
        title: 'Mã bill',
        width: 100,
        dataIndex: 'code_bill',
        //  render(data)
      },
      {
        title: 'Ngày xuất kho/ nhập kho',
        dataIndex: 'date_create_card',
        render(data) {
          return (
            data &&
            String(moment(data).format('DD/MM/YYYY HH:mm')).replace(
              /\.000Z$/,
              ''
            )
          )
        },
      },
      {
        title: 'Ngày tạo',
        dataIndex: 'created_at',
        render(data) {
          return (
            data &&
            String(moment(data).format('DD/MM/YYYY HH:mm')).replace(
              /\.000Z$/,
              ''
            )
          )
        },
      },
      {
        title: 'Lần cuối cập nhật',
        dataIndex: 'updated_at',
        render(data) {
          return data && String(data).replace(/\.000Z$/, '')
        },
      },
      {
        title: 'Mã khách hàng',
        dataIndex: 'id_customer',
      },
      {
        title: 'Tổng thu',
        dataIndex: 'sum_cost',
        // align: 'right',
        render(data) {
          return formatNumber(Number(data).toFixed(0)) + ' VND'
        },
      },
      {
        title: 'Khuyến mãi',
        dataIndex: 'discount',
        // align: 'right',
        render(data) {
          return formatNumber(data) + ' VND'
        },
      },
      {
        title: 'Phát sinh',
        dataIndex: 'sub_fee',
        // align: 'right',
        render(data) {
          return formatNumber(data || 0) + ' VND'
        },
      },
      {
        title: 'Cần thanh toán',
        render(text, record) {
          return (
            formatNumber(
              Number(record.sum_cost).toFixed(0) -
                record.discount +
                (record.sub_fee || 0) -
                record.cost_received
            ) + ' VND'
          )
        },
      },
      {
        title: 'Đã thanh toán',
        dataIndex: 'cost_received',
        render(data) {
          return formatNumber(data) + ' VND'
        },
      },
      {
        title: 'Chi chú',
        dataIndex: 'description',
        render(data, record) {
          return data && data === 'Phí Xuất Kho' ? (
            <span style={{ color: 'green' }}>{data}</span>
          ) : (
            <span style={{ color: 'red' }}>{data}</span>
          )
        },
      },
      {
        title: 'Trạng thái',
        dataIndex: 'is_received',
        render: (text, record) =>
          record.is_received ? (
            <span style={{ color: 'green' }}>
              {record.type == 'thu' ? 'Đã thu' : 'Đã chi'}
            </span>
          ) : (
            <span style={{ color: '#f0932b' }}>
              {record.type == 'thu' ? 'Cần thu' : 'Cần chi'}
            </span>
          ),
      },
      {
        title: 'Cập nhật',
        render(data) {
          return (
            <>
              <Permission permissions={[PERMISSIONS.cap_nhat_cong_no]}>
                <EditOutlined
                  style={{ cursor: 'pointer', color: '#1890FF' }}
                  onClick={() => {
                    setPriceLimit(Number(data.sum_cost).toFixed(0))
                    formUpdate.setFieldsValue({
                      ...data,
                      cost_remain:
                        Number(data.sum_cost).toFixed(0) -
                          data.discount +
                          (data.sub_fee || 0) || 0,
                      cost_received: data.cost_received || 0,
                    })
                    setDebitDetail({
                      ...data,
                      cost_remain:
                        Number(data.sum_cost).toFixed(0) -
                          data.discount +
                          (data.sub_fee || 0) || 0,
                      cost_received: data.cost_received || 0,
                    })
                    setShowModalUpdate(true)
                  }}
                />
              </Permission>
            </>
          )
        },
      },
    ],
    International: [
      {
        title: 'STT',
        width: 50,
        render(data, record, index) {
          return (filter.page - 1) * filter.pageSize + index + 1
        },
      },
      {
        title: 'Xem phiếu',
        render(data, record) {
          return (
            <span
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={() => {
                window.open(
                  ROUTES.UPDATE_WAREHOUSE_IMPORT + '/' + record.card_io.id,
                  '_blank'
                )
              }}>
              {data && data.card_io ? data.card_io.code : ''}
            </span>
          )
        },
      },
      {
        title: 'Ngày tạo',
        dataIndex: 'created_at',
        render(data) {
          return data && moment(data).format('DD/MM/YYYY')
        },
      },
      {
        title: 'Lần cuối cập nhật',
        dataIndex: 'updated_at',
        render(data) {
          return data && moment(data).format('DD/MM/YYYY')
        },
      },
      {
        title: 'Tên đơn vị vận chuyển',
        dataIndex: 'shipping_partner',
        render(data) {
          return data && data.name
        },
      },
      {
        title: 'Tổng chi',
        dataIndex: 'sum_cost',
        // align: 'right',
        render(data) {
          return formatNumber(Number(data).toFixed(0)) + ' VND'
        },
      },
      {
        title: 'Cần thanh toán',
        dataIndex: 'cost_remain',
        render(text, record) {
          return (
            formatNumber(
              Number(record.sum_cost).toFixed(0) -
                record.discount +
                (record.sub_fee || 0) -
                record.cost_received
            ) + ' VND'
          )
        },
      },
      {
        title: 'Đã thanh toán',
        dataIndex: 'cost_received',
        render(data) {
          return formatNumber(data) + ' VND'
        },
      },
      {
        title: 'Chi chú',
        dataIndex: 'description',
        render(data, record) {
          return data && data === 'Phí Xuất Kho' ? (
            <span style={{ color: 'green' }}>{data}</span>
          ) : (
            <span style={{ color: 'red' }}>{data}</span>
          )
        },
      },
      {
        title: 'Trạng thái',
        dataIndex: 'is_received',
        render: (text, record) =>
          record.is_received ? (
            <span style={{ color: 'green' }}>
              {' '}
              {record.type == 'thu' ? 'Đã thu' : 'Đã chi'}
            </span>
          ) : (
            <span style={{ color: '#f0932b' }}>
              {record.type == 'thu' ? 'Cần thu' : 'Cần chi'}
            </span>
          ),
      },
      {
        title: 'Cập nhật',
        width: 100,
        render(data) {
          return (
            <>
              <Permission permissions={[PERMISSIONS.cap_nhat_cong_no]}>
                <EditOutlined
                  style={{ cursor: 'pointer', color: '#1890FF' }}
                  onClick={() => {
                    setPriceLimit(Number(data.sum_cost).toFixed(0))
                    formUpdate.setFieldsValue({
                      ...data,
                      cost_remain:
                        Number(data.sum_cost).toFixed(0) -
                          data.discount +
                          (data.sub_fee || 0) || 0,
                      cost_received: data.cost_received || 0,
                    })
                    setDebitDetail({
                      ...data,
                      cost_remain:
                        Number(data.sum_cost).toFixed(0) -
                          data.discount +
                          (data.sub_fee || 0) || 0,
                      cost_received: data.cost_received || 0,
                    })
                    setShowModalUpdate(true)
                  }}
                />
              </Permission>
            </>
          )
        },
      },
    ],
    Inland: [
      {
        title: 'STT',
        width: 50,
        render(data, record, index) {
          return (filter.page - 1) * filter.pageSize + index + 1
        },
      },
      {
        title: 'Xem phiếu',
        render(data, record, index) {
          return (
            <span
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={() => {
                if (String(record.card_io?.code).includes('NK-')) {
                  window.open(
                    ROUTES.UPDATE_WAREHOUSE_IMPORT + '/' + record.card_io.id,
                    '_blank'
                  )
                } else {
                  window.open(
                    ROUTES.UPDATE_WAREHOUSE_EXPORT + '/' + record.card_io.id,
                    '_blank'
                  )
                }
              }}>
              {data && data.card_io ? data.card_io.code : ''}
            </span>
          )
        },
      },
      {
        title: 'Ngày tạo',
        dataIndex: 'created_at',
        render(data) {
          return data && moment(data).format('DD/MM/YYYY')
        },
      },
      {
        title: 'Lần cuối cập nhật',
        dataIndex: 'updated_at',
        render(data) {
          return data && moment(data).format('DD/MM/YYYY')
        },
      },
      {
        title: 'Tên đơn vị vận chuyển',
        dataIndex: 'shipping_partner',
        render(data) {
          return data && data.name
        },
      },
      {
        title: 'Tổng chi',
        dataIndex: 'sum_cost',
        // align: 'right',
        render(data) {
          return formatNumber(Number(data).toFixed(0)) + ' VND'
        },
      },
      {
        title: 'Cần thanh toán',
        dataIndex: 'cost_remain',
        render(text, record) {
          return (
            formatNumber(
              Number(record.sum_cost).toFixed(0) -
                record.discount +
                (record.sub_fee || 0) -
                record.cost_received
            ) + ' VND'
          )
        },
      },
      {
        title: 'Đã thanh toán',
        dataIndex: 'cost_received',
        render(data) {
          return formatNumber(data) + ' VND'
        },
      },
      {
        title: 'Chi chú',
        dataIndex: 'description',
        render(data, record) {
          return data && data === 'Phí Xuất Kho' ? (
            <span style={{ color: 'green' }}>{data}</span>
          ) : (
            <span style={{ color: 'red' }}>{data}</span>
          )
        },
      },
      {
        title: 'Trạng thái',
        dataIndex: 'is_received',
        render: (text, record) =>
          record.is_received ? (
            <span style={{ color: 'green' }}>
              {record.type == 'thu' ? 'Đã thu' : 'Đã chi'}
            </span>
          ) : (
            <span style={{ color: '#f0932b' }}>
              {record.type == 'thu' ? 'Cần thu' : 'Cần chi'}
            </span>
          ),
      },
      {
        title: 'Cập nhật',
        width: 100,
        render(data) {
          return (
            <>
              <Permission permissions={[PERMISSIONS.cap_nhat_cong_no]}>
                <EditOutlined
                  style={{ cursor: 'pointer', color: '#1890FF' }}
                  onClick={() => {
                    setPriceLimit(Number(data.sum_cost).toFixed(0))
                    formUpdate.setFieldsValue({
                      ...data,
                      cost_remain:
                        Number(data.sum_cost).toFixed(0) -
                          data.discount +
                          (data.sub_fee || 0) || 0,
                      cost_received: data.cost_received || 0,
                    })
                    setDebitDetail({
                      ...data,
                      cost_remain:
                        Number(data.sum_cost).toFixed(0) -
                          data.discount +
                          (data.sub_fee || 0) || 0,
                      cost_received: data.cost_received || 0,
                    })
                    setShowModalUpdate(true)
                  }}
                />
              </Permission>
            </>
          )
        },
      },
    ],
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  const changePagination = (page, pageSize) =>
    setFilter({ ...filter, page, pageSize })

  useEffect(() => {
    getCustomer()
    getPartnerList()
  }, [])
  useEffect(() => {
    getDebit(removeNull({ ...filter, category: activeTabs }))
  }, [filter, activeTabs])
  return (
    <>
      <Row justify="space-between">
        <Col>
          <Typography.Title level={4}>
            {translate('Debit Note')}
          </Typography.Title>
        </Col>
      </Row>
      {/* <Row>
        <Col span={8}>
          <h5 style={{ margin: 0 }}>{translate('Filter option')}</h5>
          <Select
            open={isOpenSelectSummary}
            onBlur={() => {
              if (isOpenSelectSummary) toggleOpenSelectSummary()
            }}
            onClick={() => {
              if (!isOpenSelectSummary) toggleOpenSelectSummary()
            }}
            style={{ width: '100%' }}
            placeholder="Chọn thời gian"
            allowClear
            value={selectedDateSummary}
            onChange={async (value) => {
              if (isOpenSelectSummary) toggleOpenSelectSummary()
              changeTimeOption(value)
            }}
            dropdownRender={(menu) => (
              <div>
                <RangePicker
                  placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                  onFocus={() => {
                    if (!isOpenSelect) toggleOpenSelectSummary()
                  }}
                  onBlur={() => {
                    if (isOpenSelect) toggleOpenSelectSummary()
                  }}
                  style={{ width: '100%' }}
                  showTime
                  format="YYYY-MM-DD"
                  onOpenChange={() => {
                    setTimeout(() => {
                      let timePicker = document.querySelector(
                        '.ant-picker-panel-container .ant-picker-time-panel'
                      )
                      timePicker.style = 'display:none'
                    }, 500)
                  }}
                  onChange={changeRangeSummary}
                />
                {menu}
              </div>
            )}>
            <Option value="to_day">{translate('Today')} </Option>
            <Option value="yesterday">{translate('Yesterday')}</Option>
            <Option value="this_week">{translate('This week')} </Option>
            <Option value="last_week">{translate('Last week')} </Option>
            <Option value="last_month">{translate('Last month')} </Option>
            <Option value="this_month">{translate('This month')} </Option>
            <Option value="this_year">{translate('This year')}</Option>
            <Option value="last_year">{translate('Last year')}</Option>
          </Select>
        </Col>
      </Row> */}
      <Row gutter={30} style={{ marginTop: 0 }}>
        <Col span={6}>
          <BalanceItem
            colorFrom="rgba(190, 66, 163, 1)"
            colorTo="rgba(193, 103, 157, 1)"
            title="Tổng Đã Chi"
            // icon={<CreditCardOutlined />}
            price={debitSum.sum_cost_income || 0}
          />
        </Col>
        <Col span={6}>
          <BalanceItem
            colorFrom="rgba(143, 66, 190, 1)"
            colorTo="rgba(130, 97, 201, 1)"
            title="Tổng Đã Thu"
            // icon={<CreditCardOutlined />}
            price={debitSum.sum_cost_outcome || 0}
          />
        </Col>
        <Col span={6}>
          <BalanceItem
            colorFrom="rgba(66, 78, 190, 1)"
            colorTo="rgba(97, 107, 201, 1)"
            title="Tổng Cần Thu"
            // icon={<CreditCardOutlined />}
            price={debitSum.need_cost_income || 0}
          />
        </Col>

        <Col span={6}>
          <BalanceItem
            colorFrom="rgba(66, 78, 190, 1)"
            colorTo="rgba(97, 107, 201, 1)"
            title="Tổng Cần Chi"
            // icon={<CreditCardOutlined />}
            price={debitSum.need_cost_outcome || 0}
          />
        </Col>
      </Row>
      <Row gutter={30} style={{ marginTop: 10 }}>
        <Col span={6}>
          <BalanceItem
            colorFrom="rgba(190, 66, 163, 1)"
            colorTo="rgba(193, 103, 157, 1)"
            title="Tổng Khuyến Mãi"
            // icon={<CreditCardOutlined />}
            price={debitSum.sum_discount || 0}
          />
        </Col>

        <Col span={6}>
          <BalanceItem
            colorFrom="rgba(190, 66, 163, 1)"
            colorTo="rgba(193, 103, 157, 1)"
            title="Tổng Phát Sinh"
            price={debitSum.sum_sub_fee || 0}
          />
        </Col>
      </Row>
      <Tabs
        className="warehouse-tabs"
        onChange={(e) => {
          setFilter({ page: 1, pageSize: 10 })
          setActiveTabs(e)
        }}>
        <Tabs.TabPane tab="Khách hàng" key="Customer"></Tabs.TabPane>
        <Tabs.TabPane
          tab="Vận chuyển quốc tế"
          key="International"></Tabs.TabPane>
        <Tabs.TabPane tab="Vận chuyển nội địa" key="Inland"></Tabs.TabPane>
      </Tabs>
      <br />

      <Row gutter={20}>
        <Col span={8}>
          <h5 style={{ margin: 0 }}>{translate('Filter option')}</h5>
          <Select
            open={isOpenSelect}
            onBlur={() => {
              if (isOpenSelect) toggleOpenSelect()
            }}
            onClick={() => {
              if (!isOpenSelect) toggleOpenSelect()
            }}
            style={{ width: '100%' }}
            placeholder="Chọn thời gian"
            allowClear
            value={selectedDate}
            onChange={async (value) => {
              if (isOpenSelect) toggleOpenSelect()
              changeTimeOption(value)
            }}
            dropdownRender={(menu) => (
              <div>
                <RangePicker
                  locale={locale}
                  placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                  onFocus={() => {
                    if (!isOpenSelect) toggleOpenSelect()
                  }}
                  onBlur={() => {
                    if (isOpenSelect) toggleOpenSelect()
                  }}
                  style={{ width: '100%' }}
                  showTime
                  format="YYYY-MM-DD"
                  onOpenChange={() => {
                    setTimeout(() => {
                      let timePicker = document.querySelector(
                        '.ant-picker-panel-container .ant-picker-time-panel'
                      )
                      if (timePicker) timePicker.style = 'display:none'
                    }, 500)
                  }}
                  onChange={changeRange}
                />
                {menu}
              </div>
            )}>
            <Option value="to_day">{translate('Today')} </Option>
            <Option value="yesterday">{translate('Yesterday')}</Option>
            <Option value="this_week">{translate('This week')} </Option>
            <Option value="last_week">{translate('Last week')} </Option>
            <Option value="last_month">{translate('Last month')} </Option>
            <Option value="this_month">{translate('This month')} </Option>
            <Option value="this_year">{translate('This year')}</Option>
            <Option value="last_year">{translate('Last year')}</Option>
          </Select>
        </Col>
        {activeTabs === 'Customer' && (
          <Col span={8}>
            <h5 style={{ margin: 0 }}>Tìm khách hàng</h5>
            <Select
              placeholder="Chọn khách hàng"
              style={{ width: '100%' }}
              value={filter.customer}
              allowClear
              onChange={(e) => setFilter({ ...filter, customer: e })}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              optionFilterProp="children">
              {customerList.map((e) => (
                <Select.Option value={e.id}>{e.customer_code}</Select.Option>
              ))}
            </Select>
          </Col>
        )}

        <Col span={8}>
          <h5 style={{ margin: 0 }}>Trạng thái thu tiền</h5>
          <Select
            placeholder="Chọn trạng thái"
            style={{ width: '100%' }}
            allowClear
            value={filter.type}
            onChange={(e) => setFilter({ ...filter, status: e })}>
            <Select.Option value="waiting">{'Cần chi/thu'}</Select.Option>

            <Select.Option value="complete">{'Đã chi/thu'}</Select.Option>
          </Select>
        </Col>

        {activeTabs === 'Inland' && (
          <Col span={8}>
            <h5 style={{ margin: 0 }}>Lọc theo loại phí</h5>
            <Select
              placeholder="Chọn loại phí"
              style={{ width: '100%' }}
              allowClear
              value={filter.fee_type}
              onChange={(e) => setFilter({ ...filter, fee_type: e })}>
              <Select.Option value="import">Phí nhập kho</Select.Option>
              <Select.Option value="export">Phí xuất kho</Select.Option>
            </Select>
          </Col>
        )}

        {activeTabs != 'Customer' && (
          <Col span={8}>
            <h5 style={{ margin: 0 }}>Tìm kiếm đơn vị vận chuyển</h5>
            <Select
              style={{ width: '100%' }}
              value={filter.id_shipping_partner}
              onChange={(e) => setFilter({ ...filter, id_shipping_partner: e })}
              placeholder="Chọn đơn vị vận chuyển"
              allowClear>
              {partnerList
                .filter((e) => e.area === activeTabs)
                .map((e) => (
                  <Select.Option value={e.id}>{e.name}</Select.Option>
                ))}
            </Select>
          </Col>
        )}
      </Row>

      <br />
      <Row style={{ padding: 5 }} justify="space-between">
        {activeTabs == 'Customer' && (
          <Col>
            {selectedRowKeys.length > 0 && (
              <Popconfirm
                title={'Xác nhận gửi thông báo đối soát công nợ?'}
                onConfirm={(e) => {
                  onSendNotifyDebit()
                }}>
                <Button type="primary">Gửi thông báo đối soát</Button>
              </Popconfirm>
            )}
          </Col>
        )}
        <Col>
          <Permission permissions={[PERMISSIONS.xuat_file_cong_no]}>
            <Button
              type="primary"
              onClick={onExportDataPage}
              style={{
                backgroundColor: 'rgba(219, 113, 15, 0.83)',
                border: 'none',
                marginRight: 10,
              }}>
              Xuất công nợ trang hiện tại
            </Button>
          </Permission>
          <Permission permissions={[PERMISSIONS.xuat_file_cong_no]}>
            <Button
              type="primary"
              onClick={onExportDataFilter}
              style={{
                backgroundColor: 'rgba(219, 113, 15, 0.83)',
                border: 'none',
                marginRight: 10,
              }}>
              Xuất công nợ theo bộ lọc
            </Button>
          </Permission>
          <Permission permissions={[PERMISSIONS.xuat_file_cong_no]}>
            <Button
              type="primary"
              onClick={onExportDataAll}
              style={{
                backgroundColor: 'rgba(219, 113, 15, 0.83)',
                border: 'none',
                marginRight: 10,
              }}>
              Xuất toàn bộ công nợ
            </Button>
          </Permission>
          <Button type="primary" danger onClick={resetFilter}>
            Xóa bộ lọc
          </Button>
        </Col>
      </Row>
      <div
        style={{
          width: '100%',
          maxWidth: '100%',
          overflow: 'scroll',
          display: 'block',
          backgroundColor: '#fff',
        }}>
        <Table
          size="small"
          // rowSelection={rowSelection}
          columns={columns[activeTabs].map((e) => {
            if (e.dataIndex == 'id_customer') {
              return {
                ...e,
                render(data, record) {
                  return record.user && record.user.customer_code
                },
              }
            }
            return e
          })}
          rowSelection={activeTabs == 'Customer' ? rowSelection : undefined}
          scroll={{ x: 'max-content', y: '60vh' }}
          dataSource={debitList}
          rowKey="id"
          loading={tableLoading}
          rowClassName={(data) => (data.is_received ? 'debit-recieved' : '')}
          pagination={{
            pageSize: filter.pageSize,
            pageSizeOptions: [100, 200, 300, 400],
            onChange: changePagination,
            current: filter.page,
            total: totalRecord,
          }}
          // summary={(pageData) => (
          //   <Table.Summary.Row>
          //     <Table.Summary.Cell>
          //       <div style={{ fontWeight: 700 }}>Tổng</div>
          //     </Table.Summary.Cell>
          //     {activeTabs == 'Customer' && (
          //       <Table.Summary.Cell></Table.Summary.Cell>
          //     )}
          //     <Table.Summary.Cell></Table.Summary.Cell>
          //     <Table.Summary.Cell></Table.Summary.Cell>
          //     <Table.Summary.Cell></Table.Summary.Cell>
          //     <Table.Summary.Cell></Table.Summary.Cell>
          //     <Table.Summary.Cell></Table.Summary.Cell>
          //     <Table.Summary.Cell>
          //       {formatNumber(
          //         pageData.reduce((a, b) => a + (b.sum_cost || 0), 0)
          //       )}{' '}
          //       VND
          //     </Table.Summary.Cell>

          //     <Table.Summary.Cell>
          //       {formatNumber(
          //         pageData.reduce((a, b) => a + (b.discount || 0), 0)
          //       )}{' '}
          //       VND
          //     </Table.Summary.Cell>

          //     <Table.Summary.Cell>
          //       {formatNumber(
          //         pageData.reduce(
          //           (a, b) => a + (b.cost_remain - b.discount) || 0,
          //           0
          //         )
          //       )}{' '}
          //       VND
          //     </Table.Summary.Cell>
          //     <Table.Summary.Cell>
          //       {formatNumber(
          //         pageData.reduce((a, b) => a + b.cost_received || 0, 0)
          //       )}{' '}
          //       VND
          //     </Table.Summary.Cell>
          //     <Table.Summary.Cell></Table.Summary.Cell>
          //     <Table.Summary.Cell></Table.Summary.Cell>
          //     <Table.Summary.Cell></Table.Summary.Cell>
          //   </Table.Summary.Row>
          // )}
        />
      </div>

      <Modal
        footer={null}
        title="Chi tiết công nợ"
        visible={showDetail}
        onCancel={() => setShowDetail(false)}
        onOk={() => setShowDetail(false)}>
        <Row gutter={[20, 10]}>
          <Col
            span={12}
            style={{
              display:
                (!debitDetail.card_io || !debitDetail.card_io.user) && 'none',
            }}>
            Tên khách hàng:{' '}
            {(debitDetail.card_io && debitDetail.card_io?.user?.name) || ''}
          </Col>
          <Col span={12}>Ghi chú: {debitDetail.description}</Col>
          <Col span={12}>
            Ngày tạo:{' '}
            {moment(debitDetail.created_at).format('DD/MM/YYYY HH:mm')}
          </Col>
          <Col span={12}>
            Tổng công nợ: {formatNumber(debitDetail.sum_cost)}
            VND
          </Col>
          <Col span={12}>
            Tổng cần thu: {formatNumber(debitDetail.cost_remain)}
            VND
          </Col>
          <Col span={12}>
            Tổng đã thu: {formatNumber(debitDetail.cost_received)} VND
          </Col>
          <Col span={12}>
            Vận chuyển: {debitDetail.card_io?.shipping_partner?.name || ''}
          </Col>
          <Col span={12}>
            Trạng thái: {debitDetail.is_received ? 'Đã thu' : 'Chưa thu'}
          </Col>
        </Row>
      </Modal>

      <Modal
        // title="Cập nhật công nợ"
        closable=""
        visible={showRecieveUpdate}
        onCancel={() => setShowRecieveUpdate(false)}
        onOk={recivedUpdate}>
        <h3>Cập nhật đã thu {selectedRowKeys.length} công nợ. !!</h3>
      </Modal>

      <Drawer
        title="Cập nhật phiếu"
        visible={showModalUpdate}
        width="30%"
        onClose={() => setShowModalUpdate(false)}>
        <Form layout="vertical" form={formUpdate} onFinish={onUpdateDebit}>
          <Form.Item
            name="cost_remain"
            label={debitDetail.type == 'thu' ? 'Cần thu' : 'Cần Chi'}>
            <InputNumber
              min={0}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              style={{ width: '100%' }}
              disabled
            />
          </Form.Item>

          <Form.Item name="discount" label="Khuyến mãi">
            <InputNumber
              min={0}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              style={{ width: '100%' }}
            />
          </Form.Item>

          <Form.Item name="sub_fee" label="Phát sinh">
            <InputNumber
              min={0}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              style={{ width: '100%' }}
            />
          </Form.Item>

          <Form.Item
            name="cost_received"
            label={debitDetail.type == 'thu' ? 'Đã thu' : 'Đã chi'}>
            <InputNumber
              min={0}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item name="description" label="Ghi chú">
            <Input.TextArea />
          </Form.Item>
          <Row justify="end">
            <Button type="primary" htmlType="submit">
              {translate('Update')}
            </Button>
          </Row>
          <Form.Item name="id">
            <Input hidden />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}
