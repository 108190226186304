import React from 'react'
import { Col, Tag } from 'antd'
import moment from 'moment-timezone'
import { translate } from 'utils/i18n'
import { formatNumber } from 'utils'
import ModalListOrder from './modalListOrder'
export default [
  {
    title: translate('Card code'),
    dataIndex: 'code',
    key: 'code_io',
  },
  {
    title: 'Trạng thái',
    key: 'status',
  },
  {
    title: translate('Amount package'),
    dataIndex: 'amount_import_package',
    width: 50,
    key: 'order',
    // render: (data) => data || 0,
    // render(data) {
    //   return (
    //     (data &&
    //       data.orders &&
    //       data.orders.reduce(
    //         (a, b) => a + (b.amount_package_imported || 0),
    //         0
    //       )) ||
    //     0
    //   )
    // },
  },
  {
    title: translate('List Order'),
    key: 'list_order',
    // render: (data) => data || 0,
    render(text, record) {
      return (
        record &&
        record.orders && <ModalListOrder type={'import'} data={record.orders} />
      )
    },
  },
  // {
  //   title: 'Vận chuyển',
  //   dataIndex: 'shipping_partner',
  //   key: 'shipping_partner',
  //   render(data) {
  //     return data && data.name
  //   },
  // },
  {
    title: 'Ngày nhập kho',
    key: 'created_at',
    render(text, record) {
      return (
        record &&
        moment(String(record.created_at).replace(/\.000Z$/, '')).format(
          'DD/MM/YYYY HH:mm:ss'
        )
      )
    },
  },
  // {
  //   title: 'Lần cuối cập nhật',
  //   dataIndex: 'updated_at',
  //   key: 'updated_at',
  // },
  {
    title: 'Vận chuyển cước nhập  ',
    dataIndex: 'shipping_partner',
    key: 'shipping_partner',
    render(data) {
      return data && data.name
    },
  },
  {
    title: 'Cước nhập kho',
    dataIndex: 'cost_import',
    key: 'cost',
    render(data) {
      return formatNumber(data) + ' VND'
    },
  },
  {
    title: 'Lưu nháp',
    key: 'is_activate',
    render(data, record) {
      return (
        <div>
          {record.is_activate ? (
            <Tag color={'green'}>{'Kích hoạt'}</Tag>
          ) : (
            <Tag color={'grey'}>{'Lưu nháp'}</Tag>
          )}
        </div>
      )
    },
  },
  {
    title: 'Thông báo',
    key: 'status_notify',
    render(data, record) {
      return (
        <Col>
          <div>{record.status_notify ? 'Đã gửi' : 'Chưa gửi'}</div>
        </Col>
      )
    },
  },
  {
    title: 'Ngày gửi',
    key: 'noty_date',
    width: 50,
    render(data, record) {
      return record.noty_date && moment(record.noty_date).format('DD/MM')
    },
  },
  { key: 'action', title: 'Hành động', width: 100 },
]
