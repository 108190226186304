const columns = [
  {
    title: 'STT',
    key: 'stt',
  },
  {
    title: 'Mã',
    dataIndex: 'code',
  },
  {
    title: 'Tên',
    dataIndex: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  // {
  //   title: 'Liên hệ',
  //   dataIndex: 'tel',
  // },
  {
    title: 'Số điện thoại',
    dataIndex: 'phone',
  },
  {
    title: 'Địa chỉ',
    dataIndex: 'address',
  },
  {
    title: 'Hành động',
    key: 'action',
    width: 100,
  },
]
export default columns
