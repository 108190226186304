import React from 'react'

import formatNumber from 'utils/formatNumber'
import moment from 'moment-timezone'

//icons antd
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'

//components atnd
import { Checkbox, Tag } from 'antd'

export const customer = [
  {
    title: 'STT',
    render(data, record, index) {
      return index + 1
    },
  },
  {
    title: 'Ngày',
    dataIndex: 'created_at',
    render(data) {
      return moment(data).format('MM/DD/YYYY HH:mm')
    },
  },
  {
    title: 'Mã khách hàng',
    dataIndex: 'id_customer',
  },
  {
    title: 'Công nợ',
  },
  {
    title: 'Đã thu',
    render(data) {
      return <Checkbox />
    },
  },
  {
    title: 'Thu khách',
  },
  {
    title: 'Chi chú',
    dataIndex: 'description',
  },
]

export const inetrnational = [
  {
    title: 'STT',
    render(data, record, index) {
      return index + 1
    },
  },
  {
    title: 'Ngày',
    dataIndex: 'created_at',
    render(data) {
      return moment(data).format('DD/MM/YYYY')
    },
  },
  {
    title: 'Tên đơn vị vận chuyển',
    dataIndex: 'id_customer',
  },
  {
    title: 'Công nợ',
  },
  {
    title: 'Đã thu',
    render(data) {
      return <Checkbox />
    },
  },
  {
    title: 'Thu khách',
  },
  {
    title: 'Chi chú',
    dataIndex: 'description',
  },
]

export const inland = [
  {
    title: 'STT',
    render(data, record, index) {
      return index + 1
    },
  },
  {
    title: 'Ngày',
    dataIndex: 'created_at',
    render(data) {
      return moment(data).format('DD/MM/YYYY')
    },
  },
  {
    title: 'Mã khách hàng',
    dataIndex: 'id_customer',
  },
  {
    title: 'Công nợ',
  },
  {
    title: 'Đã thu',
    render(data) {
      return <Checkbox />
    },
  },
  {
    title: 'Thu khách',
  },
  {
    title: 'Chi chú',
    dataIndex: 'description',
  },
]
