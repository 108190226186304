import React, { useState, useEffect } from 'react'
import locale from 'antd/es/date-picker/locale/vi_VN'
import {
  Row,
  Col,
  Select,
  Input,
  InputNumber,
  Button,
  DatePicker,
  Table,
  Modal,
  notification,
  Checkbox,
  Tooltip,
  Space,
} from 'antd'
import { MessageOutlined } from '@ant-design/icons'
import styles from './styles.module.scss'
import { getUsers } from 'apis/users'
import { getShipping } from 'apis/shipping'
import { getOrderByDate, getOrder } from 'apis/order'
import { translate } from 'utils/i18n'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { formatNumber } from 'utils'
export default function WarehouseExportForm({ data, setData }) {
  const [customerList, setCustomerList] = useState([])
  const [partnerList, setPartnerList] = useState([])
  const [listsSearchByDate, setListSearchByDate] = useState([])
  const [showPickOrder, setShowPickOrder] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedCustomerDebit, setSelectedCustomerDebit] = useState(false)
  const [sumVolume, setSumVolume] = useState(0)
  const [sumMass, setSumMass] = useState(0)
  const [totalExport, setTotalExport] = useState(0)
  const [totalRequireExport, setTotalRequireExport] = useState(0)
  const [totalSumCost, setTotalSumCost] = useState(0)

  const history = useHistory()
  const getCustomerList = async () => {
    try {
      const res = await getUsers({ role_id: 7, page: 1, pageSize: 10000 })
      if (res.data.success) {
        setCustomerList(res.data.data)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const getPartnerList = async () => {
    try {
      const res = await getShipping({ area: 'Inland' })
      if (res.data.success) {
        setPartnerList(res.data.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const searchByCustomer = async (params) => {
    try {
      const res = await getOrder({
        customer_code: params,
        order_status: 'CameBack',
        export: true,
      })
      if (res.data.success) {
        setListSearchByDate(
          res.data.data.filter((e) => e.order_status == 'CameBack')
        )
        setShowPickOrder(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const columns = [
    {
      title: 'Mã bill',
      dataIndex: 'code_bill',
    },
    {
      title: 'Số kiện cần xuất',
      dataIndex: 'num',
      render(_data, record, index) {
        // console.log(
        //   'record.total_quantity_package ',
        //   record.total_quantity_package
        // )
        // console.log('record.amount_package_exported', record)
        // var _total_default =
        //   record.total_quantity_package - (record.amount_package_exported || 0)

        return (
          <InputNumber
            style={{ width: 120 }}
            onBlur={(e) => {
              const value = e.target.value.replaceAll(',', '')

              if (
                parseInt(value) + parseInt(record.amount_package_export || 0) >
                record.total_quantity_package
              ) {
                notification.error({
                  message:
                    'Vượt quá số lượng tối đa có thể xuất, vui lòng thử lại',
                })
                return
              }

              if (data && data.data[index]) {
                data.data[index].num = parseInt(value)
                data.data[index].amount_package_export = parseInt(value)
              }

              setData({ ...data })
            }}
            defaultValue={record.amount_package_export}
            min={0}
            max={record.total_quantity_package}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            placeholder="Nhập số lượng"
          />
        )
      },
    },
    {
      title: 'Đã xuất',
      dataIndex: 'total_quantity_package',
      render(data, record) {
        return (
          <div>{`${record.amount_package_exported}/${record.total_quantity_package}`}</div>
        )
      },
    },
    {
      title: 'Tổng cước',
      dataIndex: 'sum_cost',
      render(data) {
        return formatNumber(data)
      },
    },
    {
      title: 'Trọng lượng',
      dataIndex: 'mass',
      render(data) {
        return data + ' kg'
      },
    },
    {
      title: 'Khối lượng',
      dataIndex: 'volume',
      render(data) {
        return (
          <>
            {data} m<sup>3</sup>
          </>
        )
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      render(data) {
        return moment(data).format('DD/MM/YYYY')
      },
    },
  ]

  const columnOrder = [
    {
      title: 'STT',
      width: 40,
      key: 0,
    },
    {
      title: 'Kho',
      key: 22,
      width: 90,
      dataIndex: 'warehouse',
    },
    {
      title: 'Ngày ký nhận',
      key: 1,
      width: 90,
      dataIndex: 'date_sign',
    },

    {
      title: translate('Bill number'),
      dataIndex: 'code_bill',
      key: 2,
      width: 90,
    },
    {
      title: translate('Amount package'),
      dataIndex: 'total_quantity_package',
      key: 3,
      width: 90,
    },
    {
      title: translate('Amount package imported'),
      dataIndex: 'amount_package_imported',
      key: 201,
      width: 90,
    },
    {
      title: translate('Amount package exported'),
      dataIndex: 'amount_package_exported',
      key: 202,
      width: 90,
    },
    {
      title: translate('Waybill number'),
      dataIndex: 'tracking_number',
      key: 4,
      width: 200,
    },
    {
      title: translate('Customer id'),
      dataIndex: 'user',
      width: 100,
      key: 5,
    },

    {
      title: translate('Payment help'),
      dataIndex: 'cod',
      width: 100,
      key: 6,
    },
    {
      title: translate('Chi phí Đóng gỗ/ Đóng tải'),
      dataIndex: 'fee_bag',
      key: 7,
      width: 100,
    },
    {
      title: 'Phí nâng hàng',
      dataIndex: 'fee_package',
      width: 100,

      key: 8,
    },
    // {
    //   title: translate('Surcharge'),
    //   dataIndex: 'sub_cost',
    //   key: 9,
    // },
    {
      title: translate('Mass'),
      dataIndex: 'mass',
      width: 100,
      key: 10,
    },
    {
      title: 'Phụ phí trọng lượng (kg)',
      dataIndex: 'sub_fee_kg',
      width: 100,
      key: 21,
    },
    {
      title: translate('Volume'),
      dataIndex: 'volume',
      width: 100,
      key: 11,
    },
    {
      title: 'Phụ phí khối lượng (m3)',
      dataIndex: 'sub_fee_m3',
      width: 100,
      key: 20,
    },
    {
      title: translate('Unit price'),
      dataIndex: 'unit_price',
      width: 100,
      key: 12,
    },
    {
      title: translate('Total charge'),
      dataIndex: 'sum_cost',
      width: 100,
      key: 13,
    },
    {
      title: translate('Origin cost'),
      dataIndex: 'cost_origin',
      width: 100,
      key: 14,
    },
    {
      title: translate('Order status'),
      dataIndex: 'order_status',
      width: 100,
      key: 16,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 100,
      key: 17,
    },
    {
      title: 'Cập nhật lần cuối',
      dataIndex: 'updated_at',
      width: 100,
      key: 18,
    },
    {
      title: 'Mô tả hàng hóa',
      dataIndex: 'description',
      width: 100,
      key: 19,
    },
    {
      title: 'Hành động',
      width: 120,
      key: 'action',
    },
  ]
  const searchBillByDate = async (params) => {
    try {
      const res = await getOrderByDate(params)
      if (res.data.success) {
        setListSearchByDate(
          res.data.data.filter((e) => e.order_status == 'CameBack')
        )
        setShowPickOrder(true)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys)
  }
  const onAddOrder = () => {
    let tmp = listsSearchByDate.filter(
      (e) => selectedRowKeys.indexOf(e.id) != -1
    )
    tmp = tmp.filter(
      (e) =>
        data.data
          .map((f) => {
            return f.id
          })
          .indexOf(e.id) == -1
    )
    setData((e) => {
      return { ...e, data: [...e.data, ...tmp] }
    })
    setShowPickOrder(false)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  const copyMessage = () => {
    let mess = data.data
      .map((e) => {
        return `${e.code_bill} - ${e.mass}kg - ${e.volume}cm3`
      })
      .join('\r\n')
    console.log(mess)
    navigator.clipboard.writeText(mess)
    notification.success({ message: 'Đã sao chép vào bộ nhớ tạm' })
  }
  useEffect(() => {
    getCustomerList()
    getPartnerList()
  }, [])

  useEffect(() => {
    setData({ ...data, created_at: moment().format('YYYY-MM-DD HH:mm:ss') })
  }, [])

  useEffect(() => {
    if (history.location.state && history.location.state.initData) {
      setData({
        ...data,
        customer_code: history.location.state.initData[0]?.customer_code,
        data: history.location.state.initData,
      })
    }
  }, [])

  useEffect(() => {
    if (data) {
      setSelectedCustomerDebit(data.is_customer_debit)
    }
    if (data.data) {
      var sumVolume = 0
      var sumMass = 0
      var totalExportPackage = 0
      var totalExportRequirePackage = 0
      var totalSumCost = 0
      data.data.map((order) => {
        console.log(order)
        sumVolume += order.volume || 0
        sumMass += order.mass || 0
        totalExportPackage += order.amount_package_exported || 0
        totalExportRequirePackage += order.total_quantity_package || 0
        totalSumCost += order.sum_cost
      })
      setTotalSumCost(totalSumCost)
      setSumMass(sumMass)
      setSumVolume(sumVolume)
      setTotalExport(totalExportPackage)
      setTotalRequireExport(totalExportRequirePackage)
    }
  }, [data])

  return (
    <>
      <Row>
        <Col span={12}>
          <Row style={{ margin: '7px 0' }}>
            <Col span={18}>
              <DatePicker
                size="large"
                showTime
                style={{ width: '100%', borderRadius: 8 }}
                placeholder="Chọn ngày xuất kho"
                locale={locale}
                defaultValue={moment()}
                onChange={(date, dateString) => {
                  let tmp = { ...data }
                  tmp.created_at = dateString
                  setData(tmp)
                }}
              />
            </Col>
          </Row>
          <Row style={{ margin: '7px 0' }}>
            <Col span={18} className={styles['chooseCustomer']}>
              <Select
                style={{ width: '100%', borderRadius: 8 }}
                placeholder="Chọn khách hàng"
                size="large"
                showSearch
                allowClear
                value={data.customer_code}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(e) => {
                  let tmp = { ...data }
                  tmp.customer_code = e
                  searchByCustomer(e)
                  setData(tmp)
                }}>
                {customerList.map((e) => (
                  <Select.Option value={e.customer_code}>
                    {e.name + ' - ' + e.customer_code}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={16}>
            <Col span={6}>
              <div
                style={{
                  marginTop: 15,
                  border: 'solid 2px #d9d9d9',
                  borderRadius: 15,
                  padding: 15,
                }}>
                <Row>Tổng kiện xuất/Tổng kiện cần xuất</Row>
                <span style={{ fontWeight: 600, fontSize: 16 }}>
                  {totalExport + '/' + totalRequireExport}
                </span>
              </div>
            </Col>
            <Col span={6}>
              <div
                style={{
                  marginTop: 15,
                  border: 'solid 2px #d9d9d9',
                  borderRadius: 15,
                  padding: 15,
                }}>
                <Row>Tổng trọng lượng</Row>
                <span style={{ fontWeight: 600, fontSize: 16 }}>
                  {formatNumber(Number(parseFloat(sumMass).toFixed(3)))} kg
                </span>
              </div>
            </Col>
            <Col span={6}>
              <div
                style={{
                  marginTop: 15,
                  border: 'solid 2px #d9d9d9',
                  borderRadius: 15,
                  padding: 15,
                }}>
                <Row>Tổng khối lượng</Row>
                <span style={{ fontWeight: 600, fontSize: 16 }}>
                  {formatNumber(Number(parseFloat(sumVolume).toFixed(3)))} m
                  <sup>3</sup>
                </span>
              </div>
            </Col>
            <Col span={6}>
              <div
                style={{
                  marginTop: 15,
                  border: 'solid 2px #d9d9d9',
                  borderRadius: 15,
                  padding: 15,
                }}>
                <Row>Tổng cước</Row>
                <span style={{ fontWeight: 600, fontSize: 16 }}>
                  {formatNumber(totalSumCost)}
                </span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ margin: '7px 0' }}>
        <Button
          size="large"
          type="primary"
          style={{ borderRadius: 5 }}
          icon={<MessageOutlined />}
          onClick={copyMessage}>
          Copy hóa đơn
        </Button>
      </Row>

      <Table columns={columns} size="small" dataSource={data.data} />
      <div style={{ borderTop: 'solid 1px', margin: '10px 0' }}></div>

      {/* <Divider style={{ backgroundColor: 'rgb(0 0 5 / 20%)', height: 2 }} /> */}
      <Row justify="space-between">
        <Col span={6}>
          <div>{'Vận chuyển'}</div>
          <Select
            placeholder="Vận chuyển nội địa"
            style={{ width: '100%' }}
            onChange={(e) => {
              let tmp = { ...data }
              tmp.shipping_partner_name = e
              setData(tmp)
            }}>
            {partnerList.map((e) => (
              <Select.Option value={e.name}>{e.name}</Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={14}>
          <Row style={{ marginBottom: 7 }}>
            <Input
              placeholder="Ghi chú"
              onChange={(e) => {
                let tmp = { ...data }
                tmp.note = e.target.value
                setData(tmp)
              }}
            />
          </Row>
          <Row style={{ marginBottom: 7 }} gutter={20}>
            <Col span={12}>
              <InputNumber
                style={{ width: '100%' }}
                placeholder="COD"
                min={0}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                onChange={(e) => {
                  let tmp = { ...data }
                  tmp.cod = e
                  setData(tmp)
                }}
              />
            </Col>
            <Col span={12}>
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Cước xuất kho"
                min={0}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                onChange={(e) => {
                  let tmp = { ...data }
                  tmp.cost_export = e
                  setData(tmp)
                }}
              />
            </Col>
          </Row>
          {/* <Row style={{ marginBottom: 7 }} justify="end" gutter={20}>
            <Checkbox
              value={selectedCustomerDebit}
              onChange={(e) => {
                setData({ ...data, is_customer_debit: e.target.checked })
              }}
            />

            <Tooltip
              title={`+ TH khách hàng đã ứng: cước xuất kho sẽ là công nợ phải trả cho đơn vị vận chuyển nội địa
              
              
              + TH khách hàng chưa ứng: cước xuất kho sẽ là công nợ phải trả cho đơn vị vận chuyển nội địa và là công nợ phải thu của khách hàng`}>
              <div style={{ marginLeft: 10 }}>{'Khách hàng đã tạm ứng'}</div>
            </Tooltip>
          </Row> */}

          {/* <Row style={{ marginBottom: 7 }} justify="end">
            <Col>
              <div>{'Bấm tạo xuất kho sẽ thực hiện tạo tự động: '}</div>

              <Tooltip
                title={
                  'Công nợ phải thu V/C nội địa = Cước xuất kho + tiền thu hộ'
                }>
                <div style={{ cursor: 'pointer' }}>
                  {'+ Công nợ phải thu V/C nội địa'}
                </div>
              </Tooltip>

              <Tooltip
                title={
                  'Công nợ phải thu khách hàng = Cước xuất kho (Nếu khách hàng đã tạm ứng thì không tạo khoản thu này)'
                }>
                <div style={{ cursor: 'pointer' }}>
                  {'+ Công nợ phải thu khách hàng'}
                </div>
              </Tooltip>

              <Tooltip
                title={'Công nợ phải chi cho khách hàng = Tổng tiền thu hộ'}>
                <div style={{ cursor: 'pointer' }}>
                  {'+ Công nợ phải chi cho khách hàng'}
                </div>
              </Tooltip>
            </Col>
          </Row> */}
        </Col>
      </Row>
      <Modal
        title="Các hóa đơn trong ngày"
        visible={showPickOrder}
        onCancel={() => setShowPickOrder(false)}
        onOk={onAddOrder}
        width={1000}>
        <Table
          rowKey="id"
          rowSelection={rowSelection}
          columns={columnOrder}
          scroll={{ x: 'max-content' }}
          dataSource={listsSearchByDate}
        />
      </Modal>
    </>
  )
}
