import React, { useContext, useEffect, useState } from 'react'

//component antd
import { Form, Input, Select, Row, Modal, Button, notification } from 'antd'

import { translate } from 'utils/i18n'
import { REG_PASSWORD, ACTION } from 'consts'
import Context from '../../utils/Context'

//component

//api
import { getRoles } from 'apis/role'
import { register } from 'apis/auth'
import { updateUser } from 'apis/users'
import { getWarehouses } from 'apis/warehouses'

export default ({ getLoadUsers, user, children }) => {
  const context = useContext(Context)
  const [forms] = Form.useForm()

  const [loadingRole, setLoadingRole] = useState(false)
  const [loadingWarehouse, setLoadingWarehouse] = useState(false)

  const [roles, setRoles] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [visible, setVisible] = useState(false)

  const _toggleModal = () => setVisible(!visible)

  const _getWarehouses = async () => {
    try {
      setLoadingWarehouse(true)
      const res = await getWarehouses()
      setLoadingWarehouse(false)
      if (res.status === 200) setWarehouses(res.data.data)
    } catch (error) {
      setLoadingWarehouse(false)
      console.log(error)
    }
  }

  const _getRoles = async () => {
    try {
      setLoadingRole(true)
      const res = await getRoles()
      setLoadingRole(false)
      if (res.status === 200) setRoles(res.data.data)
    } catch (error) {
      console.log(error)
      setLoadingRole(false)
    }
  }

  const onCreateOrEditUser = async () => {
    try {
      await forms.validateFields()

      context.dispatch({ name: ACTION.LOADING, data: true })

      let body = forms.getFieldsValue()

      delete body.confirmPassword

      let res

      if (user) {
        delete body.password
        res = await updateUser(user.id, body)
      } else res = await register(body)
      if (res.status === 200 && res.data.success) {
        notification.success({
          message: translate('Success'),
        })

        if (getLoadUsers) getLoadUsers()
        setVisible(false)
      } else {
        notification.error({ message: translate('Failed') })
      }

      context.dispatch({ name: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      context.dispatch({ name: ACTION.LOADING, data: false })
    }
  }

  //Reset các field mỗi khi show modal
  useEffect(() => {
    if (!visible) forms.resetFields()
    else {
      _getRoles()
      _getWarehouses()
      if (user) forms.setFieldsValue({ ...user })
    }
  }, [visible])

  return (
    <>
      {children ? (
        <div onClick={_toggleModal}>{children}</div>
      ) : (
        <Button
          type="primary"
          onClick={_toggleModal}
          className="primary-button">
          {'Tạo nhân viên'}
        </Button>
      )}

      <Modal
        width={650}
        onCancel={_toggleModal}
        title={user ? translate('Edit User') : translate('Create User')}
        visible={visible}
        okText={user ? translate('Save') : translate('Ok')}
        onOk={onCreateOrEditUser}
        centered>
        <Form layout="vertical" form={forms}>
          <Row justify="space-between">
            <Form.Item
              initialValue=""
              name="tel"
              label={'Tên đăng nhâp (Số điện thoại)'}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập số điện thoại',
                },
              ]}>
              <Input style={{ width: 260 }} />
            </Form.Item>

            <Form.Item
              style={{ width: 260 }}
              name="password"
              label={translate('Password')}
              rules={[
                {
                  required: true,
                  message: translate(
                    'Please input {value}',
                    translate('Password')
                  ),
                },
                // {
                //   pattern: !user && REG_PASSWORD,
                //   message: translate(
                //     'Passwords must be at least 8 characters long, 1 uppercase character, 1 lowercase character and 1 digit'
                //   ),
                // },
              ]}>
              <Input.Password
                style={{ width: 260 }}
                disabled={user ? true : false}
              />
            </Form.Item>

            <Form.Item
              style={{ display: user && 'none' }}
              name="confirmPassword"
              label={translate('Confirm Password')}
              rules={[
                {
                  required: user ? false : true,
                  message: translate(
                    'Please input {value}',
                    translate('Confirm Password')
                  ),
                },
              ]}>
              <Input.Password style={{ width: 260 }} />
            </Form.Item>

            <Form.Item
              name="role_id"
              label={translate('Role')}
              rules={[
                {
                  required: true,
                  message: translate('Please input {value}', translate('Role')),
                },
              ]}>
              <Select
                allowClear
                showSearch
                loading={loadingRole}
                style={{ width: 260 }}>
                {roles.map((role, index) => (
                  <Select.Option key={index} value={role?.id ?? index}>
                    {role?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Vui lòng điền tên',
                },
              ]}
              name="name"
              label={translate('Name')}>
              <Input style={{ width: 260 }} />
            </Form.Item>

            <Form.Item
              name="warehouse_id"
              label={translate('Warehouse')}
              rules={[
                {
                  required: true,
                  message: translate(
                    'Please input {value}',
                    translate('Warehouse')
                  ),
                },
              ]}>
              <Select
                allowClear
                showSearch
                loading={loadingWarehouse}
                style={{ width: 260 }}>
                {warehouses.map((warehouse, index) => (
                  <Select.Option key={index} value={warehouse?.id ?? index}>
                    {warehouse?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              initialValue=""
              name="address"
              label={translate('Address')}>
              <Input style={{ width: 260 }} />
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    </>
  )
}
