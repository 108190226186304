import React, { useEffect, useState } from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Row, Col, Button, notification, Form, Modal } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import { ROUTES } from 'consts'
import WarehouseExportForm from 'components/WarehouseForm/CreateExport'
import { createCardOut, importCardOut } from 'apis/card'

export default function CreateWarehouseExport() {
  const history = useHistory()
  const [exportData, setExportData] = useState({
    type: 'export',
    data: [],
  })
  const [showConfirm, setShowConfirm] = useState({
    visible: false,
    type: true,
  })

  const create = async (is_activate) => {
    var body = {
      ...exportData,
      type: 'export',
      is_activate,
      data: exportData.data.map((e) => {
        return {
          code_bill: e.code_bill,
          num: e.num || e.amount_package_export,
        }
      }),
    }
    if (!body.cost_import) body.cost_import = 0

    if (!body.sub_fee_import) body.sub_fee_import = 0

    // if (body.cost_export % 1000 !== 0) {
    //   notification.warning({ message: 'Vui lòng nhập cước xuất kho hợp lệ' })
    //   setShowConfirm({ ...showConfirm, visible: false })
    //   return
    // }

    // if (body.sub_cost % 1000 !== 0) {
    //   notification.warning({ message: 'Vui lòng nhập phụ phí khác hợp lệ' })
    //   setShowConfirm({ ...showConfirm, visible: false })
    //   return
    // }

    // if (body.cod % 1000 !== 0) {
    //   notification.warning({ message: 'Vui lòng nhập cod hợp lệ' })
    //   setShowConfirm({ ...showConfirm, visible: false })
    //   return
    // }

    try {
      const res = await importCardOut({ data: [body] })
      if (res.status === 200 && res.data.success) {
        notification.success({ message: 'Thành công' })
        history.push({
          pathname: ROUTES.WAREHOUSES,
          state: {
            warehouseActiveTab: history.location?.state?.warehouseActiveTab,
          },
        })
      } else {
        notification.error({
          message: 'Thất bại',
          description: res.data ? res.data.mess : '',
        })
      }
    } catch (err) {
      console.log(err)
      notification.error({
        message: 'Thất bại',
        description: err.data ? err.data.mess : '',
      })
    }
  }

  const onCreateCardOut = async (is_activate) => {
    if (is_activate) {
      if (!exportData.customer_code) {
        notification.warning({ message: 'Vui lòng thêm khách hàng' })
        return
      }
      if (!exportData.data.length) {
        notification.warning({ message: 'Vui lòng thêm đơn hàng' })
        return
      }
      if (!exportData.shipping_partner_name) {
        notification.warning({ message: 'Vui lòng thêm đơn vị vận chuyển' })
        return
      }
      if (!exportData.cod && exportData.cod != 0) {
        notification.warning({ message: 'Vui lòng điền COD' })
        return
      }
      if (!exportData.cost_export && exportData.cost_export != 0) {
        notification.warning({ message: 'Vui lòng nhập cước nhập kho' })
        return
      }
    }
    create(is_activate)
  }
  return (
    <div
      style={{
        padding: '1em',
        backgroundColor: '#fff',
      }}>
      <Row
        align="middle"
        style={{
          fontSize: 18,
          padding: '0.75em 0',
          fontWeight: 600,
          borderBottom: '1px solid',
        }}>
        <Col>
          <Link to={ROUTES.WAREHOUSES}>
            <ArrowLeftOutlined style={{ marginRight: 8, color: '#000' }} />
          </Link>
        </Col>
        <Col>Tạo phiếu xuất kho</Col>
      </Row>
      <WarehouseExportForm data={exportData} setData={setExportData} />
      <Row style={{ margin: '1rem 0' }} justify="end" gutter={20}>
        <Col>
          <Button
            disabled={!exportData.data.length}
            type="primary"
            style={{
              width: 120,
              borderRadius: 5,
              backgroundColor: '#bdc3c7',
              border: 'none',
            }}
            size="large"
            onClick={() => setShowConfirm({ visible: true, type: false })}>
            Lưu nháp
          </Button>
        </Col>
        <Button
          disabled={!exportData.data.length}
          type="primary"
          size="large"
          style={{ width: 120, borderRadius: 5 }}
          onClick={() => setShowConfirm({ visible: true, type: true })}>
          Tạo
        </Button>
      </Row>
      <Modal
        okText="Đồng ý"
        cancelText="Hủy"
        title="Xác nhận tạo phiếu"
        visible={showConfirm.visible}
        onCancel={() => setShowConfirm({ visible: false, type: true })}
        onOk={() => onCreateCardOut(showConfirm.type)}>
        <h2>Xác nhận {showConfirm.type ? 'tạo' : 'lưu'} phiếu xuất kho</h2>
      </Modal>
    </div>
  )
}
