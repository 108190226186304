import React, { useEffect, useState } from 'react'
import locale from 'antd/es/date-picker/locale/vi_VN'
import {
  Row,
  Col,
  Divider,
  Input,
  InputNumber,
  Select,
  DatePicker,
  Button,
  notification,
  Table,
  Modal,
} from 'antd'
import { getShipping } from 'apis/shipping'
import { getUsers } from 'apis/users'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { orderScan, getOrderByDate } from 'apis/order'
import moment from 'moment-timezone'
import { formatNumber } from 'utils'
import { translate } from 'utils/i18n'

export default function WarehouseUpdateImportForm({
  formkey,
  data,
  allData,
  setData,
}) {
  const [partnerList, setPartnerList] = useState([])
  const [billCodeSearch, setBillCodeSearch] = useState('')
  const [listsSearchByDate, setListSearchByDate] = useState([])
  const [showPickOrder, setShowPickOrder] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [customerInfo, setCustomerInfo] = useState()
  const [sumVolume, setSumVolume] = useState(0)
  const [sumWeight, setSumWeight] = useState(0)
  const [sumOrigin, setSumOrigin] = useState(0)
  const [totalPackage, setTotalPackage] = useState(0)
  const [totalRequirePackage, setTotalRequirePackage] = useState(0)

  const getPartnerList = async () => {
    try {
      const res = await getShipping({ area: 'Inland' })
      if (res.data.success) {
        setPartnerList(res.data.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getCustomerInfo = async (customer_code) => {
    try {
      const res = await getUsers({ role_id: 7, customer_code: customer_code })
      if (res.data.data) {
        if (res.data.data?.length > 0) setCustomerInfo(res.data.data[0])
      }
    } catch (err) {
      console.log(err)
    }
  }

  const searchBill = async () => {
    try {
      const res = await orderScan({ code: billCodeSearch })
      if (res.status == 404) {
        notification.warning({ message: 'Không tìm thấy đơn hàng' })
        return
      }

      if (res.status == 500) {
        notification.error({ message: 'Hệ thống đang xẩy ra sự cố!' })
        return
      }

      if (res.status == 200) {
        if (res.data.success) {
          // Trong 1 phiếu không được có 2 khách hàng
          // if (res.data.data?.length > 0 && customerInfo) {
          //   if (res.data.data[0].customer_code != customerInfo.customer_code) {
          //     notification.warning({
          //       message: `Đơn hàng này không phải của khách hàng ${customerInfo.name}, vui lòng chỉ nhập/xuất kho từ 1 khách hàng`,
          //     })
          //     return
          //   }
          // }

          if (!data.orders.find((e) => e.id === res.data.data.id)) {
            let tmp = [...allData]
            tmp[formkey] = {
              ...tmp[formkey],
              orders: [...tmp[formkey].orders, res.data.data],
            }
            setData(tmp)
          } else
            notification.warning({ message: 'Đơn hàng hàng này đã được thêm' })
        } else notification.warning({ message: 'Không tìm thấy đơn hàng' })
      }
    } catch (err) {
      console.log(err)
    }
  }
  const searchBillByDate = async (params) => {
    try {
      const res = await getOrderByDate(params)
      if (res.data.success) {
        setListSearchByDate(
          res.data.data.filter((e) => e.order_status == 'StackCar')
        )
        setShowPickOrder(true)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const removeBill = (id) => {
    let tmp = [...data.orders]
    tmp.splice(
      data.orders
        .map((e) => {
          return e.id
        })
        .indexOf(id),
      1
    )
    let tmp2 = [...allData]
    tmp2[formkey].orders = tmp
    setData(tmp2)
  }
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys)
  }
  const onAddOrder = () => {
    let tmp = listsSearchByDate.filter(
      (e) => selectedRowKeys.indexOf(e.id) != -1
    )
    tmp = tmp.filter(
      (e) =>
        data.data
          .map((f) => {
            return f.id
          })
          .indexOf(e.id) == -1
    )
    setData((e) => {
      return { ...e, data: [...e.data, ...tmp] }
    })
    setShowPickOrder(false)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const columnOrder = [
    {
      title: 'STT',
      width: 40,
      key: 0,
    },
    {
      title: 'Kho',
      key: 22,
      width: 90,
      dataIndex: 'warehouse',
    },
    {
      title: 'Ngày ký nhận',
      key: 1,
      width: 90,
      dataIndex: 'date_sign',
    },

    {
      title: translate('Bill number'),
      dataIndex: 'code_bill',
      key: 2,
      width: 90,
    },
    {
      title: translate('Amount package'),
      dataIndex: 'total_quantity_package',
      key: 3,
      width: 90,
    },
    {
      title: translate('Amount package imported'),
      dataIndex: 'amount_package_imported',
      key: 201,
      width: 90,
    },
    {
      title: translate('Amount package exported'),
      dataIndex: 'amount_package_exported',
      key: 202,
      width: 90,
    },
    {
      title: translate('Waybill number'),
      dataIndex: 'tracking_number',
      key: 4,
      width: 200,
    },
    {
      title: translate('Customer id'),
      dataIndex: 'user',
      width: 100,
      key: 5,
    },

    {
      title: translate('Payment help'),
      dataIndex: 'cod',
      width: 100,
      key: 6,
    },
    {
      title: translate('Chi phí Đóng gỗ/ Đóng tải'),
      dataIndex: 'fee_bag',
      key: 7,
      width: 100,
    },
    {
      title: 'Phí nâng hàng',
      dataIndex: 'fee_package',
      width: 100,

      key: 8,
    },
    // {
    //   title: translate('Surcharge'),
    //   dataIndex: 'sub_cost',
    //   key: 9,
    // },
    {
      title: translate('Mass'),
      dataIndex: 'mass',
      width: 100,
      key: 10,
    },
    {
      title: 'Phụ phí trọng lượng (kg)',
      dataIndex: 'sub_fee_kg',
      width: 100,
      key: 21,
    },
    {
      title: translate('Volume'),
      dataIndex: 'volume',
      width: 100,
      key: 11,
    },
    {
      title: 'Phụ phí khối lượng (m3)',
      dataIndex: 'sub_fee_m3',
      width: 100,
      key: 20,
    },
    {
      title: translate('Unit price'),
      dataIndex: 'unit_price',
      width: 100,
      key: 12,
    },
    {
      title: translate('Total charge'),
      dataIndex: 'sum_cost',
      width: 100,
      key: 13,
    },
    {
      title: translate('Origin cost'),
      dataIndex: 'cost_origin',
      width: 100,
      key: 14,
    },
    {
      title: translate('Order status'),
      dataIndex: 'order_status',
      width: 100,
      key: 16,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 100,
      key: 17,
    },
    {
      title: 'Cập nhật lần cuối',
      dataIndex: 'updated_at',
      width: 100,
      key: 18,
    },
    {
      title: 'Mô tả hàng hóa',
      dataIndex: 'description',
      width: 100,
      key: 19,
    },
    {
      title: 'Hành động',
      width: 120,
      key: 'action',
    },
  ]

  useEffect(() => {
    getPartnerList()
  }, [])
  useEffect(() => {
    if (data.orders?.length > 0) {
      getCustomerInfo(data.orders[0].customer_code)
      onUpdateSummary(data.orders)
    }
  }, [data])

  const onUpdateSummary = (data) => {
    var _sumVolume = 0
    var _sumWeight = 0
    var _sumOrigin = 0
    var _total_package = 0
    var _total_require_package = 0
    data.map((bill) => {
      _sumVolume += bill.volume
      _sumWeight += bill.mass
      _sumOrigin += bill.cost_origin
      _total_package += bill.amount_package_imported
      _total_require_package += bill.total_quantity_package
    })
    setSumVolume(_sumVolume)
    setSumWeight(_sumWeight)
    setSumOrigin(_sumOrigin)
    setTotalPackage(_total_package)
    setTotalRequirePackage(_total_require_package)
  }

  return (
    <>
      <Row style={{ margin: '7px 0' }}>
        <Col span={8}>
          <Row>
            <div>{'Ngày nhập kho'}</div>
          </Row>
          <DatePicker
            locale={locale}
            allowClear={false}
            size="large"
            disabled={data.is_activate}
            showTime
            style={{ width: '100%', borderRadius: 8 }}
            value={moment(
              new Date(String(data.created_at).replace('.000Z', ''))
            )}
            placeholder="Chọn ngày nhập kho"
            onChange={(date, dateString) => {
              setData((list) => {
                const listUpdate = [...list]
                const indexUpdate = listUpdate.findIndex(
                  (item) => item.code === data.code
                )
                if (indexUpdate !== -1)
                  listUpdate[indexUpdate].created_at = dateString
                return listUpdate
              })
            }}
          />
        </Col>

        <Col span={14} style={{ marginLeft: 10 }}>
          <Row gutter={6}>
            <Col span={6}>
              <div
                style={{
                  marginTop: 15,
                  border: 'solid 2px #d9d9d9',
                  borderRadius: 15,
                  padding: 15,
                }}>
                <Row>Đang nhập/ Tổng kiện</Row>
                <span style={{ fontWeight: 600, fontSize: 16 }}>
                  {totalPackage + '/' + totalRequirePackage}
                </span>
              </div>
            </Col>
            <Col span={6}>
              <div
                style={{
                  marginTop: 15,
                  border: 'solid 2px #d9d9d9',
                  borderRadius: 15,
                  padding: 15,
                }}>
                <Row>Tổng trọng lượng</Row>
                <span style={{ fontWeight: 600, fontSize: 16 }}>
                  {formatNumber(Number(parseFloat(sumWeight).toFixed(3)))} kg
                </span>
              </div>
            </Col>
            <Col span={6}>
              <div
                style={{
                  marginTop: 15,
                  border: 'solid 2px #d9d9d9',
                  borderRadius: 15,
                  padding: 15,
                }}>
                <Row>Tổng khối lượng</Row>
                <span style={{ fontWeight: 600, fontSize: 16 }}>
                  {formatNumber(Number(parseFloat(sumVolume).toFixed(3)))} m
                  <sup>3</sup>
                </span>
              </div>
            </Col>

            <Col span={6}>
              <div
                style={{
                  marginTop: 15,
                  border: 'solid 2px #d9d9d9',
                  borderRadius: 15,
                  padding: 15,
                }}>
                <Row>Tổng giá vốn</Row>
                <span style={{ fontWeight: 600, fontSize: 16 }}>
                  {formatNumber(sumOrigin)} <sup>VNĐ</sup>
                </span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {!data.is_activate && (
        <Row style={{ margin: '7px 0' }}>
          <Col span={8}>
            <Input
              onPressEnter={searchBill}
              placeholder="Nhập mã Bill"
              size="large"
              style={{ borderRadius: 8 }}
              onChange={(e) => setBillCodeSearch(e.target.value)}
            />
          </Col>
          <Col span={2}>
            <Button
              type="ghost"
              size="large"
              style={{ border: 'none', fontSize: 18 }}
              onClick={searchBill}>
              <PlusOutlined />
            </Button>
          </Col>
        </Row>
      )}
      {/* {customerInfo && (
        <Col>
          <Row>
            <div
              style={{
                fontSize: 20,
                fontWeight: 'bold',
              }}>{`Thông tin khách hàng:`}</div>
          </Row>
          <Row>
            <div>{`Mã khách hàng: ${customerInfo.customer_code}`}</div>
            <div
              style={{
                marginLeft: 20,
              }}>{`Tên khách hàng: ${customerInfo.name}`}</div>
          </Row>
          <Row></Row>
        </Col>
      )} */}
      {data.orders &&
        data.orders.map((e, index) => (
          <Row style={{ margin: '7px 0' }}>
            <Col span={24}>
              <Row
                style={{
                  width: '100%',
                  border: 'solid 1px',
                  borderRadius: 5,
                  padding: 5,
                }}
                align="middle"
                justify="space-between">
                <Col span={4}>{e.code_bill}</Col>
                <Col span={3}>{`Trọng lượng: ${e.mass}`}</Col>
                <Col span={3}>{`Khối lượng: ${e.volume}`}</Col>
                <Col span={4}>{`Giá vốn: ${formatNumber(e.cost_origin)}`}</Col>

                <Col span={10}>
                  <Row align="middle">
                    <Col span={12}>
                      <InputNumber
                        placeholder="Số kiện"
                        style={{ width: '100%' }}
                        value={e.amount_package_imported}
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={(e) => {
                          let tmp = [...allData]
                          tmp[formkey].orders[index].amount_package_imported = e
                          setData(tmp)
                        }}
                      />
                    </Col>
                    <Col span={9}>/{e.total_quantity_package}</Col>
                    {!data.is_activate && (
                      <Col span={3}>
                        <CloseOutlined onClick={() => removeBill(e.id)} />
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        ))}

      <Divider style={{ backgroundColor: 'rgb(0 0 5 / 20%)', height: 2 }} />
      <Row justify="space-between">
        <Col span={6}>
          <Select
            placeholder="Vận chuyển"
            style={{ width: '100%' }}
            value={data.id_shipping || undefined}
            onChange={(e) => {
              let tmp = [...allData]
              tmp[formkey].id_shipping = e
              const shippingFind = partnerList.find((s) => s.id === e)
              if (shippingFind) tmp[formkey].shipping_partner = shippingFind
              setData(tmp)
            }}>
            {partnerList.map((e) => (
              <Select.Option value={e.id}>{e.name}</Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={14}>
          <Row span={24} style={{ marginBottom: 7 }}>
            <Input
              value={data.note}
              placeholder="Ghi chú"
              defaultValue={data.note}
              onChange={(e) => {
                let tmp = [...allData]
                tmp[formkey].note = e.target.value
                setData(tmp)
              }}
            />
          </Row>
          <Row span={24} style={{ marginBottom: 7 }}>
            <Input
              placeholder="Biên lai ghi nhận thực tế(link liên kết hình ảnh google drive)"
              value={data.link_image}
              onChange={(e) => {
                let tmp = [...allData]
                tmp[formkey].link_image = e.target.value
                setData(tmp)
              }}
            />
          </Row>
          <Row style={{ marginBottom: 7 }} justify="end">
            <Col span={8}>
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Cước nhập kho"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                value={data.cost_import}
                onChange={(e) => {
                  let tmp = [...allData]
                  tmp[formkey].cost_import = e
                  setData(tmp)
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        title="Các hóa đơn trong ngày"
        visible={showPickOrder}
        onCancel={() => setShowPickOrder(false)}
        onOk={onAddOrder}
        width={1000}>
        <Table
          rowKey="id"
          rowSelection={rowSelection}
          columns={columnOrder}
          scroll={{ x: 'max-content' }}
          dataSource={listsSearchByDate}
        />
      </Modal>
    </>
  )
}
