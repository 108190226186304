import React, { useContext } from 'react'

import { translate } from 'utils/i18n'

export default [
  {
    title: 'STT',
    width: 60,
    key: 'stt',
  },
  {
    title: 'Xem chi tiết',
    key: 'view_detail',
  },
  {
    title: 'Mã đơn hàng',
    dataIndex: 'code_bill',
  },
  {
    title: 'Mã khách hàng',
    dataIndex: 'customer_code',
  },
  {
    title: translate('Waybill number'),
    dataIndex: 'tracking_number',
  },
  {
    title: 'Trạng thái cuối',
    dataIndex: 'order_status_text',
    width: 100,
  },
  {
    title: 'Cập nhật lần cuối',
    dataIndex: 'updated_at',
    width: 100,
    key: 5,
  },
  {
    title: 'Hành động',
    width: 100,
    key: 6,
  },
]
