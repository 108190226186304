import React, { useCallback } from 'react'
import Barcode from 'react-barcode'
import { Row } from 'antd'
import moment from 'moment'

const PrintQRCode = ({ record = {}, index, printType = '' }) => {
  const padTo2Digits = (num) => num.toString().padStart(2, '0')

  const createDate = record.created_at && new Date(record.created_at)
  const createDateFormat = createDate
    ? `${createDate.getFullYear()}/${padTo2Digits(
        createDate.getMonth() + 1
      )}/${createDate.getDate()}`
    : ''

  const BarcodeOrder = (props) => (
    <Barcode
      format="CODE39"
      height={50}
      displayValue={false}
      value={record.code_bill}
      {...props}
    />
  )
  return (
    <div style={{ width: '100%', height: '100%', padding: 10 }}>
      <Row wrap={false} style={{ width: '100%', height: '100%' }}>
        <div
          style={{
            width: '100%',
            marginRight: 10,
            textTransform: 'uppercase',
            fontWeight: 700,
            fontSize: printType === '75x130' ? '50px' : '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}>
          <div style={{ fontSize: printType === '75x130' ? '50px' : '20px' }}>
            <Row justify="space-between" wrap={false}>
              <div>
                [{index}/{record.total_quantity_package}]
              </div>
              <div>{record?.warehouse?.name}</div>
            </Row>
          </div>
          <div>
            <div
              style={{
                fontSize: printType === '75x130' ? '80px' : '40px',
                marginTop: 10,
                marginBottom: 6,
              }}>
              {record.code_bill}-{record.total_quantity_package}
            </div>
            <div
              style={{
                overflow: 'hidden',
                display: '-webkit-box',
                '-webkit-box-orient': 'vertical',
                '-webkit-line-clamp': '1',
              }}>
              {createDateFormat && (
                <span style={{ marginRight: 10 }}>{createDateFormat}</span>
              )}
              {record.description}
            </div>
            <div>{record.customer_code}</div>
          </div>
          <div style={{ width: '100%' }}>
            <BarcodeOrder width={printType === '75x130' ? 2.5 : 1.7} />
          </div>
        </div>
        {/* <div style={{ width: '20%', height: '100%' }}>
          <div style={{ transform: 'rotate(270deg)' }}>
            <BarcodeOrder width={1} />
          </div>
        </div> */}
      </Row>
    </div>
  )
}
class ComponentPrint extends React.Component {
  render() {
    const { records, printType } = this.props
    const order = records.length ? records[0] : {}
    let printingPages = []
    for (let i = 0; i < order.total_quantity_package; i++) {
      const tempTemplate = (
        <PrintQRCode printType={printType} record={order} index={i + 1} />
      )
      printingPages.push(tempTemplate)
    }
    return <div>{printingPages}</div>
  }
}

function ContentPrint({ records, componentRef, printType }) {
  return (
    <>
      <div style={{ display: 'none' }}>
        <ComponentPrint
          printType={printType}
          records={records}
          ref={componentRef}
        />
      </div>
    </>
  )
}

export default ContentPrint
