import React, { useState, useEffect } from 'react'

//antd
import {
  Button,
  Drawer,
  Row,
  Input,
  Space,
  Form,
  notification,
  Tree,
  Col,
  Checkbox,
  Select,
} from 'antd'
//consts
import { ACTION, PERMISSIONS } from 'consts'
//apis
import { createRole, updateRole } from 'apis/role'
import { getWarehouses } from 'apis/warehouses'
import treeData from './tree-data'

export default function RoleForm({ _getRoles, role, children }) {
  const [visible, setVisible] = useState(false)
  const [checkedKeys, setCheckedKeys] = useState([])
  const toggle = () => setVisible(!visible)
  const [loadingWarehouse, setLoadingWarehouse] = useState(false)

  const list = [...treeData]
  const half = Math.ceil(list.length / 2)
  const firstHalf = list.splice(0, half)
  const secondHalf = list.splice(-half)

  const [warehouses, setWarehouses] = useState([])
  const [form] = Form.useForm()

  const _createOrUpdateRole = async () => {
    try {
      if (checkedKeys.length >= 1) {
        await form.validateFields()
        const formData = form.getFieldsValue()
        const body = { ...formData, permissions: checkedKeys }

        let res
        if (role) res = await updateRole(body, role.id)
        else res = await createRole(body)

        if (res.status === 200) {
          if (res.data.success) {
            notification.success({
              message: `${role ? 'Cập nhật' : 'Thêm'} vai trò thành công!`,
            })
            form.resetFields()
            toggle()
            _getRoles()
          } else notification.error({ message: res.data.message })
        } else notification.error({ message: res.data.message })
      } else {
        notification.warning({
          message: 'Một vai trò phải có ít nhất một quyền!',
        })
      }
    } catch (error) {
      notification.error({ message: 'Thêm vai trò thất bại!' })
    }
  }

  const _getWarehouses = async () => {
    try {
      setLoadingWarehouse(true)
      const res = await getWarehouses()
      setLoadingWarehouse(false)
      if (res.status === 200) setWarehouses(res.data.data)
    } catch (error) {
      setLoadingWarehouse(false)
      console.log(error)
    }
  }

  useEffect(() => {
    if (visible) {
      _getWarehouses()

      if (role) {
        form.setFieldsValue({ ...role })
        setCheckedKeys(role.permissions || [])
      }
    }
  }, [visible])

  return (
    <>
      <div onClick={toggle}>{children}</div>

      <Drawer
        footer={
          <Row justify="end">
            <Space>
              <Button
                type="primary"
                danger
                style={{ minWidth: 100 }}
                onClick={toggle}>
                Hủy
              </Button>
              <Button
                type="primary"
                style={{ minWidth: 100, marginLeft: 10 }}
                onClick={_createOrUpdateRole}
                // disabled={checkedKeys.length >= 1 ? false : true}
              >
                {role ? 'Cập nhật' : 'Tạo'}
              </Button>
            </Space>
          </Row>
        }
        width="70%"
        title={`${role ? 'Cập nhật' : 'Tạo'} vai trò`}
        visible={visible}
        onClose={toggle}>
        <Form form={form} layout="vertical">
          <Row gutter={20} wrap={false} style={{ marginBottom: 15 }}>
            <Col span={12}>
              <Form.Item
                name="name"
                label={'Tên vai trò'}
                rules={[{ required: true, message: 'Vui lòng nhập tên' }]}>
                <Input placeholder={'Tên vai trò'} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                name="warehouse_id"
                label="Kho"
                rules={[{ required: true, message: 'Vui lòng chọn kho' }]}>
                <Select
                  placeholder="Chọn kho"
                  allowClear
                  showSearch
                  loading={loadingWarehouse}
                  style={{ width: '100%' }}>
                  {warehouses.map((warehouse, index) => (
                    <Select.Option key={index} value={warehouse?.id ?? index}>
                      {warehouse?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> */}
          </Row>

          <Row>
            <Col span={12}>
              <Tree
                checkable
                treeData={firstHalf}
                checkedKeys={checkedKeys}
                checkStrictly
                // defaultCheckedKeys={['thong_ke']}
                defaultExpandAll
                selectable={false}
                onCheck={(value, e) => {
                  //khi unchecked và ko còn checkbox nào checked
                  // if (!e.checked && checkedKeys.length === 0) {
                  //   notification.warning({
                  //     message: 'Một vai trò phải có ít nhất một quyền!',
                  //   })
                  //   return false
                  // }
                  setCheckedKeys(value.checked)
                  // console.log(value)
                }}
              />
            </Col>
            <Col span={12}>
              <Tree
                checkable
                treeData={secondHalf}
                checkedKeys={checkedKeys}
                checkStrictly
                defaultExpandAll
                selectable={false}
                onCheck={(value, e) => {
                  //khi unchecked và ko còn checkbox nào checked
                  // if (!e.checked && e.checkedNodes.length === 0) {
                  //   notification.warning({
                  //     message: 'Một vai trò phải có ít nhất một quyền!',
                  //   })
                  //   return false
                  // }
                  setCheckedKeys(value.checked)
                  // console.log(value)
                }}
              />
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  )
}
