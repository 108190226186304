import React, { useState, useEffect, useContext, useRef } from 'react'

//component antd
import { Table, notification, Row, Typography, Input, Popconfirm } from 'antd'

//icons antd
import { SearchOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'

import usersColumns from './usersColumns'
import Context from 'utils/Context'
import { ACTION } from 'consts'
import { translate } from 'utils/i18n'
import { PERMISSIONS } from 'consts/permissions'

//components
import ModalCreateUser from 'components/UserForm'
import Permission from 'components/Permission'

//apis
import { getUsers, deleteUser } from 'apis/users'
import { removeNull } from 'utils'
import { getRoles } from 'apis/roles'
export default () => {
  const context = useContext(Context)
  const typingTimeoutRef = useRef(null)

  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    pageSize: 300,
    is_employee: true,
  })
  const [data, setData] = useState([])
  const [roles, setRoles] = useState([])
  const [count, setCount] = useState(0)

  const onSearch = (e) => {
    const value = e.target.value
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      paramsFilter.page = 1

      if (!value) delete paramsFilter.search
      else paramsFilter.search = value

      getLoadUsers(paramsFilter)
      setParamsFilter(paramsFilter)
    }, 750)
  }

  const getLoadUsers = async (params) => {
    try {
      context.dispatch({ name: ACTION.LOADING, data: true })
      const res = await getUsers(params)
      if (res.data && res.status === 200 && res.data.success) {
        setData(res.data.data)
        setCount(res.data.count)
      } else
        notification.error({
          message: 'Có lỗi xảy ra, vui lòng thử lại',
          description: res.statusText,
        })
      context.dispatch({ name: ACTION.LOADING, data: false })
    } catch (error) {
      notification.error({
        message: 'Hệ thống đang khởi động, vui lòng thử lại',
      })
      console.log(error)
      context.dispatch({ name: ACTION.LOADING, data: false })
    }
  }

  const onDeleteUser = async (userId) => {
    try {
      context.dispatch({ name: ACTION.LOADING, data: true })
      const res = await deleteUser(userId)
      if (res.status === 200) {
        getLoadUsers(paramsFilter)
        notification.success({ message: translate('Delete user success') })
      } else
        notification.error({
          message: 'Error',
          description: translate('Delete user failed'),
        })
      context.dispatch({ name: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      context.dispatch({ name: ACTION.LOADING, data: false })
    }
  }

  const getAllRoles = async () => {
    try {
      const res = await getRoles()
      if (res.data.success) {
        setRoles(res.data.data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getAllRoles()
    getLoadUsers(paramsFilter)
  }, [])

  return (
    <>
      <Row justify="space-between">
        <Typography.Title level={4}>{'Nhân viên'}</Typography.Title>
        {/* create user */}
        <Permission permissions={[PERMISSIONS.tao_nhan_vien]}>
          <ModalCreateUser getLoadUsers={() => getLoadUsers(paramsFilter)} />
        </Permission>
      </Row>
      <br />
      <div>
        <div>Tìm kiếm theo tên nhân viên</div>
        <Input
          style={{ width: 500 }}
          prefix={<SearchOutlined style={{ color: '#bdc3c7' }} />}
          placeholder="Nhập tên nhân viên"
          allowClear
          onChange={onSearch}
        />
      </div>

      <br />
      <br />

      <Table
        style={{ width: '100%' }}
        columns={usersColumns.map((e) => {
          if (e.key === 'STT')
            return {
              ...e,
              render(data, record, index) {
                return (
                  (paramsFilter.page - 1) * paramsFilter.pageSize + index + 1
                )
              },
            }
          if (e.key === 'group_telegram_id')
            return {
              ...e,
              width: 100,
              render: (text, record) => <div>{record.group_telegram_id}</div>,
            }

          if (e.key === 'warehouse')
            return {
              ...e,
              width: 100,
              render: (text, record) => (
                <div>{record.warehouse_info?.name}</div>
              ),
            }

          if (e.key === 'action')
            return {
              ...e,
              width: 100,
              render: (text, record) => (
                <Row justify="center" align="middle" wrap={false}>
                  <Permission permissions={[PERMISSIONS.cap_nhat_nhan_vien]}>
                    <ModalCreateUser
                      getLoadUsers={() => getLoadUsers(paramsFilter)}
                      user={record}>
                      <EditOutlined
                        style={{
                          fontSize: 16,
                          color: '#1890FF',
                          cursor: 'pointer',
                        }}
                      />
                    </ModalCreateUser>
                  </Permission>
                  <Permission permissions={[PERMISSIONS.xoa_nhan_vien]}>
                    <Popconfirm
                      title={`${translate('Are you sure delete this user')} ?`}
                      okText="Ok"
                      cancelText="No"
                      onConfirm={() => onDeleteUser(record.id)}>
                      <DeleteOutlined
                        style={{
                          marginLeft: 10,
                          fontSize: 16,
                          color: 'orange',
                          cursor: 'pointer',
                        }}
                      />
                    </Popconfirm>
                  </Permission>
                </Row>
              ),
            }
          if (e.key === 'roleId')
            return {
              ...e,
              render(data) {
                return (
                  roles.find((role) => role.id == data) &&
                  roles.find((role) => role.id == data)['name']
                )
              },
            }

          return e
        })}
        dataSource={data}
        pagination={{
          current: paramsFilter.page,
          defaultPageSize: paramsFilter.pageSize,
          pageSizeOptions: [100, 200, 300, 400],
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            paramsFilter.page = page
            paramsFilter.pageSize = pageSize

            setParamsFilter(paramsFilter)
            getLoadUsers(paramsFilter)
          },
          total: count,
        }}
        size="small"
      />
    </>
  )
}
