import React, { useState, useContext, useEffect } from 'react'
import locale from 'antd/es/date-picker/locale/vi_VN'
import moment from 'moment'

//component antd
import {
  Modal,
  Button,
  Form,
  Input,
  notification,
  Select,
  DatePicker,
  Checkbox,
  Row,
} from 'antd'

//apis

import { translate } from 'utils/i18n'
import Context from 'utils/Context'
import { ACTION } from 'consts'
import { createCustomer, updateUser } from 'apis/users'
import { getPrice } from 'apis/price'

export default ({ getInitialCustomersReceiver, customer, children }) => {
  const [form] = Form.useForm()
  const context = useContext(Context)
  const dateFormat = 'DD/MM/YYYY'

  const [visible, setVisible] = useState(false)
  const [companies, setCompanies] = useState([])
  const [effortPriceList, setEffortPriceList] = useState([])

  const [useCompany, setUseCompany] = useState(false)
  const toggle = () => setVisible(!visible)

  const isValidateForm = async () => {
    try {
      await form.validateFields()
      return true
    } catch (error) {
      return false
    }
  }

  const onCreateOrEditCustomer = async () => {
    try {
      //check validate field trước khi submit
      const isValidate = await isValidateForm()
      if (!isValidate) return

      context.dispatch({ name: ACTION.LOADING, data: true })

      const body = { ...form.getFieldsValue() }
      console.log('body', body)
      //bỏ các field ko điền
      Object.keys(body).map((key) => {
        if (!body[key]) delete body[key]
      })

      let res
      if (customer) res = await updateUser(customer.id, body)
      else res = await createCustomer({ ...body, role_id: 7 })

      console.log(res)
      if (res.status === 200 && res.data.success) {
        //Get all customer again
        await getInitialCustomersReceiver()

        setVisible(false)

        notification.success({ message: 'Success' })
      } else {
        notification.error({ message: res.data?.mess })
      }
      context.dispatch({ name: ACTION.LOADING, data: false })
    } catch (error) {
      context.dispatch({ name: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const getEffortPrice = async () => {
    try {
      const res = await getPrice({ page: 1, pageSize: 10000 })
      if (res.data.success) {
        setEffortPriceList(res.data.data)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const layout = {
    labelCol: {
      span: 7,
    },

    wrapperCol: {
      span: 17,
      offset: 1,
    },
  }

  useEffect(() => {
    //reset all field khi open modal
    if (!visible) form.resetFields()
    else {
      if (customer)
        form.setFieldsValue({
          ...customer,
          time_expired_price: moment(customer.time_expired_price),
        })
    }
  }, [visible])

  // useEffect(() => {
  //   getInitialCompanies();
  // }, []);

  useEffect(() => {
    getEffortPrice()
  }, [])

  return (
    <>
      {children ? (
        <div onClick={toggle}>{children}</div>
      ) : (
        <Button type="primary" onClick={toggle} className="primary-button">
          {translate('Create Customer')}
        </Button>
      )}

      <Modal
        visible={visible}
        title={
          customer ? translate('Edit Customer') : translate('Create Customer')
        }
        cancelText={translate('Cancel')}
        okText={customer ? translate('Save') : 'OK'}
        onCancel={toggle}
        onOk={onCreateOrEditCustomer}
        width={600}
        centered>
        <Form
          {...layout}
          form={form}
          initialValues={{
            //Nếu là client thì điền sẵn company và disabled
            companyId: context.id == 3 && context.companyId,
          }}>
          <Form.Item
            label={'Group Telegram Id'}
            name="group_telegram_id"
            rules={[
              {
                message: 'Vui lòng nhập Group Telegram Id',
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item
            label={translate('Name')}
            name="name"
            rules={[
              {
                required: true,
                message: translate('Please input name'),
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label={translate('Phone')}
            name="tel"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập số điện thoại',
              },
              // {
              //   pattern:
              //     /^(0|84)(2(0[3-9]|1[0-6|8|9]|2[0-2|5-9]|3[2-9]|4[0-9]|5[1|2|4-9]|6[0-3|9]|7[0-7]|8[0-9]|9[0-4|6|7|9])|3[2-9]|5[5|6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])([0-9]{7})$/,
              //   message: 'Vui lòng nhập số điện thoại',
              // },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Mật khẩu"
            name="password"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập mật khẩu',
              },
            ]}>
            <Input.Password />
          </Form.Item>
          <Form.Item
            label={translate('Customer code')}
            name="customer_code"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập mã khách hàng',
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item name="effort_prices" label="Công thức tính giá">
            <Select
              mode="multiple"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              listHeight={230}>
              {effortPriceList.map((e) => (
                <Select.Option value={e.id}>{e.title}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={translate('Email')} name="email">
            <Input type="email" />
          </Form.Item>

          <Form.Item label={translate('Address')} name="address">
            <Input />
          </Form.Item>

          <Form.Item name="time_expired_price" label="Thời gian sử dụng CTTG">
            <DatePicker locale={locale} style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
