import React, { useState, useRef, useEffect } from 'react'
import { useReactToPrint } from 'react-to-print'
import delay from 'delay'

import ContentPrint from './content-print'
import { getWarehouses } from 'apis/warehouses'

export default function PrintOrder({ children, records = [], form }) {
  const componentRef = useRef()

  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)

  const [recordsPrint, setRecordsPrint] = useState([])
  const [printType, setPrintType] = useState('75x130')

  const print = async () => {
    const res = await getWarehouses({ page: 1, pageSize: 1000 })
    let type

    if (res.status === 200) {
      let recordsPrint
      if (form) {
        const dataPrint = form.getFieldsValue()
        recordsPrint = [dataPrint]
        const warehouse = res.data.data.find(
          (wh) => wh.id == dataPrint?.warehouse_id
        )
        if (warehouse) type = warehouse.formatprint
      } else {
        const warehouse = res.data.data.find(
          (wh) => wh.id == records[0]?.warehouse_id
        )
        if (warehouse) type = warehouse.formatprint
        recordsPrint = records
      }
      if (type) setPrintType(type)
      setRecordsPrint([...recordsPrint])

      await delay(100)

      _handlePrint()
      toggle()
    }
  }

  const _handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setRecordsPrint([]),
  })

  return (
    <>
      {recordsPrint.length ? (
        <ContentPrint
          printType={printType}
          records={recordsPrint}
          componentRef={componentRef}
        />
      ) : (
        ''
      )}

      <div onClick={print}>{children}</div>
    </>
  )
}
