import { getShipping } from 'apis/shipping'
import { orderScan } from 'apis/order'
import { notification } from 'antd'
import moment from 'moment'
export const orderConvertFields = (data, reverse = false) => {
  const template = {
    warehouse_code: 'MÃ KHO',
    date_sign: 'THỜI GIAN KÝ NHẬN',
    code_bill: ' MÃ BILL',
    customer_code: 'MÃ KHÁCH HÀNG',
    total_quantity_package: 'SL KIỆN',
    tracking_number: 'SỐ VẬN ĐƠN',
    description: 'MÔ TẢ SẢN PHẨM',
    cod: 'THANH TOÁN HỘ',
    fee_bag: 'ĐÓNG GỖ/ĐÓNG TẢI',
    fee_package: 'PHÍ NÂNG HÀNG',
    volume: 'KHỐI LƯỢNG',
    sub_fee_m3: 'PHỤ PHÍ KHỐI LƯỢNG',
    mass: 'TRỌNG LƯỢNG',
    sub_fee_kg: 'PHỤ PHÍ TRỌNG LƯỢNG',
    cost_origin: 'GIÁ VỐN',
  }
  if (reverse) {
    return Object.keys(template).reduce((a, b) => {
      if (b == 'customer_code') {
        a[template[b]] = data.customer_code
      } else a[template[b]] = data[b]
      return a
    }, {})
  }
  let success = true
  let tmp = Object.keys(template).reduce((a, b) => {
    if (b != 'sub_cost') {
      if (data[template[b]] === '') {
        success = false
        notification.error({ message: `${template[b]} không được để trống` })
      } else {
        a[b] = data[template[b]]
      }
    } else a[b] = data[template[b]]
    return a
  }, {})
  return success ? tmp : null
}

export const warehouseImport = async (data) => {
  let displayData = []
  let infoCard = {}
  let previousSuccess = true
  if (data.length > 0) {
    infoCard = {
      cost_import: 0,
      created_at: '',
      data: [],
      img: '',
      is_activate: true,
      note: '',
      shipping_partner_name: '',
      type: 'import',
    }
  }
  data.forEach((e) => {
    let dataTmp = { type: 'import', is_activate: true }
    let rowData = { type: 'import' }
    if (e['Ngày nhập kho']) {
      previousSuccess = true
      dataTmp.created_at = moment(e['Ngày nhập kho'])
        .add(40, 'seconds')
        .format('YYYY-MM-DD HH:mm:ss')
      infoCard.created_at = moment(e['Ngày nhập kho'])
        .add(40, 'seconds')
        .format('YYYY-MM-DD HH:mm:ss')
    }
    if (e['Tên đơn vị vận chuyển']) {
      // let partnerID =
      //   shipping_partner.data &&
      //   shipping_partner.data.data.find(
      //     (s) => s.name == e['Tên đơn vị vận chuyển']
      //   );
      // if (partnerID) {
      dataTmp.shipping_partner_name = e['Tên đơn vị vận chuyển']
      infoCard.shipping_partner_name = e['Tên đơn vị vận chuyển']
      // } else {
      //   is_success = false;
      //   notification.error({
      //     message: `Không tìm thấy đơn vị vận chuyển ${e['Tên đơn vị vận chuyển']}`,
      //   });
      // previousSuccess = false;
      // }
    }
    if (e['Ghi chú']) {
      dataTmp.note = e['Ghi chú']
      infoCard.note = e['Ghi chú']
    } else {
      infoCard.note = ''
    }
    if (e['Cước nhập kho']) {
      dataTmp.cost_import = e['Cước nhập kho']
      infoCard.cost_import = e['Cước nhập kho']
    } else {
      dataTmp.cost_import = 0
    }
    if (e['Biên lai ghi nhận thực tế']) {
      dataTmp.img = e['Biên lai ghi nhận thực tế']
      infoCard.img = e['Biên lai ghi nhận thực tế']
    } else {
      dataTmp.img = ''
    }

    if (e['Mã đơn hàng']) {
      rowData.code = e['Mã đơn hàng'] + ''
      rowData.code_bill = e['Mã đơn hàng'] + ''
    }
    if (e['Số lượng']) {
      rowData.num = e['Số lượng']
    }

    infoCard.data.push(rowData)
  })
  if (infoCard.data.length > 0) {
    infoCard.data[0].note = infoCard.note
    infoCard.data[0].shipping_partner_name = infoCard.shipping_partner_name
    infoCard.data[0].cost_import = infoCard.cost_import
    infoCard.data[0].created_at = infoCard.created_at
    infoCard.data[0].img = infoCard.img
  }
  return [infoCard, infoCard.data]
}

export const warehouseImport2 = async (data) => {
  let displayData = []
  let infoCard = {}
  let previousSuccess = true
  if (data.length > 0) {
    infoCard = {
      cost_import: 0,
      created_at: '',
      data: [],
      img: '',
      is_activate: true,
      note: '',
      shipping_partner_name: '',
      type: 'import',
    }
  }
  data.forEach((e) => {
    let dataTmp = { type: 'import', is_activate: true }
    let rowData = { type: 'import' }
    if (e['Ngày nhập kho']) {
      previousSuccess = true
      dataTmp.created_at = moment(e['Ngày nhập kho'])
        .add(40, 'seconds')
        .format('YYYY-MM-DD HH:mm:ss')
      infoCard.created_at = moment(e['Ngày nhập kho'])
        .add(40, 'seconds')
        .format('YYYY-MM-DD HH:mm:ss')
    }
    if (e['Tên đơn vị vận chuyển']) {
      // let partnerID =
      //   shipping_partner.data &&
      //   shipping_partner.data.data.find(
      //     (s) => s.name == e['Tên đơn vị vận chuyển']
      //   );
      // if (partnerID) {
      dataTmp.shipping_partner_name = e['Tên đơn vị vận chuyển']
      infoCard.shipping_partner_name = e['Tên đơn vị vận chuyển']
      // } else {
      //   is_success = false;
      //   notification.error({
      //     message: `Không tìm thấy đơn vị vận chuyển ${e['Tên đơn vị vận chuyển']}`,
      //   });
      // previousSuccess = false;
      // }
    }
    if (e['Ghi chú']) {
      dataTmp.note = e['Ghi chú']
      infoCard.note = e['Ghi chú']
    } else {
      infoCard.note = ''
    }
    if (e['Cước nhập kho']) {
      dataTmp.cost_import = e['Cước nhập kho']
      infoCard.cost_import = e['Cước nhập kho']
    } else {
      dataTmp.cost_import = 0
    }
    if (e['Biên lai ghi nhận thực tế']) {
      dataTmp.img = e['Biên lai ghi nhận thực tế']
      infoCard.img = e['Biên lai ghi nhận thực tế']
    } else {
      dataTmp.img = ''
    }

    // if (e['Mã đơn hàng']) {
    //   rowData.code = e['Mã đơn hàng'] + ''
    //   rowData.code_bill = e['Mã đơn hàng'] + ''
    // }
    // if (e['Số lượng']) {
    //   rowData.num = e['Số lượng']
    // }

    //infoCard.data.push(rowData)
  })
  infoCard.note = infoCard.note
  infoCard.shipping_partner_name = infoCard.shipping_partner_name
  infoCard.cost_import = infoCard.cost_import
  infoCard.created_at = infoCard.created_at
  infoCard.img = infoCard.img
  return infoCard
}

export const warehouseImportOrder2 = async (data) => {
  var result = []
  data.forEach((e) => {
    let item = {}
    if (e['Mã đơn hàng']) {
      item.code_bill = e['Mã đơn hàng']
    }
    if (e['Số lượng']) {
      item.num = Number(e['Số lượng'])
    }
    result.push(item)
  })

  return result
}

export const warehouseExportCardOut = async (data) => {
  let result = []
  let displayData = []
  let previousSuccess = true
  data.forEach((e) => {
    let is_success = true
    let dataTmp = { type: 'export', is_activate: true }
    let rowData = { type: 'export' }
    if (e['Mã khách hàng']) {
      previousSuccess = true

      dataTmp.customer_code = e['Mã khách hàng']
      rowData.customer_code = e['Mã khách hàng']
    }
    if (e['Ngày xuất kho']) {
      dataTmp.created_at = moment(e['Ngày xuất kho'])
        .add(40, 'seconds')
        .format('YYYY-MM-DD HH:mm:ss')
      rowData.created_at = moment(e['Ngày xuất kho'])
        .add(40, 'seconds')
        .format('YYYY-MM-DD HH:mm:ss')
    }
    if (e['Đơn vị vận chuyển']) {
      dataTmp.shipping_partner_name = e['Đơn vị vận chuyển']
      rowData.shipping_partner_name = e['Đơn vị vận chuyển']
    }
    if (e['Ghi chú']) {
      dataTmp.note = e['Ghi chú']
      rowData.note = e['Ghi chú']
    } else {
      dataTmp.note = ''
    }
    if (e['Cước xuất kho']) {
      dataTmp.cost_export = e['Cước xuất kho']
      rowData.cost_export = e['Cước xuất kho']
    } else {
      dataTmp.cost_export = 0
    }
    if (e['Thanh toán hộ(COD)']) {
      dataTmp.cod = e['Thanh toán hộ(COD)']
      rowData.cod = e['Thanh toán hộ(COD)']
    } else {
      dataTmp.cod = 0
    }

    if (e['Phụ phí']) {
      dataTmp.sub_cost = e['Phụ phí']
      rowData.sub_cost = e['Phụ phí']
    } else {
      dataTmp.sub_cost = 0
    }

    let bilData = {}
    if (e['Mã đơn hàng']) {
      bilData.code_bill = e['Mã đơn hàng']
      rowData.code = e['Mã đơn hàng']
    }
    if (e['Số kiện']) {
      bilData.num = e['Số kiện']
      rowData.num = e['Số kiện']
    }

    if (previousSuccess || is_success) {
      if (e['Mã khách hàng']) {
        dataTmp.data = []
        dataTmp.data.push(bilData)
        result.push(dataTmp)
        displayData.push(rowData)
      } else {
        result[result.length - 1] &&
          result[result.length - 1].data &&
          result[result.length - 1].data.push(bilData)
        displayData.push(rowData)
      }
    }
  })
  return [result, displayData]
}

export const warehouseExport = (data) => {
  let template = {
    code: 'MÃ NHẬP KHO',
    amount_import_package: 'TỔNG SL KIỆN',
    created_at: 'NGÀY',
    shipping_partner: 'VẬN CHUYỂN',
    cost_import: 'CƯỚC NHẬP KHO',
    is_activate: 'TRẠNG THÁI',
  }

  let tmp = Object.keys(template).reduce((a, b) => {
    if (b == 'created_at') {
      a[template[b]] = moment(data[b]).format('DD/MM/YYYY')
    } else if (b == 'shipping_partner') {
      a[template[b]] = data[b] ? data[b].name : ''
    } else if (b == 'is_activate') {
      a[template[b]] = data[b] ? 'Đã nhập' : 'Lưu nháp'
    } else a[template[b]] = data[b]
    return a
  }, {})

  return tmp
}

export const exportWarehouse = (data) => {
  let template = {
    code: 'MÃ XUẤT KHO',
    orders: 'TỔNG SL KIỆN',
    created_at: 'NGÀY',
    shipping_partner: 'VẬN CHUYỂN',
    cost_export: 'CƯỚC XUẤT KHO',
    is_activate: 'TRẠNG THÁI',
  }

  let tmp = Object.keys(template).reduce((a, b) => {
    if (b == 'created_at') {
      a[template[b]] = moment(data[b]).format('DD/MM/YYYY')
    } else if (b == 'orders') {
      a[template[b]] = data[b]
        ? data[b].reduce((a, b) => a + (b.amount_package_imported || 0), 0)
        : ''
    } else if (b == 'shipping_partner') {
      a[template[b]] = data[b] ? data[b].name : ''
    } else if (b == 'is_activate') {
      a[template[b]] = data[b] ? 'Đã xuất' : 'Lưu nháp'
    } else a[template[b]] = data[b]
    return a
  }, {})

  return tmp
}
