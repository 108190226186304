import React, { useContext } from 'react'
import './index.scss'

//component antd
import {
  Row,
  Input,
  Button,
  Checkbox,
  Form,
  Typography,
  notification,
} from 'antd'

//icon antd
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'

//utils
import Context from 'utils/Context'
import { translate, setLanguage } from 'utils/i18n'
import { ROUTES, ACTION } from 'consts'
import { decodeJWT } from 'utils'

//api
import { login } from 'apis/auth'
import { decodeToken } from 'react-jwt'
import { PERMISSIONS } from 'consts/permissions'

export default () => {
  const context = useContext(Context)
  const history = useHistory()
  const _onFinishInput = async (value) => {
    try {
      if (context.isLoading) {
        return
      }
      context.dispatch({ name: ACTION.LOADING, data: true })
      const response = await login(value)
      context.dispatch({ name: ACTION.LOADING, data: false })
      if (response.status === 200) {
        if (!response.data.accessToken)
          notification.error({
            message:
              response.data.mess || 'Đăng nhập thất bại, vui lòng thử lại!',
          })
        else {
          const dataUser = decodeToken(response.data.accessToken)
          context.dispatch({ name: ACTION.LOGIN, data: response.data })
          if (dataUser) {
            const permissionsMenu = [
              { path: ROUTES.HOME, value: PERMISSIONS.tra_cuu_don },
              { path: ROUTES.OVERVIEW, value: PERMISSIONS.tong_quan },
              { path: ROUTES.ORDERS, value: PERMISSIONS.xem_don_hang },
              { path: ROUTES.ITINERARY, value: PERMISSIONS.hanh_trinh },
              {
                path: ROUTES.WAREHOUSES,
                value: `${PERMISSIONS.tao_phieu_nhap}, ${PERMISSIONS.tao_phieu_xuat}`,
              },
              { path: ROUTES.WAREHOUSE_LIST, value: PERMISSIONS.danh_sach_kho },
              { path: ROUTES.DEBIT_NOTE, value: PERMISSIONS.xem_cong_no },
              {
                path: ROUTES.TABLE_PRICE,
                value: PERMISSIONS.xem_cong_thuc_tinh_gia,
              },
              { path: ROUTES.CUSTOMERS, value: PERMISSIONS.xem_khach_hang },
              { path: ROUTES.SHIPPING_PARTNER, value: PERMISSIONS.van_chuyen },
              { path: ROUTES.USERS, value: PERMISSIONS.xem_khach_hang },
              { path: ROUTES.ROLE, value: PERMISSIONS.vai_tro },
            ]

            const menuActive = permissionsMenu.find((permissionMenu) =>
              dataUser.permissions.includes(permissionMenu.value)
            )
            if (menuActive) history.push(menuActive.path)
          }
        }
      } else {
        notification.error({
          message: translate('Error'),
          description: (
            <div style={{ maxHeight: 100, overflow: 'auto' }}>
              {response.data && response.data.message}
            </div>
          ),
        })
      }
    } catch (error) {
      console.log(error)
      context.dispatch({ name: ACTION.LOADING, data: false })
    }
  }

  return (
    <div className="login-container">
      <div className="overlay"></div>
      <div className="login-form">
        <Typography.Title
          level={1}
          style={{
            marginBottom: 64,
            textAlign: 'center',
          }}>
          {translate('Login')}
        </Typography.Title>
        <Form
          name="login"
          initialValues={{
            remember: true,
          }}
          onFinish={_onFinishInput}>
          <Form.Item
            name="tel"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tài khoản',
              },
            ]}>
            <Input
              size="large"
              prefix={<UserOutlined style={{ color: '#1890ff' }} />}
              placeholder={translate('username')}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập mật khẩu',
              },
            ]}>
            <Input.Password
              size="large"
              prefix={<LockOutlined style={{ color: '#1890ff' }} />}
              placeholder={translate('password')}
            />
          </Form.Item>
          <Form.Item>
            <Row justify="space-between">
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>{translate('remember me')}</Checkbox>
              </Form.Item>
              {/* <Link to={ROUTES.FORGOT_PASSWORD}> */}
              {/* {translate('forgot password')} */}
              {/* </Link> */}
            </Row>
          </Form.Item>
          <Form.Item>
            <Row justify="center">
              <Button
                size="large"
                type="primary"
                style={{
                  width: '100%',
                }}
                htmlType="submit">
                {translate('log in')}
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
