import axios from 'axios'
import { stringify } from 'querystring'
import { decodeJWT } from './'
import { notification } from 'antd'
import { translate } from './i18n'
import { refreshToken } from 'apis/auth'

const payloadAccessToken = decodeJWT(localStorage.getItem('accessToken'))

export const FetchAPI = async (
  path,
  method,
  headers,
  body,
  endpoint = process.env.REACT_APP_API_ENDPOINT
) => {
  // if (!headers || !headers.Authorization) {
  //   if (
  //     payloadAccessToken &&
  //     payloadAccessToken.exp < Math.floor(Date.now() / 1000)
  //   ) {
  //     try {
  //       const response = await refreshToken({
  //         refreshToken: localStorage.getItem('refreshToken'),
  //       })
  //       if (response.status === 200 && response.data.accessToken) {
  //         localStorage.setItem('accessToken', response.data.accessToken)
  //       }
  //     } catch (error) {
  //       localStorage.clear()
  //       notification.warning({
  //         message: translate('Session has exprised. Please login again'),
  //       })
  //       setTimeout(() => {
  //         window.location.reload()
  //       }, 500)
  //       return {
  //         status: 401,
  //       }
  //     }
  //   }
  // }
  const defaultHeaders = {
    'Content-type': 'application/json',
    Authorization: localStorage.getItem('accessToken'),
  }
  if (typeof headers === 'object') {
    Object.assign(defaultHeaders, headers)
  }
  try {
    return await axios({
      url: endpoint + path,
      method,
      headers: defaultHeaders,
      data: body,
    })
  } catch (error) {
    if (error.response && error.response.status !== 401) {
      return error.response
    }
    return error
  }
}

export const get = (path, query = {}, headers = {}, endpoint) => {
  return FetchAPI(`${path}?${stringify(query)}`, 'GET', headers, null, endpoint)
}
export const post = (path, body, headers, endpoint) =>
  FetchAPI(path, 'POST', headers, body, endpoint)
export const patch = (path, body, headers, endpoint) =>
  FetchAPI(path, 'PATCH', headers, body, endpoint)
export const destroy = (path, body, headers, endpoint) =>
  FetchAPI(path, 'DELETE', headers, body, endpoint)
