import { translate } from 'utils/i18n'
import moment from 'moment-timezone'
import React from 'react'
import { formatNumber } from 'utils'
export default [
  {
    title: 'STT',
    width: 50,
    render: (text, record, index) => index + 1,
  },
  {
    title: 'Mặc định',
    width: 150,
    render: (text, record, index) => (
      <div>{record.is_default ? 'Có' : 'Không'}</div>
    ),
  },
  {
    title: translate('Partner code'),
    dataIndex: 'shipping_partner_code',
  },
  {
    title: 'Loại vận chuyển',
    render: (text, record) =>
      record.area &&
      ((record.area === 'Inland' && 'Nội địa') ||
        (record.area === 'International' && 'Quốc tế')),
  },
  {
    title: translate('Partner name'),
    dataIndex: 'name',
  },
  {
    title: translate('Tel'),
    dataIndex: 'tel',
  },
  {
    title: translate('Create date'),
    dataIndex: 'created_at',
    render(data) {
      return moment(data).format('DD/MM/YYYY')
    },
  },

  {
    title: translate('Logo'),
    dataIndex: 'avt',
    render(data) {
      return <img src={data} width="50" height="50" />
    },
  },
  {
    title: 'Hành động',
    key: 'action',
  },
]
