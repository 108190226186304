import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import Views from 'views'
import Context from 'utils/Context'
import useGlobalState from 'utils/hooks/useGlobalState'
import { ACTION } from 'consts'
import { decodeJWT, clearBrowserCache } from './utils'
import { Detector, Offline } from 'react-detect-offline'

import { notification } from 'antd'

import Loading from './components/shared/loading/Loading'
import { decodeToken } from 'react-jwt'

export default () => {
  const [isOffline, setIsOffline] = useState(false)
  const [state, dispatch] = useGlobalState({
    ...(localStorage.getItem('accessToken') &&
      decodeToken(localStorage.getItem('accessToken'))),
    isLogin: false,
    isLoading: false, //component loading app
  })

  //config notification
  notification.config({ duration: 3 })

  useEffect(() => {
    clearBrowserCache()

    if (
      !state.isLogin &&
      localStorage.getItem('accessToken') &&
      localStorage.getItem('refreshToken')
    ) {
      dispatch({
        name: ACTION.LOGIN,
        data: {
          accessToken: localStorage.getItem('accessToken'),
          refreshToken: localStorage.getItem('refreshToken'),
        },
      })
    }
  }, [])

  return (
    <Context.Provider
      value={{
        ...state,
        dispatch,
      }}>
      <Loading />
      <Views />
      {/* <Offline>
        <Modal closable={false} footer={false} visible={true}>
          <span style={{ fontSize: 18, fontWeight: 600 }}>
            Vui lòng kiểm tra kết nối mạng
          </span>
        </Modal>
      </Offline> */}
    </Context.Provider>
  )
}
