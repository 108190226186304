import React, { useState, useContext, useEffect } from 'react'
import moment from 'moment'
import { translate } from 'utils/i18n'
import Context from 'utils/Context'
import { ACTION } from 'consts'

//component antd
import { Modal, Typography, Row, Form, Input, notification, Button } from 'antd'

//apis
import { updateUser, getUsers } from 'apis/users'
import { login } from 'apis/auth'

export default ({ children }) => {
  const [form] = Form.useForm()
  const context = useContext(Context)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [customer, setCustomer] = useState({})

  const onUpdateProfile = async () => {
    try {
      await form.validateFields()
      const dataForm = form.getFieldsValue()

      const body = { ...dataForm }

      setLoadingSubmit(true)
      const res = await updateUser(context.user_id, body)
      setLoadingSubmit(false)
      if (res.status === 200) {
        if (res.data.success) {
          _getUserDetail()
          notification.success({ message: 'Cập nhật hồ sơ thành công' })
        } else notification.error({ message: res.data?.mess })
      } else notification.error({ message: res.data?.mess })
    } catch (error) {
      setLoadingSubmit(false)
      console.log(error)
    }
  }

  const ModalChangePassword = () => {
    const [visible, setVisible] = useState(false)
    const [form] = Form.useForm()
    const [loadingSubmit, setLoadingSubmit] = useState(false)

    const toggle = () => setVisible(!visible)

    const onChangePassword = async () => {
      try {
        await form.validateFields()
        const dataForm = form.getFieldsValue()
        if (dataForm.password_new_again !== dataForm.password_new) {
          notification.error({
            message: 'Nhập lại mật khẩu mới không chính xác',
          })
          return
        }

        setLoadingSubmit(true)
        const bodyUpdate = { password: dataForm.password_new }
        const resPassword = await updateUser(context.user_id, bodyUpdate)
        if (resPassword.status === 200) {
          if (resPassword.data.success) {
            form.resetFields()
            notification.success({ message: 'Đổi mật khẩu thành công' })
            toggle()
          } else
            notification.error({
              message: resPassword.data.message || resPassword.data.mess,
            })
        } else
          notification.error({
            message: resPassword.data.message || resPassword.data.mess,
          })
        setLoadingSubmit(false)
      } catch (error) {
        console.log(error)
        setLoadingSubmit(false)
      }
    }

    return (
      <>
        <Button type="primary" onClick={toggle}>
          Đổi mật khẩu
        </Button>
        <Modal
          okButtonProps={{ loading: loadingSubmit }}
          onOk={onChangePassword}
          okText="Lưu"
          cancelText="Hủy"
          visible={visible}
          onCancel={toggle}
          title="Đổi mật khẩu">
          <Form layout="vertical" form={form}>
            <Form.Item
              label="Mật khẩu mới"
              name="password_new"
              rules={[
                { required: true, message: 'Vui lòng nhập mật khẩu mới' },
              ]}>
              <Input.Password allowClear placeholder="Nhập mật khẩu mới" />
            </Form.Item>
            <Form.Item
              label="Nhập lại mật khẩu mới"
              name="password_new_again"
              rules={[
                { required: true, message: 'Vui lòng nhập lại mật khẩu mới' },
              ]}>
              <Input.Password allowClear placeholder="Nhập lại mật khẩu mới " />
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }

  const _getUserDetail = async () => {
    try {
      const res = await getUsers({ user_id: context.user_id })
      if (res.status === 200) {
        if (res.data.data.length === 1) {
          const user = res.data.data[0]
          setCustomer(user)
          form.setFieldsValue({ ...user })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const layout = { labelCol: { span: 3 }, wrapperCol: { span: 21, offset: 1 } }

  useEffect(() => {
    if (context.id) _getUserDetail()
  }, [context])

  return (
    <>
      <Row justify="space-between" align="middle">
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          Hồ sơ
        </Typography.Title>
        <Button
          onClick={onUpdateProfile}
          type="primary"
          loading={loadingSubmit}>
          Cập nhật
        </Button>
      </Row>
      <br />
      <div>
        <Form
          {...layout}
          form={form}
          initialValues={{
            //Nếu là client thì điền sẵn company và disabled
            companyId: context.id == 3 && context.companyId,
          }}>
          <Form.Item
            label={translate('Name')}
            name="name"
            rules={[
              { required: true, message: translate('Please input name') },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label={translate('Phone')}
            name="tel"
            rules={[
              { required: true, message: 'Vui lòng nhập số điện thoại' },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item initialValue="" label={translate('Email')} name="email">
            <Input readOnly bordered={false} type="email" />
          </Form.Item>
          <Form.Item
            initialValue=""
            label={translate('Address')}
            name="address">
            <Input readOnly bordered={false} />
          </Form.Item>
        </Form>
        <Row justify="end">
          <ModalChangePassword />
        </Row>
      </div>
    </>
  )
}
