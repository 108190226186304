import React, { useState, useEffect, useContext, useRef } from 'react'
//component antd
import {
  Button,
  Form,
  message,
  Select,
  InputNumber,
  notification,
  Input,
  Checkbox,
  Modal,
  Table,
  Row,
} from 'antd'
//icon antd

import { translate } from 'utils/i18n'
import { INVOICE_TYPE, ROUTES, ACTION } from 'consts'
import { useHistory, Link } from 'react-router-dom'
import Context from 'utils/Context'
import { getShipping } from 'apis/shipping'
import columnOrderExport from './orderColumnExport'
import columnOrderImport from './orderColumnImport'
export default ({ data, type }) => {
  const [visible, setVisible] = useState(false)

  const _toggleModal = () => setVisible(!visible)
  const [shippingPartnerInternational, setShippingPartnerInternational] =
    useState()

  const _onOk = async () => {}
  const onGetInfoShipping = async (id) => {
    try {
      let res = await getShipping({ id: id })
      if (res.data.success) {
        if (res.data?.data?.length > 0) {
          setShippingPartnerInternational(res.data.data[0])
        }
      }
    } catch (e) {}
  }

  useEffect(() => {
    if (visible) {
      if (data.length > 0) onGetInfoShipping(data[0].shipping_partner_id)
    }
  }, [visible])

  return (
    <>
      <a onClick={_toggleModal}>{'Xem danh sách'}</a>

      <Modal
        title={'Danh sách đơn hàng'}
        visible={visible}
        onCancel={_toggleModal}
        onOk={_onOk}>
        {/* {shippingPartnerInternational && (
          <Row justify={'center'}>
            {
              <h4>{`Vận chuyển tính giá vốn: ${shippingPartnerInternational.name}`}</h4>
            }
          </Row>
        )} */}
        <Table
          columns={type == 'import' ? columnOrderImport : columnOrderExport}
          scroll={{ x: 'max-content' }}
          dataSource={data}
          size="small"
        />
      </Modal>
    </>
  )
}
