import React, { useEffect, useState } from 'react'
import styles from './order-tracking.module.scss'
import { Row, Col, Button, Timeline, Input, notification, Spin } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { ROUTES } from 'consts'
import moment from 'moment'
import 'moment/locale/vi'
import { getOrder } from 'apis/order'

export default function OrderTracking({}) {
  const location = useLocation()
  const queries = new URLSearchParams(location.search)
  const orderId = queries.get('order_id')
  const [orderInfo, setOrderInfo] = useState()
  const history = useHistory()
  const [trackings, setTrackings] = useState([])
  const [searchValue, setSearchValue] = useState(orderId || '')
  const [loading, setLoading] = useState(false)

  const padTo2Digits = (num) => num.toString().padStart(2, '0')

  const createDate = orderInfo?.date_sign && new Date(orderInfo?.date_sign)
  const stringDateFormat = (date) =>
    date
      ? `${padTo2Digits(date.getDate())}-${padTo2Digits(
          date.getMonth() + 1
        )}-${date.getFullYear()}`
      : ''

  const stringTimeFormat = (date) =>
    date
      ? `${padTo2Digits(date.getHours())}:${padTo2Digits(date.getMinutes())}`
      : ''

  const onGetOrderDetail = async () => {
    try {
      setLoading(true)
      var response = await getOrder({ code_bill: searchValue })
      setLoading(false)
      if (response.status == 200 && response.data?.data?.length > 0) {
        setOrderInfo(response.data.data[0])
        setTrackings(JSON.parse(response.data.data[0].trackings))
      } else notification.error({ message: 'Không tìm thấy đơn hàng này' })
    } catch (e) {
      setLoading(false)
      notification.error({ message: 'Có lỗi xảy ra, vui lòng thử lại' })
    }
  }

  useEffect(() => {
    moment.locale('vi')
    if (!searchValue) {
      if (localStorage.getItem('accessToken')) history.push(ROUTES.HOME)
      else history.push(ROUTES.LOGIN)
    } else {
      onGetOrderDetail()
    }
  }, [])

  return (
    <Row justify="center" className={styles['card-info-container']}>
      <div className={styles['card-content']}>
        <div className={styles['card-content-title']}>Tra cứu đơn hàng</div>
        <Input
          onPressEnter={onGetOrderDetail}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          size="large"
          className={styles['card-content-input-search']}
          placeholder="Nhập mã đơn hàng"
        />
        <div className={styles['card-content-search-note']}>
          Nhập mã đơn hàng của bạn, mỗi lần kiểm tra tối đa 1 đơn hàng
        </div>
        <Button
          loading={loading}
          onClick={onGetOrderDetail}
          className={styles['card-content-btn-search']}
          type="primary"
          size="large"
          block>
          TRA CỨU ĐƠN HÀNG
        </Button>
        <br />
        <br />
        <Row
          justify="space-between"
          wrap={false}
          align="middle"
          style={{ fontWeight: 500, borderBottom: '1px solid #d9d9d9' }}>
          {orderInfo && (
            <div style={{ fontSize: 25 }}>#{orderInfo.code_bill}</div>
          )}
          <div>Ngày nhập kho: {stringDateFormat(createDate)}</div>
        </Row>
        <div
          style={{
            padding: '15px 0px',
            borderBottom: '1px solid #d9d9d9',
            marginBottom: 25,
          }}>
          <Row>
            <Col xs={24} md={5}>
              Số lượng kiện:
            </Col>
            <Col xs={24} md={19}>
              <b>{orderInfo?.total_quantity_package}</b>
            </Col>
          </Row>
          <Row className={styles['card-row-name']}>
            <Col xs={24} md={5}>
              Tên hàng hóa:
            </Col>
            <Col xs={24} md={19}>
              <b>{orderInfo?.description}</b>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={5}>
              Mã vận đơn trung quốc:
            </Col>
            <Col xs={24} md={19}>
              <b>{orderInfo?.tracking_number}</b>
            </Col>
          </Row>
        </div>
        <div className="timeline-order-tracking">
          {loading ? (
            <Row justify="center">
              <Spin />
            </Row>
          ) : (
            <Timeline>
              {trackings.map((tracking, index) => {
                return (
                  <Timeline.Item
                    className={`${
                      tracking.updated_time
                        ? tracking.priority === 5
                          ? 'line-green'
                          : 'line-red'
                        : 'line-gray'
                    }`}
                    dot={
                      <div
                        className={`${styles['card-icon-timeline']} ${
                          tracking.updated_time
                            ? tracking.priority === 5
                              ? styles['card-icon-timeline-green']
                              : styles['card-icon-timeline-red']
                            : styles['card-icon-timeline-gray']
                        }`}
                      />
                    }>
                    <Button
                      type="primary"
                      className={`
                  ${styles['btn-timeline']} ${
                        tracking.updated_time
                          ? tracking.priority === 5
                            ? styles['btn-timeline-green']
                            : styles['btn-timeline-red']
                          : styles['btn-timeline-gray']
                      }
                  `}>
                      {tracking.title}
                    </Button>
                    <div className={styles['card-timeline-date']}>
                      {tracking.updated_time
                        ? stringDateFormat(
                            new Date(
                              moment(tracking.updated_time).subtract(7, 'hours')
                            )
                          ) +
                          ' ' +
                          moment(tracking.updated_time)
                            .subtract(7, 'hours')
                            .format(' | dddd, ') +
                          stringTimeFormat(
                            new Date(
                              moment(tracking.updated_time).subtract(7, 'hours')
                            )
                          )
                        : 'Chưa cập nhật'}
                    </div>
                    {index !== trackings.length - 1 ? (
                      <div className={styles['card-timeline-gray']}></div>
                    ) : (
                      ''
                    )}
                  </Timeline.Item>
                )
              })}
            </Timeline>
          )}
        </div>
      </div>
    </Row>
  )
}
