import React, { useContext, useEffect } from 'react'

import { Link, useRouteMatch } from 'react-router-dom'
import { translate } from 'utils/i18n'
import Context from 'utils/Context'
import { ACTION, ROUTES } from 'consts'

//router
import { useHistory, useLocation } from 'react-router-dom'

//component
import Permission from 'components/Permission'

//component antd
import { Menu, Layout } from 'antd'

//icon antd
import {
  HomeOutlined,
  SlidersOutlined,
  ShoppingCartOutlined,
  AreaChartOutlined,
  BankOutlined,
  UserOutlined,
  SearchOutlined,
  GroupOutlined,
  TagsOutlined,
  FormOutlined,
  PieChartOutlined,
  DollarOutlined,
  ShopOutlined,
  PartitionOutlined,
  DropboxOutlined,
} from '@ant-design/icons'

import { PERMISSIONS } from 'consts/permissions'
import { Struck } from './icon'
import { refreshToken } from 'apis/auth'

const { Sider } = Layout
export default (props) => {
  const context = useContext(Context)
  const routeMatch = useRouteMatch()
  const history = useHistory()
  const location = useLocation()

  if (location.pathname == '/login') return null

  const rootSubmenuKeys = ['report', 'manager']
  const [openKeys, setOpenKeys] = React.useState([])

  const onOpenChange = (keys) => {
    console.log(keys)
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      localStorage.removeItem('openKey')
      setOpenKeys(keys)
    } else {
      localStorage.setItem('openKey', latestOpenKey)
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  const MENUS = [
    {
      path: ROUTES.HOME,
      title: translate('Home'),
      icon: <HomeOutlined />,
      permissions: [PERMISSIONS.tra_cuu_don],
    },
    {
      path: ROUTES.OVERVIEW,
      title: translate('Overview'),
      permissions: [PERMISSIONS.tong_quan],
      icon: <PieChartOutlined />,
    },
    {
      path: ROUTES.ORDERS,
      title: translate('Shipments'),
      permissions: [PERMISSIONS.xem_don_hang],
      icon: <ShoppingCartOutlined />,
    },
    {
      path: ROUTES.ITINERARY,
      title: 'Hành trình',
      permissions: [PERMISSIONS.hanh_trinh],
      icon: <PartitionOutlined />,
    },
    {
      title: translate('Warehouse'),
      permissions: [PERMISSIONS.tao_phieu_nhap, PERMISSIONS.tao_phieu_xuat],
      icon: <GroupOutlined />,
      path: ROUTES.WAREHOUSES,
    },
    {
      title: 'Danh sách kho',
      permissions: [PERMISSIONS.danh_sach_kho],
      icon: <DropboxOutlined />,
      path: ROUTES.WAREHOUSE_LIST,
    },
    {
      path: ROUTES.DEBIT_NOTE,
      title: translate('Debit note'),
      permissions: [PERMISSIONS.xem_cong_no],
      icon: <DollarOutlined />,
    },
    {
      path: ROUTES.TABLE_PRICE,
      title: translate('Price formula'),
      permissions: [PERMISSIONS.xem_cong_thuc_tinh_gia],
      icon: <TagsOutlined />,
    },
    {
      path: ROUTES.CUSTOMERS,
      title: translate('Customer'),
      permissions: [PERMISSIONS.xem_khach_hang],
      icon: <FormOutlined />,
    },
    {
      path: ROUTES.SHIPPING_PARTNER,
      title: translate('Shipping'),
      permissions: [PERMISSIONS.van_chuyen],
      icon: (
        <span
          role="img"
          aria-label="form"
          class="anticon anticon-form ant-menu-item-icon"
          style={{ alignItems: 'center' }}>
          <Struck />
        </span>
      ),
    },
    {
      path: ROUTES.USERS,
      title: 'Nhân viên',
      permissions: [PERMISSIONS.xem_khach_hang],
      icon: <UserOutlined />,
    },
    {
      path: ROUTES.ROLE,
      title: 'Vai trò',
      permissions: [PERMISSIONS.vai_tro],
      icon: <UserOutlined />,
    },
  ]

  const _renderMenuItem = (_menu) => (
    <Permission permissions={_menu.permissions} key={_menu.path}>
      {_menu.menuItems ? (
        <Menu.SubMenu
          key={_menu.path}
          icon={_menu.icon}
          title={<span style={{ fontWeight: 500 }}>{_menu.title}</span>}>
          {_menu.menuItems.map((e) => (
            <Menu.Item key={e.path} icon={e.icon}>
              <Link to={e.path} style={{ fontWeight: 500 }}>
                {translate(e.title)}
              </Link>
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      ) : (
        <Menu.Item icon={_menu.icon} key={_menu.path}>
          <Link
            to={_menu.path}
            // target={_menu.title === 'Search' && '_blank'}
            style={{ fontWeight: 500 }}>
            {translate(_menu.title)}
          </Link>
        </Menu.Item>
      )}
    </Permission>
  )

  const _refreshToken = async () => {
    try {
      const response = await refreshToken({
        refreshToken: localStorage.getItem('refreshToken'),
      })

      if (response.status === 200 && response.data.data) {
        context.dispatch({ name: ACTION.LOGIN, data: response.data.data })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _refreshToken()

    if (localStorage.getItem('openKey'))
      setOpenKeys([localStorage.getItem('openKey')])
  }, [])

  return (
    <Sider
      style={{
        backgroundColor: '#050c42',
        boxShadow: '0 1px 4px -1px rgb(0 0 0 / 15%)',
        width: '50px',
        minWidth: '50px',
        maxWidth: '50px',
      }}
      trigger={null}
      collapsible
      collapsed={props.collapsed}
      defaultCollapsed>
      <div
        style={{
          display: props.collapsed ? 'none' : 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '70px',
          backgroundColor: '#050c42',
          color: 'red',
          position: 'sticky',
          top: 0,
          zIndex: 40,
          fontSize: '24px',
          fontWeight: '700',
          zIndex: 40,
          boxShadow: 'rgb(0 0 0 / 2%) 0px 2px 2px, rgb(0 0 0 / 2%) 0px 1px 0px',
          // marginBottom: '15px',
        }}>
        {/* {translate('delivery')} */}
        <Link to="/" style={{ color: 'red' }}>
          BaoBao Express
        </Link>
      </div>
      <Menu
        onClick={(e) => {
          let isOpenKey = false
          MENUS.map((element) => {
            if (element.menuItems) {
              element.menuItems.map((value) => {
                if (value.path == e.key) isOpenKey = true
              })
            }
          })
          !isOpenKey && localStorage.removeItem('openKey')
        }}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        mode="inline"
        selectedKeys={routeMatch.path}
        style={{
          position: 'sticky',
          top: 70,
        }}>
        {MENUS.map(_renderMenuItem)}
        {/* <div
          style={{
            // position: 'fixed',
            // bottom: 40,
            // left: props.collapsed ? 5 : 60,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '18px',
            cursor: 'pointer',
            marginBottom: 25,
            marginTop: 15,
          }}
          onClick={() => {
            context.dispatch({
              name: ACTION.LOGOUT,
            })
            history.push(ROUTES.LOGIN)
          }}
        >
          <LogoutOutlined style={{ fontSize: '20px', marginBottom: 10 }} />
          <div style={{ display: props.collapsed ? 'none' : '' }}>
            {translate('Sign out')}
          </div>
        </div> */}
      </Menu>
    </Sider>
  )
}
