// react
import React, { useState, useEffect, useRef } from 'react'
import styles from './search.module.scss'
import { formatNumber } from 'utils'
import moment from 'moment-timezone'
import ReactDragListView from 'react-drag-listview'

// antd
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  DatePicker,
  Select,
  Table,
  Modal,
  Checkbox,
  notification,
} from 'antd'

//icons
import { SearchOutlined } from '@ant-design/icons'

// components
import searchColumns from './searchColumns'
import SettingColumns from 'components/setting-column'

//apis
import { getOrderDetail } from 'apis/order'

export default function SearchBill() {
  const typingTimeoutRef = useRef()

  const [loading, setLoading] = useState(false)
  const [displayColumns, setDisplayColumns] = useState([])
  const [showSettingColumns, setShowSettingColumns] = useState(false)
  const [searchResult, setSearchResult] = useState([])
  const [matchNotify, setMatchNotify] = useState(false)
  const [columns, setColumns] = useState(
    localStorage.getItem('searchColumns')
      ? JSON.parse(localStorage.getItem('searchColumns'))
      : [...searchColumns]
  )

  const tableColumnRender = {
    0: {
      align: 'center',
      render: (text, record, index) => (
        <span style={{ fontSize: '10pt' }}>{index}</span>
      ),
    },
    1: {
      align: 'center',
      render(data) {
        return data && moment(data).format('DD/MM/YYYY HH:MM:SS')
      },
    },
    2: {
      align: 'center',
      render(data) {
        return <span style={{ fontSize: '10pt' }}>{data}</span>
      },
    },
    3: {
      align: 'center',
      render(data) {
        return <span style={{ fontSize: '10pt' }}>{data}</span>
      },
    },
    4: {
      align: 'center',
      render(data) {
        return <span style={{ fontSize: '10pt' }}>{data}</span>
      },
    },
    5: {
      align: 'center',
      render(data, record) {
        return (
          <span style={{ fontSize: '10pt' }}>
            {record.customer_code || (data && data.customer_code)}
          </span>
        )
      },
    },
    6: {
      align: 'right',
      render(data) {
        return (
          <span style={{ fontSize: '10pt' }}>{formatNumber(data)} VND</span>
        )
      },
    },
    7: {
      align: 'right',
      render(data) {
        return (
          <span style={{ fontSize: '10pt' }}>{formatNumber(data)} VND</span>
        )
      },
    },
    8: {
      align: 'right',
      render(data) {
        return (
          <span style={{ fontSize: '10pt' }}>{formatNumber(data)} VND</span>
        )
      },
    },
    9: {
      align: 'right',
      render(data) {
        return (
          <span style={{ fontSize: '10pt' }}>{formatNumber(data)} VND</span>
        )
      },
    },
    10: {
      align: 'right',
      render(data) {
        return <span style={{ fontSize: '10pt' }}>{formatNumber(data)} kg</span>
      },
    },
    11: {
      align: 'right',
      render(data) {
        return (
          <span style={{ fontSize: '10pt' }}>
            {formatNumber(data)} m<sup>3</sup>
          </span>
        )
      },
    },
    12: {
      align: 'right',
      render(data) {
        return (
          <span style={{ fontSize: '10pt' }}>{formatNumber(data)} VND</span>
        )
      },
    },
    13: {
      align: 'right',
      render(data) {
        return (
          <span style={{ fontSize: '10pt' }}>{formatNumber(data)} VND</span>
        )
      },
    },
    14: {
      align: 'right',
      render(data) {
        return (
          <span style={{ fontSize: '10pt' }}>{formatNumber(data)} VND</span>
        )
      },
    },
    15: {
      align: 'right',
      render(data) {
        return (
          <span style={{ fontSize: '10pt' }}>{formatNumber(data)} VND</span>
        )
      },
    },
    16: {
      align: 'center',
      render(data) {
        return (
          <span style={{ fontSize: '10pt' }}>
            {data === 'StackCar'
              ? 'Xếp xe'
              : data === 'Delivery'
              ? 'Đã nhận'
              : 'Đã về kho'}
          </span>
        )
      },
    },
    17: {
      align: 'center',
      render(data) {
        return data && moment(data).format('DD/MM/YYYY')
      },
    },
    18: {
      width: 250,
      align: 'center',
      render(data) {
        return data
      },
    },
  }

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const tmpColumns = [...columns]
      const item = tmpColumns.splice(fromIndex, 1)[0]
      console.log('from', fromIndex, 'to', toIndex)
      tmpColumns.splice(toIndex, 0, item)
      localStorage.setItem('searchColumns', JSON.stringify(tmpColumns))
      setColumns(tmpColumns)
    },
    nodeSelector: 'th',
  }

  const _getOrderDetail = async (params) => {
    try {
      setLoading(true)
      const res = await getOrderDetail(params)
      console.log(res)

      if (res.status === 404) {
        setMatchNotify(true)
        setTimeout(() => setMatchNotify(false), 3000)
        return
      }

      if (res.status === 500) {
        notification.error({ message: 'Hệ thống đang xẩy ra sự cố!' })
        return
      }

      if (res.status === 200) {
        if (res.data.success) {
          setSearchResult([res.data.data])
          setMatchNotify(false)
        } else {
          setMatchNotify(true)
          setTimeout(() => setMatchNotify(false), 3000)
        }
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      notification.error({ message: 'Hệ thống đang xẩy ra sự cố' })
      console.log(err)
    }
  }

  const _onSearch = (value) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      console.log(value)
      if (value) _getOrderDetail({ code: value })
      else _getOrderDetail()
    }, 650)
  }

  useEffect(() => {
    if (!localStorage.getItem('searchColumns')) {
      localStorage.setItem('searchColumns', JSON.stringify(columns))
    }
  }, [])
  return (
    <div className={styles['search']}>
      <div className={styles['header']}>Tra cứu vận đơn</div>
      <div className={styles['search-box']}>
        <div>
          <Input
            onChange={(e) => _onSearch(e.target.value)}
            prefix={<SearchOutlined />}
            allowClear
            placeholder="Tìm kiếm số vận đơn/mã bill"
            style={{ borderRadius: '5px', width: '45%' }}
          />
        </div>
        {matchNotify && (
          <div
            style={{
              backgroundColor: '#f9cb24a8',
              padding: '2px 5px',
              fontSize: 16,
              borderRadius: 2,
            }}>
            Không tìm thấy đơn hàng
          </div>
        )}
      </div>
      <Row justify="end" style={{ margin: '1em 0' }}>
        <SettingColumns
          width={700}
          columnsRender={searchColumns}
          columns={columns}
          setColumns={setColumns}
          nameColumn="searchColumns"
          buttonProps={{
            style: {
              backgroundColor: '#5ecba1',
              border: 'none',
              borderRadius: 5,
            },
          }}
        />
      </Row>
      <ReactDragListView.DragColumn {...dragProps}>
        <Table
          loading={loading}
          columns={columns.map((e) => {
            if (tableColumnRender[e.key])
              return { ...e, ...tableColumnRender[e.key] }
            return e
          })}
          dataSource={searchResult}
          scroll={{ x: 'max-content' }}
        />
      </ReactDragListView.DragColumn>
    </div>
  )
}
