import { get, patch, post, destroy } from 'utils/Fetch'

export const createCardIn = (data) => post('/card/createcardin', data)
export const createCardOut = (data) => post('/card/createcardout', data)
export const getCardByType = (data) => get('/card', data)
export const updateCard = (id, data) => patch('/card/update/' + id, data)
export const getOrderInInventory = (params) => get('/inventory', params)
export const exportAllInInventory = (params) =>
  get('/inventory/export-all', params)
export const exportFilterInInventory = (params) =>
  get('/inventory/export-by-filter', params)
export const exportPageInInventory = (params) =>
  get('/inventory/export-page', params)
export const importCardIn = (data) => post('/card/import_cardin', data)
export const importCardOut = (data) => post('/card/import_cardout', data)
export const deleteCard = (id) => destroy('/card/delete/' + id)
export const cancelCard = (id) => post('/card/cancel', { id: id })
export const exportCardCurrentPage = (query) => get('/card/export-page', query)
export const exportCardByFilter = (query) => get('/card/export-filter', query)
export const exportCardAll = (query) => get('/card/export-all', query)
