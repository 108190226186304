import React, { useEffect, useRef, useState } from 'react'
// antd components
import {
  Button,
  Row,
  Typography,
  Radio,
  Col,
  Tabs,
  notification,
  Modal,
  Input,
} from 'antd'
import { PERMISSIONS } from 'consts/permissions'
import Permission from 'components/Permission'
import { translate } from 'utils/i18n'
import CreateTablePrice from './CreateModal'
import { formatNumber } from 'utils'
import { ROUTES } from 'consts'
import { getPrice, deletePrice } from 'apis/price'
import { getShipping } from 'apis/shipping'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { removeNull } from 'utils'
// import { useDispatch } from 'react-redux';
const { TabPane } = Tabs
export default function TablePrice() {
  const history = useHistory()
  const [showCreate, setShowCreate] = useState(false)
  const [price, setPrice] = useState([])
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [partnerList, setPartnerList] = useState({})
  const [filters, setFilters] = useState({ title: '' })
  const [activetab, setActiveTab] = useState(
    history.location.state?.priceActiveTab
      ? history.location.state.priceActiveTab + ''
      : '0'
  )
  // const dispatch = useDispatch();
  const typingTimeoutRef = useRef()

  const colorList = ['#2895E4', '#DF9401', '#01AA12']

  const onSearch = (e) => {
    const value = e.target.value
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (!value) delete filters.title
      else filters.title = value
      setFilters({ ...filters })
    }, 750)
  }
  const getAllPrice = async (params) => {
    try {
      // dispatch({ type: 'LOADING', data: true });
      const res = await getPrice(params)
      if (res.data.success) {
        setPrice(res.data.data)
      }
    } catch (err) {
      console.log(err)
    }
    // dispatch({ type: 'LOADING', data: false });
  }
  const deleteEffortPrice = async (id) => {
    try {
      const res = await deletePrice(id)
      if (res.data.success && res.status == 200) {
        notification.success({
          message: 'Xóa công thức tính giá thành công',
        })
        getAllPrice()
      } else {
        notification.error({
          message: 'Xóa công thức tính giá thất bại',
        })
      }
    } catch (err) {
      notification.error({
        message: 'Thất bại',
      })
      console.log(err)
    }
  }
  const getAllShipping = async (params) => {
    try {
      const res = await getShipping(params)
      if (res.data.success) {
        setPartnerList(
          res.data.data?.reduce(
            (a, b) => ((a[b.shipping_partner_code] = b), a),
            {}
          )
        )
      }
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getAllShipping()
  }, [])

  useEffect(() => {
    getAllPrice(removeNull(filters))
  }, [filters])
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Typography.Title level={4}>
          {translate('Price formula')}
        </Typography.Title>

        <Permission permissions={[PERMISSIONS.tao_cong_thuc_tinh_gia]}>
          <Button
            type="primary"
            className="primary-button"
            href={ROUTES.CREATE_TABLE_PRICE}
            // onClick={() => setShowCreate(true)}
            style={{ borderRadius: '5px' }}>
            Tạo công thức tính giá
          </Button>
        </Permission>
      </Row>
      <br />
      <Row>
        <Col span={8}>
          <Input
            placeholder="Tìm theo tên công thức"
            onChange={onSearch}
            allowClear
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Tabs
          type="card"
          style={{ width: '100%' }}
          activeKey={activetab}
          onChange={(e) => setActiveTab(e)}>
          {price.map((e, priceIndex) => (
            <TabPane
              tab={e.title}
              key={priceIndex}
              style={{ background: '#fff', padding: 20 }}>
              <Row justify="end">
                <Permission permissions={[PERMISSIONS.xoa_cong_thuc_tinh_gia]}>
                  <Button
                    type="primary"
                    icon={<DeleteOutlined />}
                    style={{ marginRight: 15 }}
                    onClick={() => setShowDeleteConfirm(true)}
                    danger>
                    Xóa
                  </Button>
                </Permission>
                <Permission
                  permissions={[PERMISSIONS.cap_nhat_cong_thuc_tinh_gia]}>
                  <Button
                    type="primary"
                    icon={<EditOutlined />}
                    onClick={() =>
                      history.push({
                        pathname: ROUTES.UPDATE_TABLE_PRICE,
                        state: { data: e, priceActiveTab: priceIndex },
                      })
                    }>
                    Cập nhật
                  </Button>
                </Permission>
              </Row>
              {e.description}
              <div
                style={{
                  width: '960px',
                  overflowX: 'auto',
                  margin: 'auto',
                  marginTop: '1em',
                  display: 'block',
                  background: '#fff',
                }}>
                <div style={{ width: 'max-content' }}>
                  {e.effort_price_packages &&
                    e.effort_price_packages.map((pack, index) => (
                      <div
                        style={{
                          width: 320,
                          display: 'inline-block',
                          padding: 10,
                          background: '#fff',
                          border: 'solid 1px #C2C2C2',
                          height: '100%',
                          textAlign: 'center',
                          fontSize: 16,
                        }}>
                        <h1
                          style={{
                            fontSize: 21,
                            fontWeight: 700,
                            color: colorList[index % colorList.length],
                          }}>
                          {`Gói ${index + 1}`}
                        </h1>
                        {pack.shipping_partner_code && (
                          <div style={{ fontSize: 19, fontWeight: 600 }}>
                            Đơn vị vận chuyển{' '}
                            {partnerList[pack.shipping_partner_code]?.name}
                          </div>
                        )}
                        <div style={{ fontSize: 19, fontWeight: 600 }}>
                          Trọng lượng tối thiểu (kg)
                        </div>
                        {formatNumber(pack.condition_miximum_kg || 0)} kg
                        <div
                          style={{
                            fontSize: 19,
                            fontWeight: 600,
                            marginTop: 7,
                          }}>
                          Trọng lượng tối đa (kg)
                        </div>
                        {formatNumber(pack.condition_maximum_kg || 0)} kg
                        <div
                          style={{
                            fontSize: 19,
                            fontWeight: 600,
                            marginTop: 7,
                          }}>
                          Khối lượng tối thiểu (m3)
                        </div>
                        {formatNumber(pack.condition_miximum_m3 || 0)} m
                        <sup>3</sup>
                        <div
                          style={{
                            fontSize: 19,
                            fontWeight: 600,
                            marginTop: 7,
                          }}>
                          Khối lượng tối đa (m3)
                        </div>
                        {formatNumber(pack.condition_maximum_m3 || 0)} m
                        <sup>3</sup>
                        <div
                          style={{
                            fontSize: 19,
                            fontWeight: 600,
                            marginTop: 7,
                          }}>
                          Chi phí/1kg
                        </div>
                        {formatNumber(pack.cost_per_kg || 0)} VND
                        <div
                          style={{
                            fontSize: 19,
                            fontWeight: 600,
                            marginTop: 7,
                          }}>
                          Chi phí/1m<sup>3</sup>
                        </div>
                        {formatNumber(pack.cost_per_m3 || 0)} VND
                      </div>
                    ))}
                </div>
              </div>

              <Modal
                visible={showDeleteConfirm}
                onCancel={() => setShowDeleteConfirm(false)}
                onOk={() => {
                  deleteEffortPrice(e.id)
                  setShowDeleteConfirm(false)
                }}>
                <h3>Bạn muốn xóa công thức tính giá {e.title}</h3>
              </Modal>
            </TabPane>
          ))}
        </Tabs>
      </Row>
      <CreateTablePrice
        visible={showCreate}
        onCancel={() => setShowCreate(false)}
        reload={getAllPrice}
      />
    </div>
  )
}
