import { EditOutlined, DeleteFilled, DeleteOutlined } from '@ant-design/icons'
import {
  Typography,
  Row,
  Button,
  Col,
  Input,
  DatePicker,
  Table,
  Popconfirm,
  notification,
  Tooltip,
} from 'antd'
import { getShipping, deleteShipping } from 'apis/shipping'
import Permission from 'components/Permission'
import { ROUTES } from 'consts'
import React, { useEffect, useState } from 'react'
import { translate } from 'utils/i18n'
import CreatePartner from './CreatePartner'
import shippingPartnerColumns from './shippingPartnerColumns'
import UpdatePartner from './updatePartner'
import { PERMISSIONS } from 'consts/permissions'

export default function ShippingPartner() {
  const [filter, setFilter] = useState({ page: 1, pageSize: 300 })
  const [maxrecord, setMaxrecord] = useState(0)
  const [partnerList, setPartnerList] = useState([])
  const [showCreate, setShowCreate] = useState(false)
  const [showUpdate, setShowUpdate] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [initValue, setInitvalue] = useState([])
  const getPartner = async (params) => {
    try {
      const res = await getShipping(params)
      if (res.data.success) {
        setPartnerList(res.data?.data?.filter((e) => e.is_activate))
        // setMaxrecord(res.data.count);
      }
    } catch (e) {
      console.log(e)
    }
  }
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  const onShowUpdate = (id) => {
    let tmp = []
    const warehouse = partnerList.find((p) => p.id == id)
    tmp.push(warehouse)
    setInitvalue(tmp)
    setShowUpdate(true)
  }

  const onDelete = async (id) => {
    var res = await deleteShipping(id)
    if (res.status == 200 && res.data.success) {
      notification.success({ message: 'Xoá đơn vị vận chuyển thành công' })
      getPartner()
    } else {
      notification.error({
        message: res.data.mess
          ? res.data.mess
          : 'Có lỗi xảy ra, vui lòng thử lại',
      })
    }
  }

  useEffect(() => {
    getPartner()
  }, [])
  return (
    <div>
      <Row justify="space-between">
        <Typography.Title level={4}>
          {translate('Shipping partner')}
        </Typography.Title>
        <Permission permissions={[PERMISSIONS.tao_van_chuyen]}>
          <Button
            type="primary"
            className="primary-button"
            onClick={() => setShowCreate(true)}>
            {translate('Create shipping partner')}
          </Button>
        </Permission>
      </Row>
      <Row gutter={30} style={{ marginBottom: 20 }}>
        <Col span={8}>
          <Input
            placeholder={translate('Search by partner name')}
            allowClear
            onChange={(e) =>
              getPartner(e.target.value && { name: e.target.value })
            }
          />
        </Col>
        {/* <Col span={8}>
          <DatePicker.RangePicker />
        </Col> */}
      </Row>
      <Row style={{ marginBottom: 15 }}>
        {selectedRowKeys.length > 0 && (
          <Button type="primary" onClick={onShowUpdate}>
            Cập nhật
          </Button>
        )}
      </Row>
      <Table
        columns={shippingPartnerColumns.map((e) => {
          if (e.key && e.key == 'action')
            return {
              ...e,
              render(data) {
                return (
                  <div>
                    <Permission permissions={[PERMISSIONS.cap_nhat_van_chuyen]}>
                      <EditOutlined
                        style={{
                          color: 'blue',
                          cursor: 'pointer',
                          marginRight: 10,
                        }}
                        onClick={() => onShowUpdate(data.id)}
                      />
                    </Permission>
                    <Permission permissions={[PERMISSIONS.xoa_van_chuyen]}>
                      <Popconfirm
                        onConfirm={() => onDelete(data.id)}
                        title={'Xác nhận xoá'}>
                        <DeleteOutlined
                          style={{ color: 'red', cursor: 'pointer' }}
                        />
                      </Popconfirm>
                    </Permission>
                  </div>
                )
              },
            }
          return e
        })}
        dataSource={partnerList}
        // rowKey="id"
        // rowSelection={rowSelection}
        size="small"
        scroll={{ y: 380 }}
        pagination={{
          pageSize: filter.pageSize,
          pageSizeOptions: [100, 200, 300, 400],
          current: filter.page,
          // total:maxrecord,
          onChange: (page, pageSize) =>
            setFilter({ ...filter, page, pageSize }),
        }}
      />
      <CreatePartner
        visible={showCreate}
        onClose={() => setShowCreate(false)}
        reload={getPartner}
      />
      <UpdatePartner
        visible={showUpdate}
        onClose={() => setShowUpdate(false)}
        reload={getPartner}
        initvalue={initValue}
      />
    </div>
  )
}
