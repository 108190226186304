import {
  Drawer,
  Form,
  Input,
  Row,
  Upload,
  Button,
  notification,
  Select,
  message,
  Checkbox,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { translate } from 'utils/i18n'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import uploadFile from 'apis/upload'
import { createShipping } from 'apis/shipping'
import { getWarehouses } from 'apis/warehouses'

export default function CreatePartner({ visible, onClose, reload }) {
  const [imageUrl, setImageUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [warehouses, setWarehouses] = useState([])

  const [form] = Form.useForm()
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  const getAllWarehouse = () => {
    getWarehouses()
      .then((res) => {
        if (res.data.success) {
          setWarehouses(res.data.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getAllWarehouse()
  }, [])

  const uploadImg = async (file) => {
    setLoading(true)
    const image = await uploadFile(file.file.originFileObj)

    if (image) {
      setImageUrl(image)
    }
    setLoading(false)
  }
  const onFinish = async (value) => {
    const body = {
      ...value,
      avt: imageUrl,
    }
    setLoadingSubmit(true)
    const res = await createShipping(body)
    setLoadingSubmit(false)
    if (res.status == 200 && res.data.success) {
      notification.success({ message: 'Thêm Đối tác thành công' })
      onClose()
      setImageUrl('')
      form.setFieldsValue({
        name: '',
        tel: '',
      })
      reload()
    } else {
      setLoadingSubmit(false)
      notification.error({
        message: res.data?.message || 'Có lỗi xảy ra, vui lòng thử lại',
      })
    }
  }
  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      title={translate('Create shipping partner')}
      width={500}>
      <Row justify="center">
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          onChange={uploadImg}
          maxCount={1}>
          {imageUrl && !loading ? (
            <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
          ) : (
            uploadButton
          )}
        </Upload>
      </Row>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item
          style={{ width: '100%' }}
          labelCol={{ span: 24 }}
          required
          name="warehouse_id"
          label={'Kho'}>
          <Select optionFilterProp="children" showSearch>
            {warehouses.map((val, index) => (
              <Select.Option value={val.id} key={index}>
                {val.name + ' (' + val.code + ')'}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="is_default" valuePropName="checked" label={'Mặc định'}>
          <Checkbox />
        </Form.Item>
        <Form.Item name="shipping_partner_code" label={'Mã đối tác'}>
          <Input />
        </Form.Item>
        <Form.Item name="name" label={translate('Partner name')}>
          <Input />
        </Form.Item>
        <Form.Item name="tel" label={translate('Phone')}>
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ message: 'Vui lòng chọn loại đối tác', required: true }]}
          name="area"
          label={translate('Partner Type')}>
          <Select>
            <Select.Option value="International">Quốc tế</Select.Option>
            <Select.Option value="Inland">Nội địa</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Row justify="end">
            <Button loading={loadingSubmit} type="primary" htmlType="submit">
              {translate('Create')}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Drawer>
  )
}
