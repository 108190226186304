import { get, destroy, post, patch } from 'utils/Fetch'

export const getUsers = (params) => get('/users', params && params)
export const getUsersByRole = (params) => get('/users/getuserbyroles', params)
export const getHomeData = (query) => get(`/users/home`, query)
export const getUserDetail = (query) => get(`/users/detail`, query)
export const deleteUser = (userId) => destroy(`/users/${userId}`)
export const updateUser = (id, body) => patch('/users/' + id, body)
export const addUser = (body) => post('/auth/register', body)
export const createCustomer = (body) => post('/users/create', body)
export const importCustomer = (body) => post('/users/import', body)
//new
export const addPermission = (id_role, id_permission) =>
  post('/roles/addpermission', { id_role, id_permission })
export const removePermission = (id_role, id_permission) =>
  post('/roles/removepermission', { id_role, id_permission })
