import {
  Drawer,
  Form,
  Input,
  Row,
  Upload,
  Button,
  notification,
  Select,
  Checkbox,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { translate } from 'utils/i18n'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import uploadFile from 'apis/upload'
import { createShipping, updateShipping } from 'apis/shipping'
import { getWarehouses } from 'apis/warehouses'
export default function UpdatePartner({ visible, onClose, reload, initvalue }) {
  const [warehouses, setWarehouses] = useState([])
  const [imageUrl, setImageUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [shipping, setShipping] = useState()
  const [avt, setAvatar] = useState('')
  const [form] = Form.useForm()
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )
  const uploadImg = async (file) => {
    if (file.file.status == 'uploading') {
      const image = await uploadFile(file.file.originFileObj)
      if (image) {
        setAvatar(image)
        form.setFieldsValue({ avt: image })
      }
    }
  }
  const onFinish = async (value) => {
    try {
      if (shipping == undefined) {
        notification.error({ message: 'Vui lòng tải lại trang' })
        return
      }

      var data = form.getFieldsValue()
      setLoadingSubmit(true)
      var res = await updateShipping(shipping.id, data)
      setLoadingSubmit(false)
      if (res.status == 200 && res.data.success) {
        notification.success({ message: 'Cập nhật thành công' })
        reload()
      } else {
        notification.error({
          message: res.data.mess ? res.data.mess : 'Lỗi cập nhật',
        })
      }
      onClose()
    } catch (error) {
      setLoadingSubmit(false)
    }
  }

  const getAllWarehouse = () => {
    getWarehouses()
      .then((res) => {
        if (res.data.success) {
          setWarehouses(res.data.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (visible) getAllWarehouse()
  }, [visible])

  useEffect(() => {
    if (initvalue.length > 0) {
      setShipping({ ...initvalue[0] })
      setAvatar(initvalue[0].avt)
      form.setFieldsValue({ ...initvalue[0] })
    }
  }, [initvalue])

  return (
    <Drawer
      visible={visible}
      onClose={() => {
        onClose()
        form.resetFields()
      }}
      title={translate('Cập nhật đối tác vận chuyển')}
      width={500}>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item name="avt">
          <Row justify="center">
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              onChange={(e) => uploadImg(e)}
              maxCount={1}>
              <img src={avt} alt="avatar" style={{ width: '100%' }} />
            </Upload>
          </Row>
        </Form.Item>
        <Form.Item
          style={{ width: '100%' }}
          labelCol={{ span: 24 }}
          required
          name="warehouse_id"
          label={'Kho'}>
          <Select placeholder="Chọn kho" optionFilterProp="children" showSearch>
            {warehouses.map((val, index) => (
              <Select.Option value={val.id} key={index}>
                {val.name + ' (' + val.code + ')'}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="is_default" valuePropName="checked" label={'Mặc định'}>
          <Checkbox />
        </Form.Item>
        <Form.Item
          label={'Mã đơn vị vận chuyển'}
          name={'shipping_partner_code'}>
          <Input />
        </Form.Item>

        <Form.Item label={translate('Partner name')} name={'name'}>
          <Input />
        </Form.Item>
        <Form.Item label={translate('Phone')} name="tel">
          <Input />
        </Form.Item>
        <Form.Item label={translate('Partner Type')} name={'area'}>
          <Select>
            <Select.Option value="International">Quốc tế</Select.Option>
            <Select.Option value="Inland">Nội địa</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Row justify="end">
            <Button loading={loadingSubmit} type="primary" htmlType="submit">
              Cập nhật
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Drawer>
  )
}
