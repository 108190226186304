import React, { useState, useEffect } from 'react'
import locale from 'antd/es/date-picker/locale/vi_VN'
import {
  DatePicker,
  Row,
  Col,
  Select,
  Input,
  InputNumber,
  Button,
  Table,
  Divider,
  Modal,
} from 'antd'
import { MessageOutlined } from '@ant-design/icons'
import { getUsers } from 'apis/users'
import { getShipping } from 'apis/shipping'
import styles from './styles.module.scss'
import { getOrderByDate, getOrder } from 'apis/order'
import moment from 'moment-timezone'
import { formatNumber } from 'utils'
import { translate } from 'utils/i18n'

export default function WarehouseUpdateExportForm({
  formkey,
  data,
  allData,
  setData,
  onUpdateBill,
  onDeleteBill,
}) {
  const [customerList, setCustomerList] = useState([])
  const [partnerList, setPartnerList] = useState([])
  const [listsSearchByDate, setListSearchByDate] = useState([])
  const [showPickOrder, setShowPickOrder] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [sumVolume, setSumVolume] = useState(0)
  const [sumMass, setSumMass] = useState(0)
  const [totalSumCost, setTotalSumCost] = useState(0)
  const [totalExport, setTotalExport] = useState(0)
  const [totalRequireExport, setTotalRequireExport] = useState(0)

  const onUpdateBillItem = (bill, amount_package_export) => {
    var _orders = [...data.orders]
    var index = data.orders.findIndex(
      (order) => order.code_bill == bill.code_bill
    )
    if (index >= 0) {
      _orders[index].amount_package_export = amount_package_export

      onUpdateBill(data.id, _orders[index])
    }
  }

  const onDeleteBillItem = (bill) => {
    var _orders = [...data.orders]
    var index = data.orders.findIndex(
      (order) => order.code_bill == bill.code_bill
    )
    if (index >= 0) {
      onDeleteBill(data.id, _orders[index])
    }
  }

  const getCustomerList = async () => {
    try {
      const res = await getUsers({ role_id: 7, page: 1, pageSize: 10000 })
      if (res.data.success) {
        setCustomerList(res.data.data)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const getPartnerList = async () => {
    try {
      const res = await getShipping({ area: 'Inland' })
      if (res.data.success) {
        setPartnerList(res.data.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const columnOrder = [
    {
      title: 'STT',
      width: 40,
      key: 0,
    },
    {
      title: 'Kho',
      key: 22,
      width: 90,
      dataIndex: 'warehouse',
    },
    {
      title: 'Ngày ký nhận',
      key: 1,
      width: 90,
      dataIndex: 'date_sign',
    },

    {
      title: translate('Bill number'),
      dataIndex: 'code_bill',
      key: 2,
      width: 90,
    },
    {
      title: translate('Amount package'),
      dataIndex: 'total_quantity_package',
      key: 3,
      width: 90,
    },
    {
      title: translate('Amount package imported'),
      dataIndex: 'amount_package_imported',
      key: 201,
      width: 90,
    },
    {
      title: translate('Amount package exported'),
      dataIndex: 'amount_package_exported',
      key: 202,
      width: 90,
    },
    {
      title: translate('Waybill number'),
      dataIndex: 'tracking_number',
      key: 4,
      width: 200,
    },
    {
      title: translate('Customer id'),
      dataIndex: 'user',
      width: 100,
      key: 5,
    },

    {
      title: translate('Payment help'),
      dataIndex: 'cod',
      width: 100,
      key: 6,
    },
    {
      title: translate('Chi phí Đóng gỗ/ Đóng tải'),
      dataIndex: 'fee_bag',
      key: 7,
      width: 100,
    },
    {
      title: 'Phí nâng hàng',
      dataIndex: 'fee_package',
      width: 100,

      key: 8,
    },
    // {
    //   title: translate('Surcharge'),
    //   dataIndex: 'sub_cost',
    //   key: 9,
    // },
    {
      title: translate('Mass'),
      dataIndex: 'mass',
      width: 100,
      key: 10,
    },
    {
      title: 'Phụ phí trọng lượng (kg)',
      dataIndex: 'sub_fee_kg',
      width: 100,
      key: 21,
    },
    {
      title: translate('Volume'),
      dataIndex: 'volume',
      width: 100,
      key: 11,
    },
    {
      title: 'Phụ phí khối lượng (m3)',
      dataIndex: 'sub_fee_m3',
      width: 100,
      key: 20,
    },
    {
      title: translate('Unit price'),
      dataIndex: 'unit_price',
      width: 100,
      key: 12,
    },
    {
      title: translate('Total charge'),
      dataIndex: 'sum_cost',
      width: 100,
      key: 13,
    },
    {
      title: translate('Origin cost'),
      dataIndex: 'cost_origin',
      width: 100,
      key: 14,
    },
    {
      title: translate('Order status'),
      dataIndex: 'order_status',
      width: 100,
      key: 16,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 100,
      key: 17,
    },
    {
      title: 'Cập nhật lần cuối',
      dataIndex: 'updated_at',
      width: 100,
      key: 18,
    },
    {
      title: 'Mô tả hàng hóa',
      dataIndex: 'description',
      width: 100,
      key: 19,
    },
    {
      title: 'Hành động',
      width: 120,
      key: 'action',
    },
  ]

  const columns = [
    {
      title: 'Mã bill',
      dataIndex: 'code_bill',
    },
    {
      title: 'Số kiện xuất',
      render(data, record) {
        return (
          <div>
            {!record.is_activate && (
              <Row>
                <InputNumber
                  onChange={(e) => {
                    onUpdateBillItem(record, e)
                  }}
                  value={record.amount_package_export}
                />
                <div style={{ marginLeft: 5, paddingTop: 5 }}>
                  {'/' + record.total_quantity_package}
                </div>
              </Row>
            )}

            {record.is_activate && (
              <Row>
                <div style={{ marginLeft: 5, paddingTop: 5 }}>
                  {record.amount_package_exported +
                    '/' +
                    record.total_quantity_package}
                </div>
              </Row>
            )}
          </div>
        )
      },
    },
    {
      title: 'Tổng kiện',
      render: (data, record) => {
        return <div>{`${record.total_quantity_package}`}</div>
      },
    },
    {
      title: 'Tổng cước',
      dataIndex: 'sum_cost',
      render(data) {
        return formatNumber(Number(data).toFixed(0))
      },
    },
    {
      title: 'Trọng lượng',
      dataIndex: 'mass',
      render(data) {
        return data + ' kg'
      },
    },
    {
      title: 'Khối lượng',
      dataIndex: 'volume',
      render(data) {
        return (
          <>
            {data} m<sup>3</sup>
          </>
        )
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'create_date',
      render(data) {
        return moment(data).format('DD/MM/YYYY')
      },
    },
    {
      action: 'Hành động',
      render(data, record) {
        return (
          <Button
            onClick={(e) => {
              onDeleteBillItem(record)
            }}>
            <div>{'Xoá'}</div>
          </Button>
        )
      },
    },
  ]

  const searchByCustomer = async (params) => {
    try {
      const res = await getOrder({
        customer_code: params,
        order_status: 'CameBack',
        export: true,
      })
      if (res.data.success) {
        setListSearchByDate(
          res.data.data.filter((e) => e.order_status == 'CameBack')
        )
        setShowPickOrder(true)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const copyMessage = () => {
    let mess = data.orders
      .map((e) => {
        return `${e.code_bill} - ${e.mass}kg - ${e.volume}cm3`
      })
      .join('\r\n')
    console.log(mess)
    navigator.clipboard.writeText(mess)
  }
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  const onAddOrder = () => {
    let tmp = listsSearchByDate.filter(
      (e) => selectedRowKeys.indexOf(e.id) != -1
    )
    tmp = tmp.filter(
      (e) =>
        data.orders
          .map((f) => {
            return f.id
          })
          .indexOf(e.id) == -1
    )
    console.log(tmp)
    let allDataUpdate = [...allData]
    allDataUpdate[formkey] = {
      ...allData[formkey],
      orders: [...allData[formkey].orders, ...tmp],
    }
    setData(allDataUpdate)
    setShowPickOrder(false)
  }

  useEffect(() => {
    getCustomerList()
    getPartnerList()
  }, [])

  useEffect(() => {
    if (data.orders) {
      var sumVolume = 0
      var sumMass = 0
      var totalExportPackage = 0
      var totalExportRequirePackage = 0
      var totalSumCost = 0
      data.orders.map((order) => {
        sumVolume += order.volume || 0
        sumMass += order.mass || 0
        totalExportPackage += order.amount_package_exported || 0
        totalExportRequirePackage += order.total_quantity_package || 0
        totalSumCost += parseInt(order.sum_cost)
      })
      setSumMass(sumMass)
      setSumVolume(sumVolume)
      setTotalSumCost(totalSumCost)
      setTotalExport(totalExportPackage)
      setTotalRequireExport(totalExportRequirePackage)
    }
  }, [data.orders])

  return (
    <>
      <Row>
        <Col span={12}>
          <Row>
            <div>{'Ngày xuất kho'}</div>
          </Row>
          <Row style={{ margin: '7px 0' }}>
            <Col span={18}>
              <DatePicker
                locale={locale}
                size="large"
                showTime
                disabled={data.is_activate}
                defaultValue={moment(
                  new Date(String(data.created_at).replace('.000Z', ''))
                )}
                style={{ width: '100%', borderRadius: 8 }}
                placeholder="Chọn ngày xuất kho"
                onChange={(date, dateString) => {
                  let tmp = { ...data }
                  tmp.created_at = dateString
                  setData(tmp)
                }}
              />
            </Col>
          </Row>
          <Row style={{ margin: '7px 0' }}>
            <Col span={18} className={styles['chooseCustomer']}>
              <Select
                style={{ width: '100%', borderRadius: 8 }}
                placeholder="Chọn khách hàng"
                size="large"
                value={
                  data.customer_code || (data.user && data.user.customer_code)
                }
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(e) => {
                  let tmp = [...allData]
                  tmp[formkey].customer_code = e
                  searchByCustomer(e)
                  setData(tmp)
                }}>
                {customerList.map((e) => (
                  <Select.Option value={e.customer_code}>
                    {e.name + ' - ' + e.customer_code}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={15}>
            <Col span={6}>
              <div
                style={{
                  marginTop: 15,
                  border: 'solid 2px #d9d9d9',
                  borderRadius: 15,
                  padding: 15,
                }}>
                <Row>Tổng đang xuất/ Tổng cần xuất</Row>
                <span style={{ fontWeight: 600, fontSize: 16 }}>
                  {totalExport + '/' + totalRequireExport}
                </span>
              </div>
            </Col>
            <Col span={6}>
              <div
                style={{
                  marginTop: 15,
                  border: 'solid 2px #d9d9d9',
                  borderRadius: 15,
                  padding: 15,
                }}>
                <Row>Tổng trọng lượng</Row>
                <span style={{ fontWeight: 600, fontSize: 16 }}>
                  {formatNumber(Number(sumMass).toFixed(3))} kg
                </span>
              </div>
            </Col>
            <Col span={6}>
              <div
                style={{
                  marginTop: 15,
                  border: 'solid 2px #d9d9d9',
                  borderRadius: 15,
                  padding: 15,
                }}>
                <Row>Tổng Khối Lượng</Row>
                <span style={{ fontWeight: 600, fontSize: 16 }}>
                  {formatNumber(Number(parseFloat(sumVolume).toFixed(3)))} m
                  <sup>3</sup>
                </span>
              </div>
            </Col>

            <Col span={6}>
              <div
                style={{
                  marginTop: 15,
                  border: 'solid 2px #d9d9d9',
                  borderRadius: 15,
                  padding: 15,
                }}>
                <Row>Tổng cước</Row>
                <span style={{ fontWeight: 600, fontSize: 16 }}>
                  {formatNumber(totalSumCost)}
                </span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ margin: '7px 0' }}>
        <Button
          size="large"
          type="primary"
          style={{ borderRadius: 5 }}
          icon={<MessageOutlined />}
          onClick={copyMessage}>
          Copy hóa đơn
        </Button>
      </Row>

      <Table columns={columns} size="small" dataSource={data.orders} />

      <Divider style={{ backgroundColor: 'rgb(0 0 5 / 20%)', height: 2 }} />
      <Row justify="space-between">
        <Col span={6}>
          <Select
            placeholder="Vận chuyển nội địa"
            style={{ width: '100%' }}
            value={
              data.shipping_partner_name ||
              (data.shipping_partner &&
                (data.shipping_partner.name || undefined)) ||
              undefined
            }
            onChange={(e) => {
              let tmp = [...allData]
              tmp[formkey].shipping_partner_name = e
              setData(tmp)
            }}>
            {partnerList.map((e) => (
              <Select.Option value={e.name}>{e.name}</Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={14}>
          <Row style={{ marginBottom: 7 }}>
            <Input
              placeholder="Ghi chú"
              value={data.note}
              onChange={(e) => {
                let tmp = [...allData]
                tmp[formkey].note = e.target.value
                setData(tmp)
              }}
            />
          </Row>
          <Row style={{ marginBottom: 7 }}>
            <InputNumber
              style={{ width: '100%' }}
              placeholder="cod"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              value={data.cod}
              onChange={(e) => {
                let tmp = [...allData]
                tmp[formkey].cod = e
                setData(tmp)
              }}
            />
          </Row>
          <Row style={{ marginBottom: 7 }} justify="end" gutter={20}>
            <Col span={12}>
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Phụ phí khác"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                value={data.sub_cost}
                onChange={(e) => {
                  let tmp = [...allData]
                  tmp[formkey].sub_cost = e
                  setData(tmp)
                }}
              />
            </Col>
            <Col span={12}>
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Cước xuất kho"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                value={data.cost_export}
                onChange={(e) => {
                  let tmp = [...allData]
                  tmp[formkey].cost_export = e
                  setData(tmp)
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        title="Các hóa đơn trong ngày"
        visible={showPickOrder}
        onCancel={() => setShowPickOrder(false)}
        onOk={onAddOrder}
        width={1000}>
        <Table
          rowKey="id"
          rowSelection={rowSelection}
          columns={columnOrder}
          scroll={{ x: 'max-content' }}
          dataSource={listsSearchByDate}
        />
      </Modal>
    </>
  )
}
